/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import './index.css';
// import { ThemeContext } from '../../utils/theme';

// export default function SweepstakeRules(props) {
// export default function SweepstakeRules(props) {
class SweepstakeRules extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   field: value,
    // };
    // creates a reference for your element to use
    this.handleOnClick = this.handleOnClick.bind(this);
    this.myDivToFocus = React.createRef();
  }

  handleOnClick() {
    // .current is verification that your element has rendered
    if (this.myDivToFocus.current) {
      // window.scrollTo(200, this.myDivToFocus.current.offsetTop);
      window.scrollTo({ top: 650, behavior: 'smooth' });
      // this.myDivToFocus.current.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      // });
    }
  }

  // const theme = useContext(ThemeContext);
  render() {
    return (
      <div>
        <Helmet>
          <title>Sweepstake Rules | cashX</title>
          <meta name="description" content="Review the terms of service for the cashX website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div style={{ marginTop: 100, minHeight: '64vh', padding: '10px' }}>
          <h2 style={{ textAlign: 'center' }}>
            cash
            <sup>x</sup>
            {' '}
            Sweepstake Rules
          </h2>
          <br />
          <br />
          <div style={{ maxWidth: 1200, margin: 'auto' }} className="terms-body">
            <p style={{ textAlign: 'left' }}> Enter for a chance to win an Apple Airpods Pro sweepstakes (up to $300 in value).</p>
            <br />
            <p style={{ textAlign: 'left' }}>
              {' '}
              NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE
              YOUR CHANCES OF WINNING. Void where prohibited.

            </p>
            <br />
            <p style={{ textAlign: 'left' }}>
              This sweepstakes is open to US residents of any one (1) of the fifty (50) United States and District
              of Columbia, who are 18+ at the time of entry and is void where prohibited. Sweepstakes begins
              on September 1
              <sup>st</sup>
              , 2021 at 00:00:01 Pacific Daylight Time  and ends at December 31
              <sup>st</sup>
              , 2021 23:59:59 Pacific
              Daylight Time. A limit of one prize per cash
              <sup>X</sup>
              {' '}
              user. ARV of each prize $300, total ARV of all
              prizes $300.

            </p>
            <br />
            <p>
              To enter:
            </p>

            <ol className="main-list eneter">
              <li>
                Install cash
                <sup>X</sup>
                {' '}
                extension on desktop chrome browser
              </li>
              <li>
                Register to become a cash
                <sup>X</sup>
                {' '}
                user
              </li>

            </ol>
            <p style={{ margin: '30px 0px 20px' }}>
              See Official Sweepstake Rules
              {' '}
              <button
                onClick={this.handleOnClick}
                type="button"
                style={{
                  color: '#083bf2',
                  textDecoration: 'underline',
                  border: 'none',
                  background: 'transparent',
                  fontSize: 16,
                }}
              >
                here.
              </button>
            </p>
            <br />
            <p>
              <b> Privacy  Notice: </b>

              Like  all  browser  extensions,  intentX  can  read  and  change  a  limited  amount  of
              data  in  your  browser  to  ensure  that  our  product  is  working  at  its  best.  We  read  the  page  when
              you’re on a shopping website so that we can find coupons for it, and the change we make is to
              apply coupon codes when you ask us to. We take your privacy very seriously, and you can read
              more about it in our privacy policy. Like all browser extensions, intentX can read and change a
              limited amount of data in your browser to ensure that our product is working at its best. We read
              the page when you’re on a shopping website so that we can find coupons for it, and the change we
              make is to apply coupon codes when you ask us to. We take your privacy very seriously, and you
              can read more about it in our privacy policy.
              {' '}
              <a className="underlined" href="https://getcashx.com/privacy-policy" style={{ color: '#000' }}>https://getcashx.com/privacy-policy.</a>
            </p>
            <br />
            <p className="officialrule" ref={this.myDivToFocus} id="officialrule1">
              <b style={{ textAlign: 'center' }}>
                OFFICIAL RULES for CASH
                <sup>X</sup>
                {' '}
                SWEEPSTAKES
              </b>
              <span>
                NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE
                YOUR CHANCES OF WINNING.
              </span>
              <span>
                BY  PARTICIPATING  IN  THE  SWEEPSTAKES,  YOU  (“THE
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  ENTRANT”
                </span>
                )  AGREE  TO  BE
                BOUND BY THESE OFFICIAL RULES WHICH FORM A BINDING CONTRACT. PLEASE
                READ CAREFULLY BEFORE PARTICIPATING.
              </span>
              <span>
                THIS  SWEEPSTAKES  IS  OFFERED  BY  INTENTX  (THE
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “SPONSOR”
                </span>
                ),  THE  OWNER  OF
                CASH
                <sup>X</sup>
                .   TO   PARTICIPATE,   YOU   ARE   REQUIRED   TO   INSTALL   THE   CASH
                <sup>X</sup>
                EXTENSION ON YOUR DESKTOP CHROME BROWSER AND REGISTER TO BECOME A CASH
                <sup>X</sup>
                {' '}
                USER.
                YOUR  PARTICIPATION  IS  SUBJECT  TO  CASH
                <sup>X</sup>
                {' '}
                WEBSITE  TERMS  OF SERVICE. (
                <a className="underlined" href="https://getcashx.com/privacy-policy" style={{ color: '#000' }}>
                  https://getcashx.com/terms-of-service
                  {' '}
                </a>
                )
              </span>
              <br />
            </p>
            <br />
            <ol className="main-list">
              <li>
                <b>SWEEPSTAKES PERIOD: </b>
                The cash
                <sup>X</sup>
                {' '}
                sweepstakes (the
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “Sweepstakes”
                </span>
                ) is sponsored by intentX
                (
                <span className="underlined" style={{ display: 'inline' }}>
                  “intentX”
                </span>
                or
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “Sponsor”
                </span>
                ). It begins September 1
                <sup>st</sup>
                , 2021 at 00:00:01 Pacific Daylight Time  and ends
                December 31
                <sup>st</sup>
                , 2021 at 23:59:59 Pacific Daylight Time (the
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “Sweepstakes Period”
                </span>
                {' '}
                ). Sponsor’s clock will
                be the official timekeeper for this Sweepstakes. The Sweepstakes is subject to all applicable federal,
                state,
                and
                local
                laws
                and
                regulations,
                and
                is
                void
                where
                prohibited.
              </li>
              <li>
                <b>SPONSOR: </b>
                intentX Inc. at 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024
                <a style={{ color: '#000' }} href="mailto:contact@intentx.com"> contact@intentx.com</a>
              </li>
              <li>
                <b>AGREEMENT TO SWEEPSTAKES RULES:</b>
                You agree that, by participating, you will be bound by these Official Rules and the decisions of the Sponsor, which are binding and final in all matters relating to this sweepstakes.
                You acknowledge that you satisfy all Sweepstakes eligibility requirements.
                The Rules along with the cash
                <sup>X</sup>
                {' '}
                Terms of Service are the
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “Applicable Terms.”
                </span>
                {' '}
                (
                <a style={{ color: '#000' }} href="https://getcashx.com/terms-of-service">https://getcashx.com/terms-of-service</a>
                {' '}
                )
              </li>
              <li>
                <b>ELIGIBILITY: </b>
                This Sweepstakes is open only to legal residents of any one (1) of the fifty (50) United States and the District of Columbia,
                who are as of entry date and through the time of the prize award 18 years of age or older.
                Entrant&rsquo;s cash
                <sup>X</sup>
                {' '}
                Membership Account (
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “cashX Account”
                </span>
                ) must remain in good standing through the time of prize award to redeem a prize.
                Employees of Sponsor, officers, managers, directors, and contractors (including, without limitation, influencers) of intentX, its parent, divisions, affiliates, subsidiaries, advertising agencies or promotional partners associated with this Sweepstakes (collectively, the
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>
                  “Sweepstakes Entities”
                </span>
                {' '}
                ) and each of their respective parents, agents, affiliates, subsidiaries, and advertising and promotion agencies, and their relatives (regardless of where they reside) or household members, whether or not related, are NOT eligible to enter or win.
                <span className="underlined" style={{ display: 'inline' }}>
                  “Relatives”
                </span>
                are defined as spouse, domestic partner, parent, legal guardian, legal ward, in-law, grandparent, sibling, children and grandchildren.
                <span className="underlined" style={{ display: 'inline' }}>
                  “Household members”
                </span>
                {' '}
                shall mean people who share the same residence at least three months a year.
                {' '}
                <b>This Sweepstakes is void in all U.S. territories (including Puerto Rico), possessions and overseas military installations and where prohibited by law.</b>
                Any potential winner may be required to provide Sponsor with proof that he/she is the registered cash
                <sup>X</sup>
                {' '}
                Account holder associated with a potentially winning entry as well as proof of residency and age.
                If a dispute cannot be resolved to Sponsor’s satisfaction, the entry will be deemed ineligible.

              </li>
              <li>
                <b>HOW TO ENTER: </b>
                <ol style={{ marginLeft: 20, marginTop: 10 }}>

                  <li style={{ marginBottom: 5 }}>
                    Install cash
                    <sup>X</sup>
                    {' '}
                    extension on desktop chrome browser
                  </li>
                  <li>
                    Register to become a cash
                    <sup>X</sup>
                    {' '}
                    user
                  </li>
                </ol>
                <p>
                  Sponsor reserves the right, in its sole discretion, to: (i) verify any element of any entry, related materials or entrant’s eligibility; (ii) to request additional information; and (iii) to disqualify any entrant whose participation may subject the Sweepstakes, Sponsor or Sponsor&apos;s advertisers, clients, or customers to controversy, negative publicity, scorn, or ridicule. By entering this Sweepstakes, you agree to provide any documentation or information deemed necessary by, and in a form acceptable to, Sponsor to satisfy the above requirement if asked by Sponsor to do so.
                </p>
                <p>
                  We may, in our sole discretion, disqualify any individual found to be tampering with the entry process or the operation of the Sweepstakes or website; acting in violation of these rules; or acting in an unsportsmanlike or disruptive manner or with intent to annoy, abuse, threaten or harass any other person. If your entry is incomplete or if you use robotic, automatic, programmed or similar entry methods, your entry will be void.
                </p>
              </li>

              <li>
                <b> PRIZE, QUANTITY AND APPROXIMATE RETAIL VALUE (“ARV”): </b>
                This Sweepstakes
                prize  is  a  cash  prize.  The  ARV  of  each  prize  is  $300  USD.   The  total  ARV  of  all  prizes:  $1500
                USD. The prize is non-transferable, and no substitution or cash redemptions will be made except
                in  intentX’s  sole  discretion.  intentX  reserves  the  right  to  substitute  the  prize  for  one  of  equal  or
                greater value if the prize should become unavailable for any reason. Sponsor will not replace any
                lost, mutilated or stolen prize or any prize that is undeliverable or does not reach the winner because
                of an incorrect or changed address or other contact information. If winner does not accept or use
                the entire prize, the unaccepted or unused part of the prize will be forfeited, and Sponsor will have
                no further obligation with respect to that prize or portion of the prize. No more than the stated prize
                will  be  awarded.  Winner  is  strictly  prohibited  from  selling,  auctioning,  trading  or  otherwise
                transferring any part of the prize.
              </li>
              <li>
                <b> LIMIT: </b>
                Limit one (1) entry per person. Attempts made by an entrant to submit entries in excess
                of any limit or otherwise in violation of these Applicable Terms by using multiple or false contact
                information or otherwise may be disqualified.
              </li>
              <li>
                <b> WINNER  SELECTION: </b>
                After  the  Sweepstakes  Period,  Sponsor  will  select  1  (one)
                {' '}
                <span className="underlined" style={{ display: 'inline' }}>Potential Winner</span>
                {' '}
                at random from among all eligible entries received during the Sweepstakes Period using a
                computer randomization system . These decisions to decide the potential winners are final. Odds of
                winning depend on number of eligible entries received. The Sponsor is not responsible for: (a) lost,
                misdirected, late, postage due, incomplete, or unintelligible entries or registries or for inaccurate
                entry information, whether caused by you or by any of the equipment or programming associated
                with  or  utilized  in  the  Sweepstakes,  or  by  any  technical  or  human  error  that  may  occur  in  the
                processing of entries; (b) any printing or typographical errors in any materials associated with the
                Sweepstakes; (c) any error in the operation or transmission, theft, destruction, unauthorized access
                to, or alteration of, entries, or for technical, network, telephone, computer, hardware or software,
                malfunctions of any kind, or inaccurate transmission of, or failure to receive any entry information
                on account of technical problems or traffic congestion on the Internet or at any website; or (d) injury
                or damage to your or any other computer resulting from downloading any materials in connection
                with the Sweepstakes.
              </li>
              <li>
                <b> WINNER NOTIFICATION: </b>
                On or about month December 31
                <sup>st</sup>
                , 2021 Sponsor will attempt to notify the
                Potential Winners individually that he or she may have won the prize in this Sweepstakes via email,
                using the email associated with their cash
                <sup>X</sup>
                {' '}
                Account (at Sponsor’s sole discretion). Each Potential
                Winner  will  be  required  to  provide  his/her  name,  mailing  address,  date  of  birth,  and  consent  to
                confirm eligibility and may be required to complete, sign, have notarized (if applicable) and return, confirm eligibility and may be required to complete, sign, have notarized (if applicable) and return,
                without   alteration   and   in   the   form   presented   by   Sponsor,   an   affidavit   of   eligibility   and
                liability/publicity release (unless prohibited by law), and tax forms, within the time frame specified
                by Sponsor or prize may be forfeited.  In the event of noncompliance with these requirements, the
                prize  may  be  forfeited. The  affidavit  of  eligibility  and  liability/publicity  release  are  subject  to
                verification  by  Sponsor. If  any  the  prize  notification  or  Sweepstakes-related  communication  is
                rejected, faulty or returned as undeliverable, or if the Potential Winner cannot be reached after a
                reasonable attempt has been made by Sponsor or does not respond according to the notification’s
                or Sponsor’s instructions (as determined by Sponsor in its sole discretion), that Potential Winner
                may  be  disqualified  and  an  alternate  winner  may  be  randomly  selected;  the  Sponsor  will  not  be
                obligated to pursue more than three (3) alternate Potential Winners for any reason, in which case
                some prizes may go unawarded. Sponsor reserves the right to modify the notification procedures in
                connection with the selection of any alternate potential winner, if any.
              </li>

              <li>
                <b> TAXES: </b>
                All applicable taxes (as well as any expenses not specified in these Official Rules as being
                provided as part of the prize) are the sole responsibility of the prize recipient. Federal IRS 1099-
                MISC or other tax forms may be issued where required.
              </li>
              <li>
                <b> PUBLICITY: </b>
                Entry into this Sweepstakes shall constitute and signify the entrant’s agreement and
                consent  that  intentX  and  its  designees  may  use  the  entrant’s  name,  city,  state,  likeness,  photo,
                including  entrant’s  or  winner’s  Social  Account  profile  photo,  entry,  and/or  prize  information  in
                connection with the Sweepstakes for promotional, advertising or other purposes, worldwide, in any
                and  all  media  now  known  or  hereafter  devised,  including  the  internet,  without  limitation  and
                without further payment, notification, permission or other consideration, except where prohibited
                by law, and participant releases all Sweepstakes Entities  from any and all liability related thereto.
                Nothing contained in these Rules obligates Sponsor to make use of any of the rights granted herein
                and winner waives any right to inspect or approve any such use.
              </li>
              <li>
                <b> ARBITRATION: </b>
                Except  where  prohibited  by  law,  as  a  condition  of  participating  in  this
                Sweepstakes, entrant agrees that:  (1) any and all disputes, claims, and causes of action arising out
                of or connected with this Sweepstakes, or the prize awarded, shall be resolved individually, without
                resort to any form of class action; and exclusively by final and binding arbitration under the rules
                of the American Arbitration Association and held at a AAA regional office in Los Angeles County,
                California;  (2)  the  Federal  Arbitration  Act  shall  govern  the  interpretation,  enforcement  and  all
                proceedings at such arbitration; and (3) judgment upon such arbitration award may be entered in
                any court having jurisdiction. Under no circumstances will entrant be permitted to obtain awards
                for,  and  entrant  hereby  waives  all  rights  to  claim  punitive,  incidental  or  consequential  damages
                including  attorney’s  fees  or  any  other  damages,  other  than  for  entrant’s  actual  out-of-pocket
                expenses (i.e., costs associated with participating in this Sweepstakes), and entrant further waives
                all rights to have damages multiplied or increased.
              </li>
              <li>
                <b> ENFORCEMENT  OF  RULES: </b>
                Sponsor’s  decisions  will  be  final  in  all  matters  relating  to  this
                Sweepstakes, including interpretation of the Rules, determination of the recipients, and distribution
                of  the  prizes.  All  entrants,  as  a  condition  of  entry,  agree  to  be  bound  by  and  comply  with  the
                Applicable Terms and the decisions and instructions of intentX. The Sponsor’s failure to exercise
                or  enforce  any  right  or  provision  of  this  Agreement  will  not  constitute  a  waiver  of  such  right  or
                provision. In the event there is an alleged or actual ambiguity, discrepancy or inconsistency between
                disclosures  or  other  statements  contained  in  any  Sweepstakes-related  materials  and/or  the
                Applicable  Terms  (including  any  alleged  discrepancy  or  inconsistency  in  the  Rules),  it  will  be
                resolved  in  intentXs  sole  discretion.     Entrants  waive  any  right  to  claim  ambiguity  in  the
                Sweepstakes or the Rules. The invalidity or unenforceability of any provision of the Rules will not
                affect  the  validity  or  enforceability  of  any  other  provision.  In  the  event  that  any  provision  is
                determined to be invalid or otherwise unenforceable or illegal, the Rules will otherwise remain in
                effect and will be construed in accordance with their terms as if the invalid or illegal provision were
                not contained herein.
              </li>
              <li>
                <b> LIMITATIONS  OF  LIABILITY: </b>
                YOU  ACKNOWLEDGE  AND  AGREE  THAT,  IN  NO
                EVENT  WILL  INTENTX  BE  LIABLE  TO  YOU  OR  ANY  THIRD  PARTY  FOR  ANY
                INDIRECT,  PUNITIVE,  EXEMPLARY,  INCIDENTAL,  SPECIAL,  OR  CONSEQUENTIAL
                DAMAGES   WHETHER   IN   CONTRACT,   TORT   (INCLUDING   NEGLIGENCE),   OR
                OTHERWISE ARISING IN CONNECTION WITH THE USE, ACCEPTANCE, POSSESSION,
                MISUSE OR AWARDING OF THE PRIZE, WHILE PREPARING FOR, PARTICIPATING IN
                AND/OR   TRAVELING   TO   OR   FROM   ANY   PRIZE-   OR   SWEEPSTAKES-RELATED
                ACTIVITY,  INCLUDING,  WITHOUT  LIMITATION,  ANY  INJURY,  DAMAGE,  DEATH,
                LOSS,  OR  ACCIDENT  TO  PERSON  OR  PROPERTY.  EACH  WINNER  AGREES  (AS
                BETWEEN   WINNER   AND   THE   SWEEPSTAKES   ENTITIES)   THAT   THE   PRIZE   IS
                PROVIDED  AS-IS  WITHOUT  ANY  WARRANTY,  REPRESENTATION  OR  GUARANTEE,
                EXPRESS   OR   IMPLIED,   IN   FACT   OR   IN   LAW,   WHETHER   NOW   KNOWN   OR
                HEREINAFTER  ENACTED,  RELATIVE  TO  THE  USE  OR  ENJOYMENT  OF  THE  PRIZE,
                INCLUDING, WITHOUT LIMITATION, ITS QUALITY, MERCHANTABILITY OR FITNESS
                FOR  A  PARTICULAR  PURPOSE.  THE  FOREGOING  LIMITATION  OF  LIABILITY  WILL
                COVER,   WITHOUT   LIMITATION,   ANY   TECHNICAL   MALFUNCTION,   COMPUTER
                ERROR  OR  LOSS  OF  DATA,  AND  ANY  OTHER  INJURY  ARISING  FROM  THE  USE  OF
                OUR   WEBSITE   OR   PLATFORM.   SOME   JURISDICTIONS   DO   NOT   ALLOW   THE
                EXCLUSION  OF  CERTAIN  WARRANTIES  OR  THE  LIMITATION  OR  EXCLUSION  OF
                LIABILITY  FOR  INCIDENTAL  OR  CONSEQUENTIAL  DAMAGES.  TO  THE  EXTENT
                THAT  INTENTX  MAY  NOT  DISCLAIM  ANY  IMPLIED  WARRANTY  OR  LIMIT  ITS
                LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT
                OF INTENTX’S LIABILITY WILL BE THE MINIMUM PERMITTED UNDER APPLICABLE
                LAW.
              </li>
              <li>
                <b> DISCLAIMERS: </b>
                Your access to and use of our Website and Platform or any content are at your
                own risk. You understand and agree that our Website and Platform are provided to you on an “AS
                IS”  and  “AS  AVAILABLE”  basis.  Without  limiting  the  foregoing,  to  the  maximum  extent
                permitted  under  applicable  law,  WE  DISCLAIM  ALL  WARRANTIES  AND  CONDITIONS,
                WHETHER   EXPRESS   OR   IMPLIED,   OF   MERCHANTABILITY,   FITNESS   FOR   A
                PARTICULAR    PURPOSE,    AND    NON-INFRINGEMENT.    We    make    no    warranty    or
                representation  and  disclaim  all  responsibility  and  liability  for:  (i)  the  completeness,  accuracy,
                availability, timeliness, security or reliability of our Website or Platform or any content; (ii) any
                harm to your computer system, loss of data, or other harm that results from your access to or use of
                our Website or Platform or any content; (iii) the deletion of, or the failure to store or to transmit,
                any content and other communications maintained by our Website or Platform; and (iv) whether our Website or Platform will meet your requirements or be available on an uninterrupted, secure,
                or error-free basis. No advice or information, whether oral or written, obtained from us or through
                our Website or Platform, will create any warranty or representation not expressly made herein.
              </li>
              <li>
                <b> PRIVACY: </b>
                When you register, we collect personally identifying information about you, including
                your  name  and  email  address.   The  information  that  you  provide  will  be  added  to  a  centralized
                Sponsor database and you will simultaneously be registered for Sponsor’s other websites as well.
                Your email address will not be sold or rented to third parties. For more information about how the
                Sponsor uses the information you provide, see Sponsor’s privacy policy at
                {' '}
                <a style={{ color: '#000' }} href="https://getcashx.com/privacy-policy">https://getcashx.com/privacy-policy.</a>

              </li>

            </ol>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
SweepstakeRules.propTypes = {
  user: PropTypes.object,
};
export default SweepstakeRules;
