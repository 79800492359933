/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-console */
/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Profile from '../../View/Profile';
import Filter from '../../images/filter.png';
import InfoIcon from '../../images/info_icon.png';
import PayPal from '../../images/paypal.png';
import Amazon from '../../images/amazon_small.png';
import CashxTick from '../../images/cashx/tick.png';
import DailyMailTick from '../../images/dailymail/tick.png';
import Rollover from '../../images/rollover.png';
import './index.css';
import { ThemeContext } from '../../utils/theme';
/* import fetchGet from '../../utils/fetchGet'; */
import {
  userPayouts, orderHistory, dashboardInfo,
} from '../../utils/apiEndPoint';
import ActivityIndicator from '../../View/ActivityIndicator';
import cookie from '../../utils/cookie';
import fgh from '../../utils/fetchGetWithHeaders';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
// const Info = () => (
//   <>
//     <p />

//   </>
// );
export default class Payout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      payouts: [],
      orders: [],
      loading: true,
      filterOpen: false,
      selectedFilter: [],
      seeMorePayout: 1,
      seeMoreOrder: 1,
      orderPagination: false,
      monthCashback: this.props.user.currentMonthCashBack === '0' ? '0.0' : parseFloat(this.props.user.currentMonthCashBack).toFixed(2),
      isAmazon: this.props.user.amazonEnabled,
    };
    this.activeIndexBody = this.activeIndexBody.bind(this);
    this.filterUpdated = this.filterUpdated.bind(this);
    this.getMoreOrders = this.getMoreOrders.bind(this);
  }

  componentDidMount() {
    const userToken = cookie.getCookie('userToken');
    if (userToken === null) {
      this.props.history.push('/login');
      this.props.onLogout();
    }
    const clientId = this.props.user.clientId || 'intentx';
    /* const userToken = 'yRBDzVmSLvDb58Wa8cNo7meI6JDWgIyMA1Jc5+X72npe0THTP1yQ0ClfKrnHzU2FuL/ARtHiuJOTKLfSmnWMYM/W0YNJe+jZCjR77kMp5D80TJoc9JduJ5EsOEhwbdErIrnAMgEpweM0HOZV6RcJ8L0eHmgYfuj1le3v87mlJqQIuvI2IsV9ySgCLIIFMsC0'; */
    fgh(`${userPayouts}${clientId}&pageNumber=${this.state.seeMorePayout - 1}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
      .then((res) => {
        if (res.length) {
          const payouts = [];
          res.forEach((ele) => {
            const month = ele.month ? ele.month.split(' ') : [null, null];
            payouts.push({
              month: month[0],
              year: month[1],
              order: ele.noOfOrders,
              cashBack: ele.cashback,
              payment: ele.status,
            });
          });
          for (let i = 0; i < payouts.length; i++) {
            payouts[i].rolledOverCashback = 0;
            for (let j = i + 1; j < payouts.length; j++) {
              if (payouts[j].payment === true && (payouts[j].cashBack >= 4.99 && payouts[j].payment === true)) {
                break;
              } else {
                payouts[i].rolledOverCashback += payouts[j].cashBack;
              }
            }
            payouts[i].totalCashBack = (payouts[i].cashBack + payouts[i].rolledOverCashback).toFixed(2);
          }
          for (let i = 0; i < payouts.length; i++) {
            if (parseFloat(payouts[i].totalCashBack) <= 4.99) {
              if (i === 0) {
                payouts[0].payment = false;
              } else {
                /* const prevCashback = payouts[i - 1].rolledOverCashback;
                payouts[i - 1].rolledOverCashback += payouts[i].cashBack;
                payouts[i - 1].totalCashBack = (parseFloat(payouts[i - 1].totalCashBack) + payouts[i - 1].rolledOverCashback - prevCashback).toFixed(2); */
                payouts[i].payment = false;
                if (parseFloat(payouts[i - 1].totalCashBack) > 4.99 && res[i].paymentSend === true) {
                  payouts[i - 1].payment = true;
                }
              }
            }
          }
          return payouts;
        }
        /* throw 'Something went wrong.'; */
      })
      .then((payouts) => this.setState({ payouts }))
      .then(() => fgh(`${orderHistory}${clientId}&pageNumber=${this.state.seeMoreOrder - 1}`, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }))
      .then((res) => {
        if (res.length) {
          const orders = [];
          res.forEach((ele) => {
            const newDate = new Date(ele.date);
            /* const date = ele.date ? ele.date.split(' ') : [null, null, null]; */
            orders.push({
              date: newDate.getDate(),
              month: months[newDate.getMonth()],
              year: newDate.getFullYear(),
              store: ele.store,
              order: ele.orderId,
              trip: ele.tripId,
              amount: ele.totalRevenue.toFixed(2) || 0.00,
              status: ele.status || '-',
            });
          });
          this.setState({ orders });
        }
        this.setState({ loading: false });
      })
      .then(() => fgh(dashboardInfo, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }))
      .then((res) => {
        if (!res.error) {
          const userInfo = JSON.parse(cookie.getCookie('userInfo'));
          userInfo.cashback = parseFloat(res.cashback).toFixed(2);
          userInfo.currentMonthCashBack = res.currentMonthCashBack;
          userInfo.email = res.email;
          userInfo.paypalVerified = res.paypalVerified;
          userInfo.paypalEmail = res.paypalEmail;
          userInfo.amazonEnabled = res.amazonEnabled;
          userInfo.clientId = res.clientId || 'intentx';
          cookie.setCookie({ userInfo: JSON.stringify(userInfo) });
          this.props.infoUpdate(userInfo);
          this.setState({ isAmazon: res.amazonEnabled });
          this.setState({ monthCashback: res.currentMonthCashBack === '0' ? '0.00' : parseFloat(res.currentMonthCashBack).toFixed(2) });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getMoreOrders(count) {
    const userToken = cookie.getCookie('userToken');
    const clientId = this.props.user.clientId || 'intentx';
    /* const userToken = 'yRBDzVmSLvDb58Wa8cNo7meI6JDWgIyMA1Jc5+X72npe0THTP1yQ0ClfKrnHzU2FuL/ARtHiuJOTKLfSmnWMYM/W0YNJe+jZCjR77kMp5D80TJoc9JduJ5EsOEhwbdErIrnAMgEpweM0HOZV6RcJ8L0eHmgYfuj1le3v87mlJqQIuvI2IsV9ySgCLIIFMsC0'; */
    fgh(`${orderHistory}${clientId}&pageNumber=${count}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
      .then((res) => {
        if (res.length) {
          const orders = [];
          res.forEach((ele) => {
            const newDate = new Date(ele.date);
            /* const date = ele.date ? ele.date.split(' ') : [null, null, null]; */
            orders.push({

              date: newDate.getDate(),
              month: months[newDate.getMonth()],
              year: newDate.getFullYear(),
              store: ele.store,
              order: ele.orderId,
              trip: ele.tripId,
              amount: ele.totalRevenue.toFixed(2) || 0.00,
              status: ele.status || '-',
            });
          });
          this.setState({ orders: [...this.state.orders, ...orders], seeMoreOrder: count + 1 });
        } else if (res.length === 0) {
          this.setState({ orderPagination: true });
        }
        this.setState({ loading: false });
      });
  }

  filterUpdated() {
    const clientId = this.props.user.clientId || 'intentx';
    const userToken = cookie.getCookie('userToken');
    this.setState({ filterOpen: false });
    console.log(this.state.selectedFilter);
    const selectedFilterNames = [];
    if (this.state.selectedFilter.indexOf(0) !== -1) selectedFilterNames.push('All Trips');
    if (this.state.selectedFilter.indexOf(1) !== -1) selectedFilterNames.push('Credited Orders');
    if (this.state.selectedFilter.indexOf(2) !== -1) selectedFilterNames.push('Pending Orders');
    if (this.state.selectedFilter.indexOf(3) !== -1) selectedFilterNames.push('Cancelled Orders');
    if (this.state.selectedFilter.indexOf(4) !== -1) selectedFilterNames.push('Adjusted Orders');
    console.log(selectedFilterNames);
    fgh(`${orderHistory}${clientId}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  activeIndexBody() {
    const theme = this.context;
    let Tick = CashxTick;
    if (theme.name === 'dailymail') Tick = DailyMailTick;
    let returnValue = null;
    const {
      payouts, orders, activeIndex, selectedFilter, seeMoreOrder, seeMorePayout, orderPagination,
    } = this.state;
    const payoutsView = [];
    let seeLength = 5 + 10 * seeMorePayout;
    if (!payouts) {
      seeLength = 0;
    } else if (seeLength > payouts.length) seeLength = payouts.length;
    for (let i = 0; i < seeLength; i++) {
      payoutsView.push(
        <tr>
          <td>
            <span style={{ textTransform: 'uppercase', fontSize: 15 }}>{payouts[i].month}</span>
            <span style={{ fontSize: 20 }}>{payouts[i].year}</span>
          </td>
          <td>
            {payouts[i].order}
            {' '}
            Orders
          </td>
          <td className="payouts-cashback">
            <p style={{ color: theme.primary }}>
              $
              {' '}
              {payouts[i].totalCashBack}
            </p>
            {payouts[i].rolledOverCashback ? (
              <p style={{ color: theme.senary, fontSize: 10 }}>
                $
                {payouts[i].cashBack.toFixed(2)}
                {' '}
                +
                {' '}
                {(payouts[i + 1].month).slice(0, 3)}
                {' '}
                $
                {payouts[i + 1].totalCashBack}
                {' '}
                Rollover
              </p>
            ) : null}
          </td>
          {payouts[i].payment
            ? (
              <td>
                <img src={Tick} alt="Done" style={{ height: 30, padding: '2px 0px' }} />
                {' '}
                <img src={PayPal} alt="PayPal" style={{ height: 30 }} />
              </td>
            )
            : (
              <td>
                <img src={Rollover} alt="" style={{ height: 30 }} />
                <span>Rollover</span>
              </td>
            )}
        </tr>,
      );
    }
    /* if (payoutsView.length === 0) {
      payoutsView.push(
        <div>
          Oops nothing here!
        </div>,
      );
    } */
    const newArr1 = orders.filter((ele) => {
      if (ele.trip) return ele;
    });
    const newArr2 = orders.filter((ele) => {
      if (ele.status.toUpperCase() === 'CREDITED') return ele;
    });
    const newArr3 = orders.filter((ele) => {
      if (ele.status.toUpperCase() === 'PENDING') return ele;
    });

    const newArr4 = orders.filter((ele) => {
      if (ele.status.toUpperCase() === 'CANCELED') return ele;
    });
    const newArr5 = orders.filter((ele) => {
      if (ele.status.toUpperCase() === 'ADJUSTED') return ele;
    });
    const checkingArray = [];
    let filteredOrders = [];
    if (selectedFilter.indexOf(0) !== -1) checkingArray.push(...newArr1);
    if (selectedFilter.indexOf(1) !== -1) checkingArray.push(...newArr2);
    if (selectedFilter.indexOf(2) !== -1) checkingArray.push(...newArr3);
    if (selectedFilter.indexOf(3) !== -1) checkingArray.push(...newArr4);
    if (selectedFilter.indexOf(4) !== -1) checkingArray.push(...newArr5);
    for (let i = 0; i < orders.length; i++) {
      const ele = orders[i];
      let count = 0;
      for (let j = 0; j < checkingArray.length; j++) {
        if (checkingArray[j] === orders[i]) {
          count++;
        }
      }
      if (count > 0) {
        filteredOrders.push(ele);
      }
    }

    if (filteredOrders.length === 0 && selectedFilter.length === 0) {
      filteredOrders = Array.from(orders);
    }
    const orderView = [];
    if (filteredOrders.length) {
      for (let i = 0; i < filteredOrders.length && i < (5 * seeMoreOrder); i++) {
        const ele = filteredOrders[i];
        const trip = ele.trip.split('_')[0];
        orderView.push(
          <tr>
            <td>
              <span style={{ textTransform: 'uppercase' }}>{(ele.month).slice(0, 3)}</span>
              <span>
                {' '}
                {ele.date}
              </span>
              <span style={{ display: 'block' }}>{ele.year}</span>
            </td>
            <td style={{ maxWidth: 50, textTransform: 'capitalize' }}>
              <p
                className="store-name"

              >
                <span style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxWidth: 75,
                  cursor: 'pointer',
                }}
                >
                  {ele.store}
                </span>
                <span className="tooltip">{ele.store}</span>
              </p>

            </td>
            <td>
              <p className="trip-number">
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(ele.trip);
                  }}
                  style={{ color: '#4f91ce', cursor: 'pointer' }}
                >
                  {trip}
                </span>
                <span className="tooltip">{ele.trip}</span>
              </p>
            </td>
            <td style={ele.status === 'Canceled' ? { fontWeight: 'bold', color: theme.secondary } : { fontWeight: 'bold', color: theme.primary }}>
              {ele.status === 'Canceled' ? null : '+ '}
              $
              {ele.amount === '0.00' ? (
                <>
                  {ele.amount}
                  {' '}
                  <p className="info-img">
                    <span className="img"><img src={InfoIcon} alt="info" style={{ height: 15 }} /></span>
                    <span className="info-tooltip">The retailer does not offer any cash back on this category.</span>
                  </p>
                  {/* The retailer does not offer any cash back on this category. */}
                  {/* <Info /> */}
                </>
              ) : ele.amount}
            </td>
            <td style={ele.status === 'Canceled' ? { color: theme.primary } : { color: theme.secondary }} className="status">
              {ele.amount === '0.00' ? 'Not Applicable' : ele.status}
            </td>
          </tr>,
        );
      }
    } /* else {
      orderView.push(
        <div>
          Oops nothing here!
        </div>,
      );
    } */
    switch (activeIndex) {
      case 0:
        returnValue = (
          payoutsView.length ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <table className="payouts-table">
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>No. Of Orders</th>
                    <th>Cash Back</th>
                    <th>Payment Sent</th>
                  </tr>
                </thead>
                <tbody>
                  {payoutsView}
                </tbody>
              </table>
              {payouts.length > (5 + 10 * seeMorePayout) ? (
                <p
                  className="see-more"
                  onClick={() => {
                    const count = seeMorePayout;
                    this.setState({ seeMorePayout: count + 1 });
                  }}
                  style={{ color: theme.primary }}
                >
                  See All Payouts
                </p>
              ) : null}
            </div>
          )
            : <div style={{ padding: 20 }}><p style={{ textAlign: 'center', fontWeight: 'bold' }}>Start Shopping to Start Saving!</p></div>
        );
        break;
      case 1:
        returnValue = (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {filteredOrders.length ? (
              <table className="order-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Store</th>
                    <th>
                      <span>Order</span>
                      {' '}
                      <span>/</span>
                      {' '}
                      <span style={{ color: '#4f91ce' }}>Trip</span>
                    </th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orderView}
                </tbody>
              </table>
            ) : <div style={{ padding: 20 }}><p style={{ textAlign: 'center', fontWeight: 'bold' }}>Start Shopping to Start Saving!</p></div>}
            {!orderPagination && filteredOrders.length >= 5 ? (
              <p
                className="see-more"
                onClick={() => {
                  const count = seeMoreOrder - 1;
                  /* this.setState({ seeMoreOrder: count + 1 }); */
                  this.getMoreOrders(count + 1);
                }}
                style={{ color: theme.primary }}
              >
                See More Orders
              </p>
            ) : null}
          </div>
        );
        break;
      default:
        returnValue = (
          <div>
            Oops some issue occured!
          </div>
        );
    }
    return returnValue;
  }

  render() {
    const {
      activeIndex, loading, filterOpen, selectedFilter, monthCashback, isAmazon,
    } = this.state;
    const theme = this.context;
    /*     if (loading) {
      return (
        <div />
      );
    } */
    return (
      <Profile {...this.props} title="Payouts and Order History | cashX" description="Check payouts received and orders placed in the cashX website.">
        <div className="account" style={{ marginTop: 5 }}>
          <p className="heading-1" style={{ color: 'black' }}>
            cash
            <sup>x</sup>
            {' '}
            Payout
          </p>
          <p className="heading-2">
            Below is a look at your earned cash back and payouts per month.
            {' '}
            <br />
            A minimum $5 earnings is required for payout.
            <span style={{ color: '#EC168C' }}>*</span>
          </p>
          <div className="cashback-main-container">
            {filterOpen
              ? (
                <div className="buttons-div">
                  <div
                    id="done-button"
                    onClick={() => {
                      if (selectedFilter.length === 0) {
                        this.setState({ filterOpen: false });
                      } else {
                        this.filterUpdated();
                      }
                    }}
                  >
                    <p>Done</p>
                  </div>
                  <div
                    className="filters-button"
                    onClick={() => {
                      if (selectedFilter.indexOf(0) !== -1) {
                        const newFilters = [];
                        selectedFilter.forEach((ele) => {
                          if (ele !== 0) {
                            newFilters.push(ele);
                          }
                        });
                        this.setState({ selectedFilter: newFilters });
                      } else {
                        const newFilters = Array.from(selectedFilter);
                        newFilters.push(0);
                        this.setState({ selectedFilter: newFilters });
                      }
                    }}
                    style={selectedFilter.indexOf(0) !== -1 ? { backgroundColor: 'gray', borderColor: 'gray', color: 'white' } : null}
                  >
                    <span className="check-before" style={selectedFilter.indexOf(0) !== -1 ? { backgroundColor: 'black', borderColor: 'white' } : null}>
                      {selectedFilter.indexOf(0) !== -1 ? (
                        <svg
                          style={{
                            position: 'relative', left: -1, marginTop: -4, marginLeft: 4,
                          }}
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -46 417.81333 417"
                          width="12px"
                        >
                          <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                        </svg>
                      ) : null}
                    </span>
                    <p>All Trips</p>
                  </div>
                  <div
                    className="filters-button"
                    onClick={() => {
                      if (selectedFilter.indexOf(1) !== -1) {
                        const newFilters = [];
                        selectedFilter.forEach((ele) => {
                          if (ele !== 1) {
                            newFilters.push(ele);
                          }
                        });
                        this.setState({ selectedFilter: newFilters });
                      } else {
                        const newFilters = Array.from(selectedFilter);
                        newFilters.push(1);
                        this.setState({ selectedFilter: newFilters });
                      }
                    }}
                    style={selectedFilter.indexOf(1) !== -1 ? { backgroundColor: 'gray', borderColor: 'gray', color: 'white' } : null}
                  >
                    <span className="check-before" style={selectedFilter.indexOf(1) !== -1 ? { backgroundColor: 'black', borderColor: 'white' } : null}>
                      {selectedFilter.indexOf(1) !== -1 ? (
                        <svg
                          style={{
                            position: 'relative', left: -1, marginTop: -4, marginLeft: 4,
                          }}
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -46 417.81333 417"
                          width="12px"
                        >
                          <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                        </svg>
                      ) : null}
                    </span>

                    <p>Credited Orders</p>
                  </div>
                  <div
                    className="filters-button"
                    onClick={() => {
                      if (selectedFilter.indexOf(2) !== -1) {
                        const newFilters = [];
                        selectedFilter.forEach((ele) => {
                          if (ele !== 2) {
                            newFilters.push(ele);
                          }
                        });
                        this.setState({ selectedFilter: newFilters });
                      } else {
                        const newFilters = Array.from(selectedFilter);
                        newFilters.push(2);
                        this.setState({ selectedFilter: newFilters });
                      }
                    }}
                    style={selectedFilter.indexOf(2) !== -1 ? { backgroundColor: 'gray', borderColor: 'gray', color: 'white' } : null}
                  >
                    <span className="check-before" style={selectedFilter.indexOf(2) !== -1 ? { backgroundColor: 'black', borderColor: 'white' } : null}>
                      {selectedFilter.indexOf(2) !== -1 ? (
                        <svg
                          style={{
                            position: 'relative', left: -1, marginTop: -4, marginLeft: 4,
                          }}
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -46 417.81333 417"
                          width="12px"
                        >
                          <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                        </svg>
                      ) : null}
                    </span>

                    <p>Pending Orders</p>
                  </div>
                  <div
                    className="filters-button"
                    onClick={() => {
                      if (selectedFilter.indexOf(3) !== -1) {
                        const newFilters = [];
                        selectedFilter.forEach((ele) => {
                          if (ele !== 3) {
                            newFilters.push(ele);
                          }
                        });
                        this.setState({ selectedFilter: newFilters });
                      } else {
                        const newFilters = Array.from(selectedFilter);
                        newFilters.push(3);
                        this.setState({ selectedFilter: newFilters });
                      }
                    }}
                    style={selectedFilter.indexOf(3) !== -1 ? { backgroundColor: 'gray', borderColor: 'gray', color: 'white' } : null}
                  >
                    <span className="check-before" style={selectedFilter.indexOf(3) !== -1 ? { backgroundColor: 'black', borderColor: 'white' } : null}>
                      {selectedFilter.indexOf(3) !== -1 ? (
                        <svg
                          style={{
                            position: 'relative', left: -1, marginTop: -4, marginLeft: 4,
                          }}
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -46 417.81333 417"
                          width="12px"
                        >
                          <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                        </svg>
                      ) : null}
                    </span>

                    <p>Canceled Orders</p>
                  </div>
                  <div
                    className="filters-button"
                    onClick={() => {
                      if (selectedFilter.indexOf(4) !== -1) {
                        const newFilters = [];
                        selectedFilter.forEach((ele) => {
                          if (ele !== 4) {
                            newFilters.push(ele);
                          }
                        });
                        this.setState({ selectedFilter: newFilters });
                      } else {
                        const newFilters = Array.from(selectedFilter);
                        newFilters.push(4);
                        this.setState({ selectedFilter: newFilters });
                      }
                    }}
                    style={selectedFilter.indexOf(4) !== -1 ? { backgroundColor: 'gray', borderColor: 'gray', color: 'white' } : null}
                  >
                    <span className="check-before" style={selectedFilter.indexOf(4) !== -1 ? { backgroundColor: 'black', borderColor: 'white' } : null}>
                      {selectedFilter.indexOf(4) !== -1 ? (
                        <svg
                          style={{
                            position: 'relative', left: -1, marginTop: -4, marginLeft: 4,
                          }}
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -46 417.81333 417"
                          width="12px"
                        >
                          <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                        </svg>
                      ) : null}
                    </span>

                    <p>Adjusted Orders</p>
                  </div>
                </div>
              )
              : (
                <>
                  <div className="cashback-container">
                    <div id="cashback-desc">
                      <p>This Month</p>
                      <p>Cash Back:</p>
                    </div>
                    <p id="cashback-amount">
                      $
                      <span style={{ width: 17, display: 'inline-block' }} />
                      {' '}
                      {monthCashback}
                    </p>
                  </div>
                  <div>
                    {activeIndex === 0
                      ? (
                        <div
                          id="paypal-button"
                          onClick={() => {
                            this.props.history.push('/payment-method');
                          }}
                        >
                          <p>Payment Method:</p>
                          {!isAmazon
                            ? (
                              <>
                                <img src={PayPal} alt="" style={{ height: 20 }} />
                                <p style={{ color: '#00317a' }}>PayPal</p>
                              </>
                            )
                            : (
                              <>
                                <img src={Amazon} alt="" style={{ height: 20 }} />
                                <p style={{ color: '#00317a' }}>Amazon</p>
                              </>
                            )}
                        </div>
                      )
                      : (
                        <div
                          id="filter-button"
                          onClick={() => this.setState({ filterOpen: true })}
                        >
                          <p>Filters</p>
                          <img src={Filter} alt="" style={{ height: 20 }} />
                          {selectedFilter.length > 0 ? <span className="filter-label">{selectedFilter.length}</span> : null}
                        </div>
                      )}
                  </div>

                </>
              )}
          </div>
          <div className="payout-order-main-container">
            <div className="payout-order-selection-container">
              <div className="selection" onClick={() => this.setState({ activeIndex: 0 })}>
                <p style={activeIndex === 0 ? { color: 'black' } : { color: 'gray' }}>
                  Payouts
                </p>
                {activeIndex === 0 ? <p style={{ backgroundColor: theme.primary }} /> : null}
              </div>
              <div className="selection" onClick={() => this.setState({ activeIndex: 1 })}>
                <p style={activeIndex === 0 ? { color: 'gray' } : { color: 'black' }}>
                  Order History
                </p>
                {activeIndex === 1 ? <p style={{ backgroundColor: theme.primary }} /> : null}
              </div>
            </div>
            <div className="payout-order-body-container">
              {loading ? <ActivityIndicator /> : this.activeIndexBody()}
            </div>
          </div>
          <p className="fot-tex">
            <span>*</span>
            If less than $5 is earned during a month, your accumulated earnings will be rolled over to the next month. Once your accumulated earnings total $5 or more, your payout will be sent to your account automatically.
          </p>
          <p className="fot-tex">
            <span className="img"><img src={InfoIcon} alt="info" style={{ height: 20 }} /></span>
            The retailer does not offer any cash back on this category.
          </p>
        </div>
      </Profile>

    );
  }
}
Payout.propTypes = {
  user: {
    name: PropTypes.string,
  },
};
Payout.contextType = ThemeContext;
