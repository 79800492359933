/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React, { Component } from 'react';
// import Helmet from 'react-helmet';
// import PropTypes from 'prop-types';
import './index.css';
// import Footer from '../../View/Footer';
// import Header from '../../View/Header';
// import one from '../../images/about/01.png';
// import two from '../../images/about/02.png';
// import three from '../../images/about/03.png';
// import four from '../../images/about/04.png';
import progresscheckemail from '../../images/promotion/check-email.png';
import { ThemeContext } from '../../utils/theme';
// import { CHROME_EXTENSION_LINK } from '../../utils/constants';

export default class SweepstakeRegisterd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: '',
    };
    this.state.windowWidth = window.innerWidth;
    // this.setState({ this.state.windowWidth });
    this.setState((prevState) => ({ windowWidth: prevState.windowWidth }));
  }

  render() {
    // const theme = this.context;
    // console.log('c :>> ', this.state.windowWidth);

    // const c = window.innerWidth;
    return (
      // { c === 767 &&
      <div>
        {/* <Helmet>
          <title>Sweepstake Registerd | cashX</title>
          <meta name="description" content="Thankyou the cashx website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} /> */}
        <div className="SweepstakeRegisterd">
          <div className="sweepstakeregisterd-container">
            <h1>
              Thanks for registering with cash
              <sup>x</sup>
              .
            </h1>
            <div className="sweepstakeregisterd-b1">
              <span><img src={progresscheckemail} alt="img" /></span>
            </div>
            <div className="sweepstakeregisterd-b2">
              <p>
                * cash
                <sup>x</sup>
                {' '}
                extension only works on desktop Chrome browser.
              </p>
              <p>Auto apply coupons and get cash back every time you shop.</p>
              <p>The 2-in-1 shopping extension.</p>

              <p>Some apps save you money, some earn you cash back. We do both.</p>
              <p>
                With one click, cash
                <sup>x</sup>
                {' '}
                offers cash back whether a coupon works or not.
              </p>

            </div>

          </div>

        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
// SweepstakeRegisterd.propTypes = {
//   user: {
//     firstName: PropTypes.string,
//   },
//   history: PropTypes.object,
// };
SweepstakeRegisterd.contextType = ThemeContext;
