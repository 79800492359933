/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import './index.css';
import Profile from '../../View/Profile';
import { passwordValidator, cnfpasswordValidator } from '../../utils/regex';
import { ThemeContext } from '../../utils/theme';
import fetchPost from '../../utils/fetchPost';
import { updatePassword } from '../../utils/apiEndPoint';
import cookie from '../../utils/cookie';
import Loading from '../../images/google.gif';

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pwd: '',
      cnfPwd: '',
      error: '',
      submitLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const userToken = cookie.getCookie('userToken');
    if (userToken === null) {
      this.props.history.push('/login');
      this.props.onLogout();
    }
  }

  handleChange() {
    this.setState({ error: '', submitLoading: true });
    const { pwd, cnfPwd } = this.state;
    const pwdError = passwordValidator(pwd);
    const cnfPwdError = cnfpasswordValidator(cnfPwd, pwd);
    if (pwdError) {
      this.setState({
        error: pwdError, submitLoading: false,
      });
      return;
    } if (cnfPwdError) {
      this.setState({ error: cnfPwdError, submitLoading: false });
      return;
    }
    fetchPost(updatePassword, {
      newPassword: pwd,
      newRetypedPassword: cnfPwd,
    }, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookie.getCookie('userToken')}`,
    })
      .then((res) => {
        if (res.status === 'success') {
          this.props.history.push({
            pathname: '/account',
            state: { edited: true },
          });
        } else throw res.message;
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong', submitLoading: false });
      });
  }

  render() {
    const {
      pwd, cnfPwd, error, submitLoading,
    } = this.state;
    const theme = this.context;
    return (
      <Profile {...this.props} title="Change Password | cashX" description="Change your password for your cashX account">
        <div className="account ">
          <p className="heading-1" style={{ color: 'black' }}>Password Change</p>
          <p className="heading-2">Update your password to keep your account secure. Your password should contain one capitalized letter and one number. </p>
          <div className="account-details">
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
              <table className="details editable" style={{ marginTop: 25 }}>
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    New Password:
                  </td>
                  <td>
                    <input type="password" value={pwd} onChange={(e) => { this.setState({ pwd: e.target.value }); }} />

                  </td>
                </tr>
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    Confirm Password:
                  </td>
                  <td>
                    <input type="password" value={cnfPwd} onChange={(e) => { this.setState({ cnfPwd: e.target.value }); }} />
                  </td>
                </tr>
              </table>
            </div>
            {error && <p className="error-text">{error}</p>}
            <div className="handle-butn">
              <button
                type="button"
                onClick={this.handleChange}
                id="save-info"
                className={`${theme.name}-edit`}
              >
                {submitLoading ? <img src={Loading} alt="Loading.." style={{ height: 18, margin: '0px 60px' }} /> : 'Change Password'}
              </button>
              <button
                type="button"
                onClick={() => this.props.history.push({
                  pathname: '/account',
                  state: { edited: false },
                })}
                id="cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Profile>
    );
  }
}
ChangePassword.contextType = ThemeContext;
