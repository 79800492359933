/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import './index.css';
import Footer from '../../View/Footer';
import Header from '../../View/Header';
// import one from '../../images/about/01.png';
// import two from '../../images/about/02.png';
// import three from '../../images/about/03.png';
// import four from '../../images/about/04.png';
// import five from '../../images/about/05.png';
import { ThemeContext } from '../../utils/theme';
// import { CHROME_EXTENSION_LINK } from '../../utils/constants';

export default class ReferralThankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const theme = this.context;
    return (
      <div>
        <Helmet>
          <title>Thankyou | cashX</title>
          <meta name="description" content="Thankyou the cashx website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="referralThankyou">
          <div className="referralt-container">
            <h1>Thank you!</h1>
            <span>We will send a email to your friends shortly.</span>
            <div className="thank-button">
              <button
                type="button"
                onClick={() => this.props.history.push({
                  pathname: '/',
                  state: { edited: false },
                })}
                id="goto"
                style={{ backgroundColor: theme.septenary }}
              >
                Go to Cash
                <sup>x</sup>
              </button>

            </div>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}
ReferralThankyou.propTypes = {
  user: {
    firstName: PropTypes.string,
  },
  history: PropTypes.object,
};
ReferralThankyou.contextType = ThemeContext;
