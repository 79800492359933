import { cookieGet } from './constants';

export default function getParms() { //  onNewIp - your listener function for new IPs
  let parms = '';
  const cxDownload = cookieGet('CX_DWN');
  const clientId1 = cookieGet('CX_CLIENT') || 'intentx';
  const utmsource = cookieGet('UTM_SOURCE');
  const userid = cookieGet('CX_userId');
  const utmmedium = cookieGet('UTM_MEDIUM');
  const utmcampaign = cookieGet('UTM_CAMPAIGN');
  const reminder = cookieGet('reminder');
  const marketingSource = cookieGet('marketingSource');
  if (clientId1) {
    parms += `&clientId=${clientId1}`;
  }
  if (cxDownload) {
    parms += `&dwnc=${cxDownload}`;
  }

  if (userid) {
    parms += `&userID=${userid}`;
  }

  if (utmsource) {
    parms += `&utm_source=${utmsource}`;
  }

  if (utmmedium) {
    parms += `&utm_medium=${utmmedium}`;
  }
  if (utmcampaign) {
    parms += `&utm_campaign=${utmcampaign}`;
  }
  if (reminder) {
    parms += `&reminder=${reminder}`;
  }
  if (marketingSource) {
    parms += `&marketingSource=${marketingSource}`;
  }
  return fetch(`https://cashx-stag-api.intentx.com/cx/v1/user/update-user-event-mobile?${parms}`)
    .then((response) => response.json())
    .then((result) => {
      console.log('result :>> ', result);
    })
    .catch((error) => console.log('error', error));
}
