/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import './index.css';
import Footer from '../../View/Footer';
import Header from '../../View/Header';
import one from '../../images/home/01.png';
import two from '../../images/home/02.png';
import three from '../../images/home/03.png';
import four from '../../images/home/04.png';
import { ThemeContext } from '../../utils/theme';
import {
  CHROME_EXTENSION_LINK,
} from '../../utils/constants';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const theme = this.context;
    return (
      <div>
        <Header user={this.props.user} {...this.props} />
        <div className="home">
          <div className="home-save container">
            <div className="home-left-text">
              <h1 className="banner-heading-h1">2-in-1 online shopping extension</h1>
              <p className="banner-heading">
                Some apps save you money,
                <br />
                some earn you cash back.
                <br />
                We do both.
              </p>
              <p className="heading-sub">
                cash
                <sup>x</sup>
                {' '}
                automatically applies coupons and offers cash back while you shop online. All you have to do is install our Chrome extension.

              </p>
            </div>
            <p className="oneimg"><img src={one} alt="cashX Savings" /></p>
            <div className="add-btn-wrapper">
              <button className="add-chrome-btn" style={{ backgroundColor: theme.primary }} type="button" onClick={() => { window.open(CHROME_EXTENSION_LINK, '_blank'); }}>Add to Chrome — it’s free</button>
              <span className="backdrop-btn" />
            </div>
          </div>
          <div className="home-wrapper container">
            <div className="text-wrapper">
              <p className="heading">You Add to Cart. We Find the Best Coupons.</p>
              <p>
                A lot of times, retailers offer coupons or deals that you may not know about. cash
                <sup>x</sup>
                {' '}
                combs through these to find the deal that provides you with the biggest discount, and automatically applies it to your cart.
              </p>
            </div>
            <div className="image-wrapper">
              <img src={two} alt="cashX Savings" />
            </div>
          </div>
          <div className="home-wrapper container checkout-part">
            <div className="image-wrapper">
              <img src={three} alt="cashX Savings" />
            </div>
            <div className="text-wrapper">
              <p className="heading">
                Coupon or Not,
                <br />
                {' '}
                We Pay Cash Back.
              </p>
              <p>It doesn’t matter if you used any coupons or deals, our cash back is earned with each purchase at participating sites. We are serious about saving you money. We offer cash back at a range of popular online retailers.</p>
            </div>
          </div>
          <div className="home-wrapper container paypal-part">
            <div className="text-wrapper">
              <p className="heading">
                You Shop Each Month. We Pay You Each Month.
              </p>
              <p>
                As you shop each month using cash
                <sup>x</sup>
                , we cumulate your earned cash back and pay it back to you at the beginning of each month. There is no points system or complicated rewards process—we pay you cash through PayPal or Amazon gift cards.
              </p>
            </div>
            <div className="image-wrapper">
              <img src={four} alt="cashX Savings" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
Home.contextType = ThemeContext;
