/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
function getP(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}
export default async function droppingCookie() {
  const valOfCXDown = getCookie('CX_DWN');
  let valOfUserId = getCookie('CX_userId');
  let marketing_source = getCookie('MARKETING_SOURCE');

  if (!marketing_source) {
    marketing_source = getP('marketing_source');
    document.cookie = marketing_source && `MARKETING_SOURCE=${marketing_source};;path=/`;
  }
  if (!valOfUserId) {
    valOfUserId = getP('userId');
    document.cookie = valOfUserId && `CX_userId=${valOfUserId}`;
  }
  let cookie;
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  };
  if (!valOfCXDown) {
    cookie = makeid(20);
  } else {
    cookie = valOfCXDown;
  }
  document.cookie = `CX_DWN=${cookie};;path=/`;
  const clientId = getP('clientId') || 'intentx';
  document.cookie = `CX_CLIENT=${clientId};;path=/`;
  const utm_source = getP('source');
  const utm_medium = getP('medium');
  const utm_campaign = getP('campaign');
  const reminder = getP('reminder');
  let attach = '';
  if (valOfUserId) {
    attach = `&user_id=${valOfUserId}`;
  }
  if (utm_source) {
    document.cookie = `UTM_SOURCE=${utm_source};;path=/`;
    attach += `&utm_source=${utm_source}`;
  }
  if (utm_medium) {
    document.cookie = `UTM_MEDIUM=${utm_medium};;path=/`;
    attach += `&utm_medium=${utm_medium}`;
  }
  if (utm_campaign) {
    document.cookie = `UTM_CAMPAIGN=${utm_campaign};;path=/`;
    attach += `&utm_campaign=${utm_campaign}`;
  }
  if (reminder) {
    document.cookie = `reminder=${reminder};;path=/`;
    attach += `&reminder=${reminder}`;
  }
  if (marketing_source) {
    attach += `&marketingSource=${marketing_source}`;
  }

  return fetch(`https://cashx-api.intentx.com/cx/v1/user/download?clientId=${clientId}&dwnc=${cookie}${attach}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.userID || result.clientId) {
        document.cookie = `CX_userId=${result.userID};;path=/`;
        document.cookie = `CX_CLIENT=${result.clientId};;path=/`;
      }
    })
    .catch((error) => console.log('error', error));
}
