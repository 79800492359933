/* eslint-disable import/prefer-default-export */
/* eslint-disable radix */
export function representationalNumbers(num) {
  if (num) {
    const view = num.toString().split('.');
    const first = view[0];
    let second = view[1];

    if (view[1] < 10) {
      second = `${view[1]}0`;
    }
    if (view[1] === 0 || view[1] === null || view[1] === undefined) {
      second = '00';
    }
    return [parseInt(first).toLocaleString(), second.substr(0, 2)];
  }
  return ['0', '00'];
}
