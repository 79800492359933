/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import { representationalNumbers } from '../../utils/representationalNumbers';

export default function SideRail(props) {
  const { pathname } = props.history.location;
  const payouts = ['/payout', '/payment-method'];
  const theme = useContext(ThemeContext);
  const cashback = representationalNumbers(props.user.cashback);
  return (
    <div className="aside">
      <div className="aside-1">
        <p className="heading-1" style={{ color: theme.primary }}>
          Welcome
          {props.user.firstName ? null : '!'}
        </p>
        {props.user.firstName ? (
          <p className="heading-1" style={{ color: theme.primary }}>
            {props.user.firstName}
            !
          </p>
        ) : null}
        <p className="heading-2">Member since</p>
        {props.user.month && props.user.day && props.user.day ? (
          <p className="heading-3">
            {props.user.month.toUpperCase()}
            {' '}
            {props.user.day}
            {' '}
            {props.user.year}
          </p>
        )
          : null}
      </div>
      <div className="aside-2">
        <p className="heading-3">Earned Cash Back</p>
        <p className="heading-2">Since you&apos;ve become a member you&apos;ve earned...</p>
        <p className="heading-1" style={{ fontWeight: 'normal', color: 'gray', marginTop: 12 }}>
          $
          {' '}
          {cashback[0]}
          .
          {cashback[1]}
          { }
        </p>
        {payouts.indexOf(pathname) !== -1 ? null
          : (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
              <span
                className="see-payouts"
                onClick={() => {
                  props.history.push('/payout');
                }}
                role="button"
              >
                See payouts
              </span>
            </div>
          )}
      </div>
      {payouts.indexOf(pathname) !== -1
        ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
            <span
              className="see-payouts acc"
              onClick={() => {
                props.history.push('/account');
              }}
              style={{
                padding: '6px 43px',
              }}
              role="button"
            >
              Account
            </span>
          </div>
        )
        : null}
    </div>
  );
}
