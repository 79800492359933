/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import InputRow from '../../View/InputRow';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import './index.css';
import Loading from '../../images/google.gif';
import { emailValidator } from '../../utils/regex';
import { ThemeContext } from '../../utils/theme';
import fetchPost from '../../utils/fetchPost';
import { referral } from '../../utils/apiEndPoint';
import cookie from '../../utils/cookie';

export default class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: [
        {
          value: '',
          error: '',
        },
      ],
      num: 1,
      error: '',
      submitLoading: '',
      cxClientId: '',
      userId: '',
      userToken: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.focusNextField = this.focusNextField.bind(this);
    this.inputs = {};
  }

  componentDidMount() {
    cookie.getCookie('userId', (val) => {
      if (val.userId) {
        this.setState({ userId: val.userId });
      }
    });
    // cookie.getCookieFromCashxWebsite('CX_CLIENT', (res) => {
    //   this.setState({ cxClientId: res });
    // });
    cookie.getCookie('userToken', (result) => {
      if (result.userToken) {
        this.setState({ userToken: result.userToken });
      }
    });
  }

  focusNextField(id) {
    this.inputs[id].focus();
  }

  handleSubmit() {
    const {
      email, cxClientId, userId, userToken,
    } = this.state;
    this.setState({ error: '', submitLoading: true });
    let emailError = false;
    const a = [...email];
    a.forEach((ele) => {
      const error = emailValidator(ele.value);
      if (error) {
        emailError = true;
        // this.setState({
        //   email: { ...email, error: ele.error },
        // });
        ele.error = error;
      } else {
        ele.error = '';
        // this.setState({
        //   email: { ...email, error: '' },
        // });
      }
    });
    if (emailError) {
      this.setState({
        email: a,
        submitLoading: false,
      });
      return;
    }

    const body = {
      clientId: cxClientId || 'intentx',
      emails: email.map((e) => e.value),
      userId,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    if (userToken) {
      headers.Authorization = `Bearer ${userToken}`;
    }
    fetchPost(referral, body, headers).then((res) => {
      const keys = Object.keys(res);
      if (!keys || keys.length === 0 || res.status === 'failure') {
        this.setState({ error: 'Something went wrong.', submitLoading: false });
      } else {
        this.props.history.push('/referral-thankyou');
        // this.props.window;
      }
    });
  }

  render() {
    const theme = this.context;

    const {
      email, num, submitLoading, error,
    } = this.state;
    const emailView = [];
    for (let i = 0; i < num; i += 1) {
      emailView.push(
        <InputRow
          value={email[i].value}
          onChange={(e) => {
            const a = Array.from(email);
            a.forEach((ele, index) => {
              if (index === i) {
                ele.value = e;
              }
            });
            this.setState({ email: a });
          }}
          type="email"
          placeholder="Email"
          error={email[i].error}
          onRef={(ref) => {
            this.inputs[`email${i}`] = ref;
          }}
          onSubmit={() => {
            if (i === num - 1) {
              this.handleSubmit();
            } else {
              this.focusNextField(`email${i + 1}`);
            }
          }}
        />,
      );
    }
    return (
      <div>
        <Helmet>
          <title>Friend Referral | cashX</title>
          <meta name="description" content="Thankyou the cashx website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="referral">
          <div className="referral-container">

            <div className="referral-heading">
              <h1>Share the Love!</h1>
              <span>
                Be the first to tell your friends about cash
                <sup>x</sup>
                :
              </span>
            </div>
            <div className="referal-block">
              <div className="referal-inpuyblock">
                {emailView}

                {num === 4 ? null : (
                  <p
                    onClick={() => {
                      this.setState((prevState) => ({
                        num: prevState.num + 1,
                        email: [...prevState.email, { value: '', error: '' }],
                      }));
                    }}
                    style={{
                      color: '#707070',
                      fontSize: 15,
                      cursor: 'pointer',
                    }}
                  >
                    + add another
                  </p>
                )}
              </div>

              {error ? (
                <p
                  style={{
                    textAlign: 'center',
                    color: 'red',
                  }}
                >
                  {error}
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
              {submitLoading ? (
                <div className="send">
                  <img src={Loading} style={{ height: 15 }} alt="loding" />
                </div>
              ) : (
                <div
                  onClick={this.handleSubmit}
                  className="sub-button"
                  style={{ backgroundColor: theme.primary, color: 'white' }}
                  role="button"
                  tabIndex="0"
                >
                  Submit
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
Referral.contextType = ThemeContext;
