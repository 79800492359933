import React from 'react';

export const themes = {
  cashx: {
    name: 'cashx',
    primary: '#ec168c',
    secondary: '#afafaf',
    tertiary: '#545c64',
    quaternary: '#9AA0A6',
    quinary: '#000',
    senary: '#950654',
    septenary: '#FFD800',
    octonary: '',
    nonary: '',
    denary: '',
  },
  dailymail: {
    name: 'dailymail',
    primary: '#2052a0',
    secondary: '#afafaf',
    tertiary: '#545c64',
    quaternary: '#9AA0A6',
    quinary: '#000',
    senary: '#183d78',
    septenary: '',
    octonary: '',
    nonary: '',
    denary: '',
  },
};
export const ThemeContext = React.createContext(themes.cashx);
