/* eslint-disable global-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PayPal from '../../images/paypal.png';
import CashxTick from '../../images/cashx/tick.png';
import DailyMailTick from '../../images/dailymail/tick.png';
import './index.css';
import Profile from '../../View/Profile';
import { ThemeContext } from '../../utils/theme';
import { nameValidator, emailValidator } from '../../utils/regex';
import fetchPost from '../../utils/fetchPost';
import { editProfile, addPayPalId } from '../../utils/apiEndPoint';
import cookie from '../../utils/cookie';
import Loading from '../../images/google.gif';

const clientId = 'AYgW6-9WI5Dn45Hf44Yr_1r7tCsPomP4wSB-xQ9Hyt-SD0Dmod1zy6c32kVhCP5g8LDjatFVwWxeXz_p';
const paypalurl = `https://www.paypal.com/connect?flowEntry=static&client_id=${clientId}&scope=openid%20email&redirect_uri=${encodeURIComponent('https://getcashx.com/edit-details')}&response_type=code&fullPage=true&authend=live`;
export default class EditAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      paypal: '',
      error: '',
      submitLoading: false,
      loading: false,
    };
    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    const { search } = this.props.location;
    const userToken = cookie.getCookie('userToken');
    if (userToken === null) {
      this.props.history.push('/login');
      this.props.onLogout();
    }
    const { user } = this.props;
    this.setState({
      fname: user.firstName,
      lname: user.lastName,
      email: user.email,
      paypal: user.paypalEmail ? user.paypalEmail : '',
    });
    if (search) {
      this.setState({ loading: true });
      const searchArray = search.split('=')[1];
      const code = searchArray.split('&')[0];
      fetchPost(addPayPalId + code, null, {
        Authorization: `Bearer ${userToken}`,
      }).then((res) => {
        if (res.status === 'success') {
          this.setState({
            paypal: res.value,
          });
        } else {
          this.setState({
            error: res.status,
          });
        }
        this.props.history.push('/edit-details');
        this.setState({ loading: false });
      }).catch(() => {
        this.setState({ error: 'Something went wrong.', loading: false });
      });
    }
  }

  handleSave() {
    this.setState({ error: '', submitLoading: true });
    const {
      fname, lname, email, paypal,
    } = this.state;
    const fNameError = nameValidator(fname, 'First Name');
    const lNameError = nameValidator(lname, 'Last Name');
    const emailError = emailValidator(email);
    if (fNameError || lNameError || emailError) {
      let error = '';
      if (fNameError) error = `${fNameError}. `;
      if (lNameError) error += `${lNameError}. `;
      if (emailError) error += `${emailError}`;
      this.setState({ error, submitLoading: false });
      return;
    }
    const data = {
      // eslint-disable-next-line key-spacing
      email,
      firstName: fname,
      lastName: lname,
      paypalEmail: paypal,
    };
    const userToken = cookie.getCookie('userToken');
    fetchPost(editProfile, data, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
      .then((res) => {
        if (res.status === 'success') {
          cookie.setCookie({ userToken: res.data.new_token });
          this.props.infoUpdate(data);
          setTimeout(() => {
            this.props.history.push({
              pathname: '/account',
              state: { edited: true },
            });
          }, 1000);
        } else {
          this.setState({ error: 'Something went wrong', submitLoading: false });
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong', submitLoading: false });
      });
  }

  render() {
    const {
      fname, lname, email, paypal, error, submitLoading, loading,
    } = this.state;
    const theme = this.context;
    let Tick = CashxTick;
    if (theme.name === 'dailymail') Tick = DailyMailTick;
    return (
      <Profile {...this.props} title="Edit Account Details | cashX" description="Edit your cashX account details">
        <div className="account">
          <p className="heading-1" style={{ color: 'black' }}>
            Hey
            {' '}
            {this.props.user.firstName ? this.props.user.firstName : 'there!'}
          </p>
          {this.props.user.firstName
            ? <p className="heading-2">Here is the info we have, update it if you want it.</p>
            : <p className="heading-2">We’d love to start sending money your way, but we need some information first.</p>}
          <div className="account-details edit">
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
              <table className="details editable">
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    First Name:
                  </td>
                  <td>
                    <input type="name" value={fname} onChange={(e) => this.setState({ fname: e.target.value })} placeholder="First Name" />

                  </td>
                </tr>
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    Last Name:
                  </td>
                  <td>
                    <input type="name" value={lname} onChange={(e) => this.setState({ lname: e.target.value })} placeholder="Last Name" />

                  </td>
                </tr>
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    Email:
                  </td>
                  <td>
                    <input type="email" value={email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Email Addres" />

                  </td>
                </tr>
                <tr className="paypal-row">
                  <td className="row-name" style={{ paddingTop: 7, paddingLeft: 7 }}>
                    <img src={PayPal} alt="PayPal" style={{ height: 30 }} />
                  </td>
                  <td style={{ color: '#0f2169', textAlign: 'center' }}>
                    {loading ? (
                      <>
                        <img
                          src={require('../../images/loader-cashx.gif')}
                          alt="Loading..."
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </>
                    )
                      : paypal ? (
                        <>
                          <p>{paypal}</p>
                          <a
                            onClick={() => {
                              window.location.href = paypalurl;
                            }}
                            id="change-account"
                          >
                            Change PayPal Account
                          </a>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="hook-paypal"
                          onClick={() => {
                            window.location.href = paypalurl;
                          }}
                        >
                          <img src={PayPal} alt="PayPal" />
                          <span>Hookup your PayPal account</span>
                        </button>
                      )}
                  </td>
                  {paypal
                    ? (
                      <td>
                        <img src={Tick} alt="Synced" style={{ height: 20, marginLeft: 10 }} />
                      </td>
                    ) : null}
                </tr>

              </table>
            </div>
            {error ? <p style={{ fontSize: 12, textAlign: 'center', color: 'red' }}>{error}</p> : null}
            {paypal
              ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="paypal-enabled">
                    <button
                      type="button"
                      onClick={this.handleSave}
                      id="save-info"
                      className={`${theme.name}-edit`}
                    >
                      {submitLoading
                        ? <img src={Loading} alt="Loading..." style={{ height: 18, margin: '0px 26px' }} />
                        : 'Save Info'}
                    </button>
                    <button
                      type="button"
                      onClick={() => this.props.history.push({
                        pathname: '/account',
                        state: { edited: false },
                      })}
                      id="cancel"
                    >
                      Cancel
                    </button>
                  </div>
                  <button type="button" id="change-password" style={{ margin: 10 }} onClick={() => this.props.history.push('/change-password')}>Change Password</button>
                </div>
              )
              : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    type="button"
                    onClick={this.handleSave}
                    id="save-info"
                    className={`${theme.name}-edit`}
                  >
                    {submitLoading
                      ? <img src={Loading} alt="Loading..." style={{ height: 18, margin: '0px 26px' }} />
                      : 'Finish Registration'}
                  </button>
                </div>
              )}
          </div>
        </div>
      </Profile>
    );
  }
}
EditAccount.contextType = ThemeContext;
