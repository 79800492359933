/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';

// import './index.css';
import '../SweepstakeAirfyer/index.css';
import Helmet from 'react-helmet';
import SweepstakeMobiled from '../SweepstakeMobiled';
import Footer from '../../../View/Footer';
import HowBox from '../../../View/Components/HowBox';
import FavoriteStore from '../../../View/Components/FavoriteStore';
import SaveFavorite from '../../../View/Components/SaveFavorite';
import PromotionBanner from '../../../View/Components/PromotionBanner';
import Header from '../../../View/PromotionHeaderSub';
import SweepStakeLogin from '../../SweepStakeLogin';
import SweepstakeRegisterd from '../../SweepstakeRegisterd';
import landingCashX from '../../../images/promotion/AirpodsPro.png';
import { ThemeContext } from '../../../utils/theme';
import landingpagecookie from '../../../utils/landingpagecookie';
// import landingpageredirect from '../../../utils/landingpageredirect';
import cookie from '../../../utils/cookie';
import { cookieGet, API_BASE_URL } from '../../../utils/constants';

function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isregister: null,
      cxCookie: null,
      screenWidth: window.screen.availWidth,
    };

    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    const userToken = cookie.getCookie('userToken');
    const checkurl = window.location.href;
    const cxDownload = cookieGet('CX_DWN');
    this.setState({ cxCookie: cxDownload });
    console.log('cxDownload :>> ', cxDownload);
    console.log('checkurl :>> ', checkurl);
    console.log('userToken :>> ', userToken);
    const d = this.getMobileOperatingSystem();
    // const { href } = window.location;
    if (this.state.screenWidth <= 767) {
      const valOfUserId = getCookie('CX_userId');
      if (!valOfUserId && d !== 'Android') {
        landingpagecookie(`${API_BASE_URL}/cx/v1/user/landing-page`, 'Mobile');
      }
      if (!valOfUserId && d === 'Android') {
        landingpagecookie(`${API_BASE_URL}/cx/v1/user/landing-page`, 'Mobile');
      }
    } else {
      const valOfUserId = getCookie('CX_userId');
      if (!valOfUserId) {
        landingpagecookie(`${API_BASE_URL}/cx/v1/user/landing-page`, 'Desktop');
      }
    }

    window.addEventListener('resize', this.screenresize);
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

  handleCallback(childData) {
    this.setState({ isregister: childData });
  }

  render() {
    const valOfUserId = getCookie('CX_userId');
    console.log('valOfUserId :>> ', valOfUserId);
    const theme = this.context;
    const { isregister } = this.state;
    const deviceos = this.getMobileOperatingSystem();
    console.log('deviceos :>> ', deviceos);
    // if (deviceos === 'Android' && !valOfUserId) {

    // }
    return (
      // screenWidth <= 767
      // && (
      <div id="sweep">
        <Helmet>
          <title>cashX: We’re giving away an AirPods Pro</title>
        </Helmet>
        <Header user={this.props.user} {...this.props} txt="AirPods Pro" />
        <div className="home1">
          {(deviceos === 'Android' || deviceos === 'iOS')
            && <SweepstakeMobiled deviceos={deviceos} />}
          <>
            {isregister === null && deviceos !== 'Android' && deviceos !== 'iOS'
              && (
                <div className="mobilesweepview">
                  <SweepStakeLogin user={this.props.user} cxcookie={this.state.cxCookie} parentCallback={this.handleCallback} {...this.props} />
                  {/* <SweepstakeSignUp user={this.props.user} {...this.props} /> */}
                </div>
              )}
            {this.state.isregister !== null && this.state.isregister.status === 'success' && (
              <div className="mobilesweepview">
                <SweepstakeRegisterd {...this.props} />
              </div>
            )}
          </>
          <>
            {deviceos !== 'Android' && deviceos !== 'iOS'
              && (
                <div className="desktopsweepview">

                  <>
                    <PromotionBanner
                      txt="AirPods Pro"
                      theme={theme}
                      bimg={landingCashX}
                    />
                    <SaveFavorite />
                    <HowBox />
                    <div className="favstore">
                      <FavoriteStore />
                    </div>
                  </>
                </div>
              )}
          </>
          <Footer />
        </div>
      </div>
    );
    // );
  }
}
Home.contextType = ThemeContext;
