/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable key-spacing */
/* eslint-disable comma-dangle */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import './index.css';
// import { ThemeContext } from '../../utils/theme';

export default function PrivacyPolicy(props) {
  // const theme = useContext(ThemeContext);
  return (
    <div>
      <Helmet>
        <title>Privacy Policy | cashX</title>
        <meta name="description" content="Review the privacy policy for the cashX website." />
      </Helmet>
      <Header user={props.user} {...props} />
      <div style={{ marginTop: 100, minHeight: '64vh', padding: '10px' }}>
        <h2 style={{ textAlign: 'center' }}>
          cash
          <sup>x</sup>
          {' '}
          owned by
        </h2>
        <h2 style={{ textAlign: 'center' }}>
          INTENTX INC.
        </h2>
        <br />
        <h1 style={{ textAlign: 'center' }}>
          PRIVACY POLICY
          {' '}
        </h1>
        <br />
        <p style={{ textAlign: 'center' }}>Last Updated: September 1, 2020</p>
        <br />
        <div style={{ maxWidth: 1200, margin: 'auto' }} className="terms-body">
          <ol className="main-list">
            <li>
              <h3>INTRODUCTION</h3>
              <p>
                <br />
                <span style={{ fontWeight: 'bold' }}>intentX Inc.</span>
                {' '}
                (“
                <span className="underlined">intentX</span>
                ,”
                <span className="underlined">we</span>
                ,”
                <span className="underlined">us</span>
                ,” or “
                <span className="underlined">our</span>
                ”) respects the privacy of its Users (“
                <span className="underlined">User</span>
                ,” “
                <span className="underlined">your</span>
                ,” or “
                <span className="underlined">you</span>
                ”). This Privacy Policy (the “
                <span className="underlined">Privacy Policy</span>
                ”) explains how we collect, use, disclose, and safeguard your information when you use intentX Platform (the “
                <span className="underlined">Platform</span>
                ”) through intentX’s website at
                {' '}
                <a href="https://www.intentx.com">www.intentx.com</a>
                {' '}
                (the “
                <span className="underlined">Website</span>
                ”).
              </p>
              <br />
              <p>
                intentX is committed to protecting the privacy of its Users whose information is collected and stored while using intentX’s Platform through our Website.
              </p>
              <br />
              <p>
                The capitalized terms have the same meaning as ascribed in our
                {' '}
                <a target="_blank" href="/website-terms-of-use">Website Terms of Use</a>
                {' '}
                or
                {' '}
                <a target="_blank" href="terms-of-service">Terms of Service</a>
                {' '}
                as applicable, unless otherwise noted here.
              </p>
              <br />
              <p>
                PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. BY ACCESSING OR USING OUR WEBSITE AND PLATFORM, YOU AGREE TO ACCEPT ALL THE TERMS CONTAINED IN THIS PRIVACY POLICY AND ACKNOWLEDGE AND AGREE WITH THE PRACTICES DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND USE OUR WEBSITE AND PLATFORM.
              </p>
              <br />
              <p>
                IF YOU HAVE ANY QUESTIONS REGARDING THIS PRIVACY POLICY, PLEASE SEND US AN EMAIL AT
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">PRIVACY@INTENTX.COM</a>
                .
              </p>
              <br />
              <p style={{ fontWeight: 'bold' }}>
                WE DO NOT SELL YOUR PERSONAL INFORMATION, NOR DO WE INTEND TO DO
              </p>
              <br />
            </li>
            <li>
              <h3>
                TERRITORIAL RESTRICTION
              </h3>
              <p>
                <br />
                Our Website and Platform are only available for use and access in the United States. Our Website and Platform are not available for use or download by residents of, visitors to, or your employees who reside in the European Union (collectively a “European”). If you are a European, please do not access, register, and/or use our Website or Platform. If you are a resident of the United States (“US”), the laws of the State of Delaware, United States shall apply.  If you are a resident of any other country, please ensure compliance with all local laws prior to using our Website or Platform.  You must comply with this Privacy Policy and our
                {' '}
                <a target="_blank" href="/website-terms-of-use">Website Terms of Use</a>
                {' '}
                and
                {' '}
                <a target="_blank" href="terms-of-service">Terms of Service</a>
                , as applicable.
              </p>
              <br />
              <p>
                If you have any questions regarding this Section, please email us at
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                .
              </p>
              <br />
            </li>
            <li>
              <h3>
                WHAT INFORMATION DO WE COLLECT?
              </h3>
              <p>
                <br />
                In order to provide our services to you, we collect personal information that you share with us when you use our Website, App, or Platform.  The information you share will allow us to provide additional features to you.  As part of the registration process, we may collect information to personalize your profile and account, such as your name, address, online contact information such as your email address or username or your social media account information, email address or username combined with a password or security question and answer, phone number, user photograph or profile picture, and other similar information.  If you would like to update your profile and account, you may do so on our Platform via the profile settings. To better personalize your user experience, we may also collect information such as persistent identifiers that can be used to identify you across different websites or other online services, your browsing and search histories including your interaction with our Platform, your commercial purchase information, and your geolocation.  Any information we collect will be stored on our secure servers. We currently do not collect or store any credit cards or bank information, as we are using a third-party payment processor.  However, we will update this Privacy Policy when we start using and storing such information. We will also inform you via reasonable means if we start collecting such information from you.
              </p>
              <br />
            </li>
            <li>
              <h3>HOW DO WE COLLECT INFORMATION?</h3>
              <p>
                <br />
                We collect personal information from you in the following ways:
                <ol className="four-main-list">
                  <br />
                  <li>
                    At registration on our Website or Platform;
                  </li>
                  <li>
                    In email, text, and other electronic messages between you and our Website or Platform;
                  </li>
                  <li>
                    Through mobile and desktop applications your downloads from our Website or Platform, which provides dedicated non-browser based interaction between you and our Website or Platform;
                  </li>
                  <li>
                    When you interact with our advertising and applications on third-party website and services, if those applications or advertising include a link to this Privacy Policy;
                  </li>
                  <li>
                    From you placing an order, which includes details of transactions you carry out on our Website  or Platform;
                  </li>
                  <li>
                    When you subscribe to a newsletter;
                  </li>
                  <li>
                    From your responses to a survey;
                  </li>
                  <li>
                    From forms filled out by you;
                  </li>
                  <li>
                    From records or copies of correspondences (including email addresses) if you contact us;
                  </li>
                  <li>
                    From search queries on our Website or Platform; and
                  </li>
                  <li>
                    When you post information to be published or displayed on our Website or Platform.
                  </li>
                </ol>
                <br />
                We collect information from you automatically when you navigate through our Website or Platform in the following ways:
                <ol className="four-main-list">
                  <br />
                  <li>
                    {' '}
                    Usage details;
                  </li>
                  <li>
                    IP addresses;
                  </li>
                  <li>
                    Information obtained through browser cookies;
                  </li>
                  <li>
                    Information obtained through flash cookies;
                  </li>
                  <li>
                    Web beacons on our Website;
                  </li>
                  <li>
                    Web beacons on emails sent by us; and
                  </li>
                  <li>Other tracking technologies. </li>
                </ol>
                <br />
                We also collect information from third party business partners that provide us with product data, deals, and coupons from their partners, which we then use on our Website.
              </p>
              <br />
            </li>
            <li>
              <h3>HOW DO WE USE YOUR INFORMATION?</h3>
              <p>
                <br />
                We use the information that you provide to:
                <ol className="four-main-list">
                  <br />
                  <li>
                    Personalize your experience in using our Platform;
                  </li>
                  <li>
                    Provide you with information, products, or services requested from us;
                  </li>
                  <li>
                    Present our Website and Platform and their contents to you;
                  </li>
                  <li>
                    Provide you with notices about account and/or subscription, including expiration and renewal notices;
                  </li>
                  <li>
                    Carry out obligations and enforce rights arising from contracts entered into between you and us, including billing and collection;
                  </li>
                  <li>
                    Notify you about changes to our Website and Platform and any products or services;
                  </li>
                  <li>
                    Allow you to participate in interactive features on our Website and Platform;
                  </li>
                  <li>
                    Improve the Website and Platform;
                  </li>
                  <li>
                    Improve our customer service;
                  </li>
                  <li>
                    Administer contests, promotions, and surveys or other Website and Platform features;
                  </li>
                  <li>
                    Process transactions;
                  </li>
                  <li>
                    Anonymize data and aggregate data for statistics, which will be shared with our business and marketing partners;
                  </li>
                  <li>
                    Contact you about our products and services that may be of interest;
                  </li>
                  <li>
                    Contact you about third parties’ goods and services;
                  </li>
                  <li>
                    Enable the display of advertisements to our advertisers’ target audiences, although personal information is not shared with advertisers without your consent; and
                  </li>
                  <li>
                    Send you periodic emails, in accordance with the CAN-SPAM Act of 2003 as detailed in Section 14, via the email address provided by you to (i) send information, respond to inquiries, and/or other requests or questions; (ii) process orders and send information and updates pertaining to such orders; (iii) send additional information related to your product and/or service; and (iv) market to our mailing list or continue to send email to you after the original transaction has occurred.
                  </li>
                </ol>
              </p>
              <br />
            </li>
            <li>
              <h3>OUR COOKIE POLICY</h3>
              <p>
                <br />
                Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and receive identifiers and other information on computers, phones, and other devices. Other technologies, including data we store on your web browser or device, identifiers associated with your device, and other software, are used for similar purposes. In this Privacy Policy, we refer to all of these technologies as “
                <span className="underlined">Cookies</span>
                .”
              </p>
              <br />
              <p>
                We use Cookies on our Website to (a) help remember and process items in the shopping cart, (b) understand and save your preferences for future visits, (c) keep track of advertisements, (d) compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future, and (e) allow trusted third-party services that track this information on our behalf.  You can set your browser to refuse all or some browser Cookies, but it will turn off essential features. We honor Do Not Track signals and, if one is in place, we will not track, plant cookies, or use advertising.
              </p>
              <br />
              <p>
                We allow third party behavioral tracking and links to third-party web pages.  Occasionally, at our discretion, we may include or offer third-party products or services on our Website or Platform.  These third-party sites have separate and independent privacy policies.  We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our Website or Platform and welcome any feedback at about these sites.  Please contact us at
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                . We use third parties who have access to the personal information of our consumers to perform some of the operational functions (such as billing and collection) to provide full service to our consumers.
              </p>
              <br />
            </li>
            <li>
              <h3>HOW DO WE PROTECT INFORMATION WE COLLECT?</h3>
              <p>
                <br />
                Our Website receives regular security scans and penetration tests.  Our Website also receives regular malware scans.  In addition, our Website use san SSL certificate as an added security measure. We require username and passwords for our employees who can access the personal information of Users that we store and/or process on our Platform and servers. In addition, we actively prevent third parties from getting access to the personal information of Users that we store and/or process on our Platform and servers.  We accept payment by credit card through a third-party credit card processor on our behalf.  We will implement reasonable security measures every time you (a) place an order, or (b) enter, submit, or access your information, (c) register, or (d) access our Platform.
              </p>
              <br />
            </li>
            <li>
              <h3>DATA SECURITY MEASURES.</h3>
              <p>
                <br />
                <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }} className="eleven-list">
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Security Measures.</span>
                      {'   '}
                      We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.  The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website or Platform, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website or Platform. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our Website or Platform.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Fair Information Practice Principles.</span>
                      {'   '}
                      In the event of a personal data breach, we will notify the User within 48 hours via our Platform notification system on our Website.  We agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.
                    </p>
                  </li>
                </ol>
              </p>
            </li>
            <li>
              <h3>DISCLOSURE OF PERSONAL INFORMATION</h3>
              <p>
                <br />
                There are times when we may share Personal Information that you have shared with us may be shared by intentX with others to enable us to provide you over Services, including contractors, service providers, and third parties (“
                <span className="underlined">Partners</span>
                ”). This section discusses only how intentX may share such information with Partners. We will ensure that our Partners protect your Personal Information. The following describe how and with whom we may share your Personal Information:
              </p>
              <br />
              <p>
                <p style={{ fontWeight: 'bold' }}>
                  Disclosure of Personal Information.
                </p>
                <br />
                <ol className="four-main-list">
                  <li>
                    We may disclose aggregated, de-personalized information about you that does not identify any individual or personally identifiable information to other parties without restriction, such as for marketing, advertising, or other uses.
                  </li>
                  <li>
                    We may disclose personal information to our subsidiaries and affiliates.
                  </li>
                  <li>
                    We may disclose personal information to contractors, services providers, and other third parties.
                  </li>
                  <li>
                    We require all contractors, service providers, and other third parties to whom we disclose your personal information to be under contractual obligations to keep personal information confidential and to use it only for the purposes for which we disclose them.
                  </li>
                  <li>
                    We may disclose personal information in the event of a merger, sale of business, etc.
                  </li>
                  <li>
                    We may disclose to third parties to market their products and services to you if you have either consented or not opted out of these disclosures.
                  </li>
                  <li>
                    We may disclose personal information to third parties to market their products and services if you have either consented or not opted out of these disclosures.
                  </li>
                  <li>
                    We require all other Partners, to whom we disclose your personal information, to enter into contracts with us to keep personal information confidential and use it only for the purposes for which we disclose it to such Partners.
                  </li>
                  <li>
                    We disclose personal information to fulfill the purpose for which you have provided it, for instance, if you gave us an email address to use the “email a friend” feature of the Platform.
                  </li>
                  <li>
                    We may disclose personal information for any other purpose for which you have provided it.
                  </li>
                  <li>
                    We may only disclose personal information as described in this Privacy Policy or your consent.
                  </li>
                </ol>
                <br />
                <p style={{ fontWeight: 'bold' }}>Other Disclosure of Personal Information. </p>
                <br />
                <ol className="four-main-list">
                  <li>
                    We will disclose personal information (i) to comply with any court order, law, or legal process, including to respond to any government or regulatory request, (ii) to enforce or apply our
                    {' '}
                    <a target="_blank" href="/website-terms-of-use">Website Terms of Use</a>
                    {' '}
                    or
                    {' '}
                    <a target="_blank" href="terms-of-service">Terms of Service</a>
                    {' '}
                    and other agreements, including for billing and collection purposes, (iii) if we believe it is necessary or appropriate to protect the rights, property, or safety of intentX, our customers or others, and/or (iv) if it is necessary or appropriate to protect the rights, property, or safety of intentX, our customers, or others, and this includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                  </li>
                </ol>
                <br />
                <p style={{ fontWeight: 'bold' }}>Third Party Disclosure.</p>
                <br />
                <ol className="four-main-list">
                  <li>
                    We do not sell, trade, rent, or otherwise transfer personal information to others, unless we provide you with advance notice.  This does not include our hosting partners and other parties who assist us in operating our Website or Platform, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                  </li>
                  <li>
                    We provide non-personally identifiable visitor information to third parties for marketing, advertising, and other similar purposes.
                  </li>
                </ol>
                <br />
                <p style={{ fontWeight: 'bold' }}>Choices Users Have About How intentX Uses and Discloses Information. </p>
                <br />
                <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }} className="eleven-list">
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Tracking Technologies and Advertising.</span>
                      {' '}
                      Users can set their browser to refuse some or all the browser cookies, but if the Users disable or refuse cookies, some parts of our Website may not be accessible or function properly.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Disclosure of Users’ Information for Third-Party Advertising.</span>
                      {' '}
                      Users can opt-out by (i) checking the relevant form when we collect the data; (ii) logging into the Website or Platform and adjusting their preferences in their account profile by checking or unchecking the relevant boxes, or (iii) emailing us their opt-out request at
                      {' '}
                      <a href="mailto:optout@intentx.com">optout@intentx.com</a>
                      {' '}
                      .  Users receiving promotional email can opt-out by sending a return email requesting to be omitted from future promotional email distributions. This opt-out will not apply to information provided by intentX for product purchases, warranty registration, or other transactions.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Disclosure of User’s Information for Targeted Advertising.</span>
                      {' '}
                      Users can opt-out by (i) checking the relevant form when we collect the data, (ii) logging into the Website or Platform and adjusting their preferences in their account profile by checking or unchecking the relevant boxes, provided Users’ browser accepts cookies, or (iii) emailing us their opt-out request at
                      {' '}
                      <a href="mailto:optout@intentx.com">optout@intentx.com</a>
                      .
                    </p>
                  </li>
                </ol>
              </p>
              <br />
            </li>
            <li>
              <h3>
                GOOGLE ADSENSE AND GOOGLE ANALYTICS
              </h3>
              <p>
                <br />
                Google, as a third-party vendor, uses Cookies to serve advertisements to Users on our Website and Platform. Google uses first-party Cookies, such as Google Analytics Cookies, to compile data regarding User interactions with ad impressions and other ad service functions as they relate to our Platform. We currently use Google Analytics to collect and process certain Website usage data. To learn more about Google Analytics and how to opt-out, please visit
                {' '}
                <a target="_blank" href="https://policies.google.com/privacy/google-partners">https://policies.google.com/privacy/google-partners</a>
                .
              </p>
              <br />
              <p>
                We have implemented advertising features on our Website and Platform including: (a) remarketing with Google AdSense; (b) Google Display Network Impression Reporting; (c) Google Demographics and Interests Reporting; and (d) Google’s DoubleClick platform integration.
              </p>
              <br />
              <p>
                We use these Cookies to compile data regarding User interactions with ad impressions and other ad service functions as they relate to our Website.
              </p>
              <br />
            </li>
            <li>
              <h3>
                YOUR CALIFORNIA PRIVACY RIGHTS
              </h3>
              <p>
                <br />
                intentX does not sell, trade, or otherwise transfer to outside third parties your “Personal Information” as the term is defined under the California Civil Code Section § 1798.82(h).  Additionally, California Civil Code Section § 1798.83 permits Users of our Website or Platform that are California residents to request certain information regarding our disclosure of their Personal Information to third parties for their direct marketing purposes. To make a request for such disclosure, or identification and/or deletion of Personal Information in all our systems that we store on you, please send an email to
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                {' '}
                or write us at intentX Inc., 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024.
              </p>
              <br />
              <p>
                Note that (i) if we delete your Personal Information as requested, we will no longer be able to provide our services to you and (ii) we may need to keep such Personal Information for a while during the shutting down and billing process. If you would like to discuss our Personal Information storage and processing process with us, please send us an email at
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                {' '}
                or write us at intentX, 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024.
              </p>
              <br />
            </li>
            <li>
              <h3>
                CALIFORNIA CONSUMER PRIVACY ACT
              </h3>
              <p>
                <br />
                This Section supplements the information contained in our Privacy Policy above and applies solely to all visitors, users, and others to our Website or Platform, who reside in the State of California (“
                <span className="underlined">consumers</span>
                ” or “
                <span className="underlined">you</span>
                ”). We adopt this Section to comply with the California Consumer Privacy Act of 2018 (“
                <span className="underlined">CCPA</span>
                ”) and any terms defined in the CCPA have the same meaning when used in this Section.
                <br />
                <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }} className="eleven-list">
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Right to Request Personal Information.</span>
                      {' '}
                      Upon request, we will provide you with (i) a list of all Personal Information that we have collected on you, (ii) from whom we obtained such Personal Information, (iii) the reason why we collected such Personal Information, and (iv) with whom (if any) we have shared such Personal Information. If we sell your Personal Information or disclose your Personal Information to third parties, upon request, we will provide you with (i) a list of the Personal Information that we have collected on you, (ii) a list of the Personal Information that we sell or disclose to others on you, and (iii) to whom we have sold or disclosed your Personal Information. A consumer can make such a request only twice in a 12-month period.
                    </p>
                  </li>
                </ol>
                <br />
              </p>
              <br />
              <p>
                We require such Personal Information to be able to provide to you our Services.
              </p>
              <br />
              <p>
                Unless otherwise specified, we only collect Personal Information from you. We do not use others to provide us with your Personal Information.
                <br />
                <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }} className="eleven-list">
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Disclosure of Personal Information.</span>
                      {' '}
                      We only share your Personal Information with service providers, e.g., billing and collection agents, who enable us to provide our Services to you. We do not sell or give your Personal Information to third parties for purposes unrelated to our provision of Services to you.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Right to have Personal Information Deleted.</span>
                      {' '}
                      Upon request, we will delete all of your Personal Information that we have collected on you and will direct our Service Providers to also delete all of your Personal Information. But note that if we do delete all of this Personal Information, you will no longer be able to use our Services.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Non-Discrimination Right.</span>
                      {' '}
                      We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
                      <ol className="twelve-c-list">
                        <br />
                        <li>
                          Deny you goods or services.
                        </li>
                        <li>
                          Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
                        </li>
                        <li>
                          Provide you a different level or quality of goods or services.
                        </li>
                        <li>
                          Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.

                        </li>
                      </ol>
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Financial Incentives.</span>
                      {' '}
                      However, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information’s value and contain written terms that describe the program’s material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Contact Information.</span>
                      {' '}
                      You may contact us (i) at
                      {' '}
                      <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                      {' '}
                      or (ii) by writing to us at Privacy Officer, at 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024 to (i) make a Personal Information Request, (ii) lodge a complaint about our use or storage of your Personal Information, (iii) ask us to delete such Personal Information, and/or (iv) discuss our Privacy Policy and/or anything that has to do with it. We will respond within forty-five (45) days of receiving such request or query. Additionally, in order for us to respond to your request or query, we will need to collect information from the requesting party to verify their identity.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Under 16.</span>
                      {' '}
                      We will not sell your Personal Information if you are under the age of 16 unless we have the consent of your parent or your guardian nor will we sell it if you ask us not to do so.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Opt Out Right.</span>
                      {' '}
                      Upon your request, we will stop selling your Personal Information (sometimes called your Opt Out Right). You may send the request to Opt Out (i) to
                      {' '}
                      <a href="mailto:optout@intentx.com">optout@intentx.com</a>
                      {' '}
                      or (ii) by writing to us at Privacy Officer, 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024.
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Personal Information that We Store.</span>
                      {' '}
                      For your information, we store/collect the following Personal Information on you: name, address, online contact information, phone number, persistent identifiers, user photograph or profile picture, and other personal information described in this Privacy Policy.
                    </p>
                  </li>
                </ol>

              </p>

              <br />
            </li>
            <li>
              <h3>
                COPPA COMPLIANCE (FOR CHILDREN UNDER 13 USERS ONLY)
              </h3>
              <p>
                <br />
                The Children’s Online Privacy Protection Act (“COPPA”) is a federal legislation that applies to entities that collect and store “Personal Information,” as the term is defined under COPPA, from children under the age of 13. We are committed to ensure compliance with COPPA. Our Website and Platform are not meant for use by children under the age of 13. Our Website and Platform do not target children under the age of 13, but we do not age-screen or otherwise prevent the collection, use, and personal disclosure of persons identified as under 13. If you would like to know more about our practices and specifically our practices in relation to COPPA compliance, please email us at
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                .
              </p>
              <br />
              <p>
                IF YOU ARE UNDER 13, PLEASE DO NOT ACCESS OR USE OUR WEBSITE OR PLATFORM.
              </p>
              <br />
            </li>
            <li>
              <h3>
                CAN-SPAM ACT OF 2003
              </h3>
              <p>
                <br />
                The CAN-SPAM Act establishes requirements for commercial messages, gives recipients the right to have businesses stop emailing them, and spells out penalties for violations.  Per the CAN-SPAM Act, we will:
                <br />
                <ol className="four-main-list">
                  <br />
                  <li>
                    not use false or misleading subjects or email addresses;
                  </li>
                  <li>
                    identify the email message as an advertisement in some reasonable way;
                  </li>
                  <li>
                    include the physical address of intentX Inc., which 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024;
                  </li>
                  <li>
                    monitor third-party email marketing services for compliance, if one is used;
                  </li>
                  <li>
                    honor opt-out/unsubscribe requests quickly; and
                  </li>
                  <li>give an “opt-out” or “unsubscribe” option.  </li>
                </ol>
                <br />
                If you wish to opt out of email marketing, follow the instructions at the bottom of each email or contact us at
                {' '}
                <a href="mailto:optout@intentx.com">optout@intentx.com</a>
                {' '}
                and we will promptly remove you from all future marketing correspondences.
              </p>
              <br />
            </li>
            <li>
              <h3>
                MODIFICATIONS TO OUR PRIVACY POLICY
              </h3>
              <p>
                <br />
                intentX reserves the right, at its sole discretion, to change or modify this Privacy Policy at any time. In the event we modify this Privacy Policy, such modifications shall be binding on you only upon your acceptance of the modified Privacy Policy. We will inform you about the modifications on our Privacy Policy page on our Website or Platform by posting a modified version of the Privacy Policy page, or by a comparable means within a reasonable time period.  Your continued use of our Website or Platform shall constitute your consent to such changes.
              </p>
              <br />
            </li>
            <li>
              <h3>
                LIST OF THIRD-PARTY SERVICE PROVIDERS
              </h3>
              <p>
                <br />
                intentX uses the following third-party service providers for the provision of services as detailed under the
                {' '}
                <a target="_blank" href="/website-terms-of-use">Website Terms of Use</a>
                {' '}
                or
                {' '}
                <a target="_blank" href="terms-of-service">Terms of Service</a>
                , as applicable
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>

                  <table border="1" className="privacy-table">
                    <thead>
                      <tr>
                        <th>
                          Name of Third-Party Service Provider
                        </th>
                        <th>
                          Contact Information
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          PayPal
                        </td>
                        <td>
                          <p>
                            Website:
                            {' '}
                            <a target="_blank" href="https://www.paypal.com/us/smarthelp/contact-us">https://www.paypal.com/us/smarthelp/contact-us</a>
                          </p>
                          <p>
                            Address: 2211 North First Street San Jose, CA 95131
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Stripe, Inc.
                        </td>
                        <td>
                          <p>
                            Email:
                            {' '}
                            <a href="mailto:info@stripe.com">info@stripe.com</a>
                          </p>
                          <p>
                            Address: 510 Townsend St, San Francisco, CA 94103
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Amazon Web Services Inc.
                          {' '}
                        </td>
                        <td>
                          <p>
                            Website:
                            {' '}
                            <a target="_blank" href="https://aws.amazon.com/premiumsupport/knowledge-center/aws-phone-support/">https://aws.amazon.com/premiumsupport/knowledge-center/aws-phone-support/</a>
                          </p>
                          <p>
                            Address: 410 Terry Avenue North, Seattle, WA 98109-5210
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                Additionally, if you have any questions or concerns about our third-party service providers, please email us at
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                .
              </p>
              <br />
            </li>
            <li>
              <h3>
                COPYRIGHT INFRINGEMENT/DMCA NOTICE
              </h3>
              <p>
                <br />
                If you believe that any content on our Website or Platform violates your copyright, and you wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to the Digital Millennium Copyright Act of 1998 (“
                <span className="underlined">DMCA Takedown Notice</span>
                ”)) must be provided to our designated Copyright Agent.
                <ol className="four-main-list">
                  <li>
                    Your physical or electronic signature;
                  </li>
                  <li>
                    Identification of the copyrighted work(s) that you claim to have been infringed;
                  </li>
                  <li>
                    Identification of the material on our Website or Platform that you claim is infringing and that you request us to remove;
                  </li>
                  <li>
                    Sufficient information to permit us to locate such material;
                  </li>
                  <li>
                    Your address, telephone number, and email address;
                  </li>
                  <li>
                    A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and
                  </li>
                  <li>
                    A statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.
                  </li>
                </ol>
                <br />
                intentX’s Copyright Agent to receive DMCA Takedown Notices is intentX Privacy Team, at
                {' '}
                <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                {' '}
                and at intentX Inc., Attn: DMCA Notice, 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024. You acknowledge that for us to be authorized to take down any content, your DMCA Takedown Notice must comply with all the requirements of this Section. Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by intentX in connection with the written notification and allegation of copyright infringement.
              </p>
              <br />
            </li>
            <li>
              <h3>
                CONTACT US
              </h3>
              <p>
                <br />
                To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:
                <ul>
                  <br />
                  <li>Privacy Team</li>
                  <li>
                    Email:
                    <a href="mailto:PRIVACY@INTENTX.COM">privacy@intentx.com</a>
                  </li>
                  <li>
                    Address: intentX Inc., 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024
                  </li>
                </ul>
                <br />
                PLEASE NOTE: IF YOU USE OUR WEBSITE OR PLATFORM, YOU HAVE AGREED TO AND ACCEPTED THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY AND THE TERMS AND CONDITIONS SET FORTH IN OUR
                {' '}
                <a target="_blank" href="/website-terms-of-use">Website Terms of Use</a>
                {' '}
                OR OUR
                {' '}
                <a target="_blank" href="terms-of-service">TERMS OF SERVICE</a>
                , AS APPLICABLE.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY OR OUR
                {' '}
                <a target="_blank" href="terms-of-service">TERMS OF SERVICE</a>
                , PLEASE DO NOT USE OUR WEBSITE OR PLATFORM.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}
PrivacyPolicy.propTypes = {
  user: PropTypes.object
};
