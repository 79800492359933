/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './header.css';
import PropTypes from 'prop-types';
// import { NavLink } from 'react-router-dom';
import logo from '../../images/logo_2x.png';
// import whiteLogo from '../../images/white-logo_2x.png';
// import cookie from '../../utils/cookie';
import { ThemeContext } from '../../utils/theme';
// import { logout } from '../../utils/apiEndPoint';
import { CHROME_EXTENSION_LINK } from '../../utils/constants';

export default class PromotionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageScrolled: false,
      // dropwDownOpen: false,
      // showToast: false,
    };
    this.scrolling = this.scrolling.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolling);
  }

  /*   componentDidUpdate(props) {
      console.log(props);
    } */

  scrolling() {
    const offset = window.pageYOffset;
    if (offset > 350) {
      this.setState({ pageScrolled: true });
    } else {
      this.setState({ pageScrolled: false });
    }
  }

  render() {
    const theme = this.context;
    // const { user } = this.props;
    const { pageScrolled } = this.state;
    return (
      <div id="promotionalheader" className={pageScrolled ? `scrolling ${theme.name}-scrolling` : `${theme.name}-header`}>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-PY8JSH67MK" />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-PY8JSH67MK');
            gtag('config', 'AW-387232000');
            `}
          </script>
        </Helmet>
        <div className="container">
          <div className="promotionalheader-wrapper">
            {pageScrolled
              ? (
                <h1>
                  <a href="/"><img src={logo} alt="cashX" id="promotionalheader-logo" /></a>
                  <span>{`is giving away an ${this.props.txt}. Join now!`}</span>
                </h1>
              )
              // ? <a href="/"><img src={whiteLogo} alt="cashX" id="header-logo" /></a>
              : (
                <h1>
                  <a href="/"><img src={logo} alt="cashX" id="promotionalheader-logo" /></a>
                  <span>Auto apply coupons and get cash back every time you shop.</span>
                </h1>
              )}
            <div id="menu1">

              <div className="sign-up-btn">
                {pageScrolled
                  ? (
                    <button
                      type="button"
                      id="join-free"
                      onClick={() => {
                        window.open(CHROME_EXTENSION_LINK, '_blank');
                      }}
                    >
                      Add to Chrome - it’s free
                    </button>
                  ) : ''}
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
PromotionHeader.propTypes = {
  txt: PropTypes.string,
};
// PromotionHeader.propTypes = {
//   user: {
//     name: PropTypes.string,
//   },
//   history: PropTypes.object,
// };
PromotionHeader.contextType = ThemeContext;
