/* eslint-disable max-len */
/* eslint-disable no-throw-literal */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import Signup from '../Signup';
import GoogleLogo from '../../images/google-logo-signup.png';
import PayPalLogo from '../../images/paypal.png';
import FBLogo from '../../images/fb-logo-signup.png';
import Loading from '../../images/google.gif';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import { FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL, API_BASE_URL } from '../../utils/constants';
import cookie from '../../utils/cookie';
import ActivityIndicator from '../../View/ActivityIndicator';
import fgh from '../../utils/fetchGetWithHeaders';
import getParms from '../../utils/getParms';
import { getProfile, dashboardInfo, savePayPalUser } from '../../utils/apiEndPoint';
import fetchPost from '../../utils/fetchPost';

const clientId = 'AYgW6-9WI5Dn45Hf44Yr_1r7tCsPomP4wSB-xQ9Hyt-SD0Dmod1zy6c32kVhCP5g8LDjatFVwWxeXz_p';
const OAUTH2_REDIRECT_URIP = 'https://getcashx.com/register';
const paypalurl = `${API_BASE_URL}/cx/v1/paypal/paypal-login?redirect_uri=${OAUTH2_REDIRECT_URIP}&clientId=${clientId}`;

// const clientId = 'AYgW6-9WI5Dn45Hf44Yr_1r7tCsPomP4wSB-xQ9Hyt-SD0Dmod1zy6c32kVhCP5g8LDjatFVwWxeXz_p';
// const paypalurl = `https://www.paypal.com/connect?flowEntry=static&client_id=${clientId}&scope=openid%20email&redirect_uri=${encodeURIComponent('https://getcashx.com/register')}&response_type=code&fullPage=true&authend=live`;
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      fbLoading: false,
      googleLoading: false,
      backendError: '',
      paypalLoading: false,
    };
    this.getProfile = this.getProfile.bind(this);
    // this.getParms = this.getParms.bind(this);
  }

  async componentDidMount() {
    const param = this.props.location.search;
    const { search } = this.props.location;
    if (param) {
      let token = '';
      let data = {};
      if (param.indexOf('token') === -1 && param.indexOf('code') !== -1) {
        const searchArray = search.split('=')[1];
        const code = searchArray.split('&')[0];
        data = {
          firstName: '',
          lastName: '',
          phone: '',
          password: '',
          email: '',
          userProviderType: 'paypal',
          providerUid: '',
          providerToken: code,
          picUrl: '',
          typeOfSignup: 'social',
        };
        fetchPost(savePayPalUser + code, null)
          .then((res) => {
            if (res.value !== undefined && res.value !== null) {
              data.email = res.value;
              token = res.token;
              data.userId = res.userId;
              cookie.setCookie({ userToken: res.token });
              cookie.setCookie({ userInfo: JSON.stringify(data) });
            } else {
              throw new Error('Something went wrong');
            }
          })
          .then(() => this.getProfile(token, data))
          .catch(() => {
            this.setState({ backendError: 'Something went wrong', loading: false });
          });
      } else {
        token = param.split('token=')[1];
        data = {
          firstName: '',
          lastName: '',
          phone: '',
          password: '',
          email: '',
          userProviderType: 'google',
          providerUid: '',
          providerToken: token,
          picUrl: '',
          typeOfSignup: 'social',
        };
        await this.getProfile(token, data);
      }
    } else {
      const info = cookie.getCookie('userInfo');
      if (info) {
        this.props.history.push('/');
      }
      this.setState({ loading: false });
    }
  }

  async getProfile(token, data) {
    return fgh(getProfile, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }).then((res) => {
      const name = res.name.split(' ');
      data.firstName = name[0];
      data.lastName = name[name.length - 1];
      data.email = res.email;
      cookie.setCookie({ userToken: token });
    })
      .then(() => {
        const userToken = cookie.getCookie('userToken');
        return fgh(dashboardInfo, {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        });
      })
      .then((res) => {
        data.currentMonthCashBack = parseFloat(res.currentMonthCashBack).toFixed(2);
        data.cashback = parseFloat(res.cashback).toFixed(2);
        data.paypalVerified = res.paypalVerified;
        data.day = res.responseTimeDto.day;
        data.month = res.responseTimeDto.month;
        data.paypalEmail = res.paypalEmail;
        data.amazonEnabled = res.amazonEnabled;
        data.year = res.responseTimeDto.year;
        data.clientId = res.clientId || 'intentx';
        cookie.setCookie({ userInfo: JSON.stringify(data) });
        this.setState({ loading: false });
        this.props.onLogin(data);
      })
      .then(() => {
        getParms();
      })
      .catch(() => {
        this.setState({ backendError: 'Something went wrong', loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="login-main">
          <Helmet>
            <title>Register | cashX</title>
            <meta name="description" content="Register with cashX to start saving." />
          </Helmet>
          <Header user={this.props.user} {...this.props} />
          <div className="login-containerl">
            <ActivityIndicator />
          </div>
          <Footer />
        </div>
      );
    }
    const theme = this.context;
    const {
      fbLoading, googleLoading, backendError, paypalLoading,
    } = this.state;
    return (
      <div className="login-main">
        <Helmet>
          <title>Register | cashX</title>
          <meta name="description" content="Register with cashX to start saving." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="login-containerl">
          <p id="welcome">
            Welcome
          </p>
          <p
            id="login-msg"
            style={{ color: theme.tertiary, marginTop: 7 }}
          >
            Join cash
            <sup>x</sup>
            {' '}
            and start earning cash back while you shop
          </p>
          <div className="social-button-container">
            {googleLoading ? (
              <div
                className="social-button"
                style={{ justifyContent: 'center' }}
              >
                <img
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = GOOGLE_AUTH_URL;
                }}
                className="social-button"
              >
                <img src={GoogleLogo} alt="Google" />
                <span>Join with Google</span>
              </div>
            )}
            {fbLoading ? (
              <div
                className="social-button"
                style={{ justifyContent: 'center' }}
              >
                <img
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = FACEBOOK_AUTH_URL;
                }}
                className="social-button"
              >
                <img src={FBLogo} alt="Facebook Logo" />
                <span>Join with Facebook</span>
              </div>
            )}
            {paypalLoading ? (
              <div
                className="social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = paypalurl;
                }}
                className="social-button"
              >
                <img src={PayPalLogo} alt="PayPal" />
                <span>Join with PayPal</span>
              </div>
            )}
            {backendError ? (
              <p style={{ color: 'red', margin: 0, textAlign: 'center' }}>
                {backendError}
              </p>
            ) : null}
            <div className="or-div">
              <span
                className="or"
                style={
                  backendError
                    ? { top: 210, color: theme.tertiary }
                    : { color: theme.tertiary }
                }
              >
                OR
              </span>
            </div>
          </div>

          <Signup
            onLogin={(val) => {
              this.props.onLogin(val);
            }}
            login={() => { this.props.history.push('/login'); }}
          />

        </div>
        <Footer />
      </div>
    );
  }
}
Register.contextType = ThemeContext;
Register.propTypes = {
  user: {
    name: PropTypes.string,
  },
};
