import React from 'react';
import './index.css';
import adidas from '../../../images/promotion/storeLogos/adidas.png';
import americaneagle from '../../../images/promotion/storeLogos/americaneagle.png';
import bestbuy from '../../../images/promotion/storeLogos/bestbuy.png';
import chicco from '../../../images/promotion/storeLogos/chicco.png';
import esteeLauder from '../../../images/promotion/storeLogos/estee_lauder.png';
import farfetch from '../../../images/promotion/storeLogos/farfetch.png';
import gamestop from '../../../images/promotion/storeLogos/gamestop.png';
import homedepot from '../../../images/promotion/storeLogos/homedepot.png';
import hurley from '../../../images/promotion/storeLogos/hurley.png';
import macys from '../../../images/promotion/storeLogos/macys.png';
import nike from '../../../images/promotion/storeLogos/nike.png';
import nordstrom from '../../../images/promotion/storeLogos/nordstrom.png';
import pandora from '../../../images/promotion/storeLogos/pandora.png';
import sephora from '../../../images/promotion/storeLogos/sephora.png';
import target from '../../../images/promotion/storeLogos/target.png';
import walmart from '../../../images/promotion/storeLogos/walmart.png';

export default function FavoriteStore() {
  return (
    <div className="outer-wrapper2 ">
      <header className="hw-header">
        <h1>Your Favorite Stores</h1>
        <span>Find codes and cash back automatically at the retailers you love.</span>
      </header>
      <div className="favstore container2">
        <div className="favstore-wrapper-banner left">
          <ul>
            <li><img src={adidas} alt="addidas" /></li>
            <li><img src={americaneagle} alt="addidas" /></li>
            <li><img src={bestbuy} alt="bestbuy" /></li>
            <li><img src={chicco} alt="chicco" /></li>
            <li><img src={esteeLauder} alt="esteeLauder" /></li>
            <li><img src={farfetch} alt="farfetch" /></li>
            <li><img src={gamestop} alt="gamestop" /></li>
            <li><img src={homedepot} alt="homedepot" /></li>
            <li><img src={hurley} alt="hurley" /></li>
            <li><img src={macys} alt="macys" /></li>
            <li><img src={nike} alt="nike" /></li>
            <li><img src={nordstrom} alt="nordstrom" /></li>
            <li><img src={pandora} alt="pandora" /></li>
            <li><img src={sephora} alt="sephora" /></li>
            <li><img src={target} alt="target" /></li>
            <li><img src={walmart} alt="walmart" /></li>
          </ul>
          {/* <p className="seeall"><a href="all">See all stores</a></p> */}
        </div>
      </div>
    </div>

  );
}
