/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PayPal from '../../images/paypal.png';
import CashxTick from '../../images/cashx/tick.png';
import DailyMailTick from '../../images/dailymail/tick.png';
import './index.css';
import Profile from '../../View/Profile';
import { ThemeContext } from '../../utils/theme';
import cookie from '../../utils/cookie';
import fgh from '../../utils/fetchGetWithHeaders';
import { dashboardInfo } from '../../utils/apiEndPoint';

export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
      name: '',
      email: '',
      paypal: '',
    };
  }

  componentDidMount() {
    const userToken = cookie.getCookie('userToken');
    if (userToken === null) {
      this.props.history.push('/login');
      this.props.onLogout();
    }
    if (this.props.location.state && this.props.location.state.edited) {
      this.setState({ edited: true });
      this.props.history.push({
        pathname: '/account',
        state: { edited: false },
      });
      setTimeout(() => { this.setState({ edited: false }); }, 2000);
    }
    const userInfo = JSON.parse(cookie.getCookie('userInfo'));
    fgh(dashboardInfo, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
      .then((res) => {
        userInfo.currentMonthCashBack = parseFloat(res.currentMonthCashBack).toFixed(2);
        userInfo.cashback = parseFloat(res.cashback).toFixed(2);
        userInfo.paypalVerified = res.paypalVerified;
        userInfo.day = res.responseTimeDto.day;
        userInfo.month = res.responseTimeDto.month;
        userInfo.year = res.responseTimeDto.year;
        userInfo.paypalEmail = res.paypalEmail;
        userInfo.amazonEnabled = res.amazonEnabled;
        userInfo.clientId = res.clientId || 'intentx';
        cookie.setCookie({ userInfo: JSON.stringify(userInfo) });
        this.props.infoUpdate(userInfo);
      })
      .catch((e) => console.log(e));
    this.setState({
      name: `${this.props.user.firstName} ${this.props.user.lastName}`,
      email: this.props.user.email,
    });
  }

  render() {
    const {
      edited, name, email, paypal,
    } = this.state;
    const theme = this.context;
    let Tick = CashxTick;
    if (theme.name === 'dailymail') Tick = DailyMailTick;
    return (
      <Profile {...this.props} title="Your Account | cashX" description="Your account summary in cashX">
        <div className="account">
          <p className="heading-1" style={{ color: 'black' }}>
            Hey
            {' '}
            {this.props.user.firstName}
          </p>
          <p className="heading-2">Here is the info we have, update it if you want it.</p>
          <div className="account-details">
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
              <table className="details">
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    Name:
                  </td>
                  <td>
                    {name}
                  </td>
                </tr>
                <tr>
                  <td className="row-name" style={{ color: theme.primary }}>
                    Email:
                  </td>
                  <td>
                    {email}
                  </td>
                </tr>
                {
                  (paypal !== '') ? (
                    <tr>
                      <td className="row-name" style={{ paddingTop: 7, paddingLeft: 7 }}>
                        <img src={PayPal} alt="PayPal" style={{ height: 30 }} />
                      </td>
                      <td style={{ color: '#0f2169', textAlign: 'center' }}>
                        <p>{paypal}</p>
                      </td>
                      <td>
                        <img src={Tick} alt="Synced" style={{ height: 20, marginLeft: 10 }} />
                      </td>
                    </tr>
                  ) : null
                }
              </table>
              <button type="button" id="change-password" onClick={() => this.props.history.push('/change-password')}>Change Password</button>
            </div>
            <button type="button" id="edit" onClick={() => this.props.history.push('/edit-details')} className={`${theme.name}-edit`}>Edit</button>
          </div>
          {edited
            ? (
              <div className="modal-backdrop">
                <div id="info-saved">
                  <h3 style={{ textAlign: 'center' }}><img src={Tick} alt="Synced" style={{ height: 40 }} /></h3>
                  <p id="desc">
                    Your information has been updated
                  </p>
                </div>
              </div>
            ) : null}
        </div>
      </Profile>
    );
  }
}
Account.contextType = ThemeContext;
