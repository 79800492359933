import React from 'react';
import './index.css';
import PropTypes from 'prop-types';
import linestepsGraphic from '../../../images/promotion/linesteps_graphic.png';

import dailymail from '../../../images/promotion/dailymail_logo.png';
import accuweather from '../../../images/promotion/accuweather_logo.png';
// import usaToday from '../../../images/promotion/usa_today_logo.png';
// import {
//   CHROME_EXTENSION_LINK,
// } from '../../../utils/constants';
// import landingpageredirect from '../../../utils/landingpageredirect';
import landingpagecookie from '../../../utils/landingpagecookie';

export default function PromotionBanner(props) {
  const { txt, theme, bimg } = props;
  return (
    <div className="home-wrapper-gen container">
      <div className="text-wrapper-banner-gen">
        <p className="heading-gen">
          We’re giving away an
          {' '}
          {txt}
          .
        </p>
        <p className="twoinone-gen">No purchase necessary. Here’s how to enter:</p>
        <div className="text-descr-gen">
          <span className="steps"><img src={linestepsGraphic} alt="linesteps_graphic" width="41" height="207" /></span>
          <ul>
            <li>
              <b>
                Add cash
                <sup>x</sup>
                {' '}
                to your desktop Chrome browser.
              </b>
            </li>
            <li>
              <b>
                Join cash
                <sup>x</sup>
                {' '}
                - it’s free.
              </b>
              {/* <p>
                Create a cash
                <sup>x</sup>
                {' '}
                account, so that we can pay you cash back while you shop.
              </p> */}
            </li>
            <li>
              <b> You could be the winner!</b>
              <p>
                You’re automatically entered into the sweepstake and
                we’ll notify you if you’re the winner.
              </p>
            </li>

          </ul>

          {/* <p>With one click, cashX offers cash back whether coupon works or not.</p> */}
        </div>
        <div className="must-link-gen">
          {' '}
          <p>

            Must be 18+ to enter. Subject to
            {' '}
            <a style={{ color: '#464646' }} href="/sweepstake-rules-airpods" target="_blank">Official Rules.</a>
          </p>

        </div>
        <div className="add-btn-wrapper1-gen">
          <button
            className="add-chrome-btn1-gen"
            style={{ backgroundColor: theme.primary }}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              landingpagecookie('https://cashx-api.intentx.com/cx/v1/user/download', 'eventdwn');
            }}
          >
            Add to Chrome -- it’s free

          </button>

        </div>
        <div className="clientlogo-gen">
          <span><img src={dailymail} alt="dailymail logo" /></span>
          <span><img src={accuweather} alt="accuweather logo" /></span>
          {/* <span><img src={usaToday} alt="usaToday logo" /></span> */}
        </div>
      </div>
      <div className="image-wrapper-banner-gen">
        <img src={bimg} alt="cashX Savings" />
      </div>

    </div>

  );
}
PromotionBanner.propTypes = {
  txt: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  bimg: PropTypes.string.isRequired,
};
