/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-this-in-sfc */
import React, { useContext } from 'react';
import './index.css';
import { ThemeContext } from '../../utils/theme';

export default function ShowEmail(props) {
  const theme = useContext(ThemeContext);
  return (
    <div className="modal-backdrop">
      <div id="forgot-pwd">
        <p id="close" onClick={() => props.checkEmail(false)}>&#9587;</p>
        <h3 style={{ textAlign: 'center', color: theme.primary }}>Check your Email</h3>
        <p id="desc">
          A new password has been sent to your email address.
        </p>
        <button type="submit" className="reset-btn" style={{ maxWidth: 200 }} onClick={() => props.checkEmail(false)}>Close this Window</button>
      </div>
    </div>
  );
}
