/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { ThemeContext } from '../../utils/theme';
import PinkLoader from '../../images/cashx/loader-cashx.gif';
import BlueLoader from '../../images/dailymail/loader-dailymail.gif';

class ActivityIndicator extends Component {
  render() {
    const theme = this.context;
    let loader = null;
    if (theme.name === 'cashx') {
      loader = PinkLoader;
    } else if (theme.name === 'dailymail') {
      loader = BlueLoader;
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: 350 }}>
        <img
          src={loader}
          alt="Loading..."
          style={{ width: 75, height: 75, alignSelf: 'center' }}
        />
      </div>
    );
  }
}
ActivityIndicator.contextType = ThemeContext;
export default ActivityIndicator;
