/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import { Base64 } from 'js-base64';

function getP(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}
export default async function landingpagecookie(href, browserwidtth) {
  const valOfCXDown = getCookie('CX_DWN');
  let valOfUserId = getCookie('CX_userId');
  let marketing_source = getCookie('MARKETING_SOURCE');

  if (!marketing_source) {
    marketing_source = getP('marketing_source');
    document.cookie = marketing_source && `MARKETING_SOURCE=${marketing_source};;path=/`;
  }
  if (!valOfUserId) {
    valOfUserId = getP('userId');
    document.cookie = valOfUserId && `CX_userId=${valOfUserId}`;
  }
  let cookie;
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  };
  if (!valOfCXDown) {
    cookie = makeid(20);
  } else {
    cookie = valOfCXDown;
  }
  document.cookie = `CX_DWN=${cookie};;path=/`;
  const clientId = getP('clientId') || 'intentx';
  document.cookie = `CX_CLIENT=${clientId};;path=/`;
  const utm_source = getP('source');
  const utm_medium = getP('medium');
  const utm_campaign = getP('campaign');
  const reminder = getP('reminder');
  const clickId = getP('clickId');

  let attach = '';

  if (valOfUserId) {
    attach = `&user_id=${valOfUserId}`;
  }
  if (utm_source) {
    document.cookie = `UTM_SOURCE=${utm_source};;path=/`;
    attach += `&utm_source=${utm_source}`;
  }
  if (utm_medium) {
    document.cookie = `UTM_MEDIUM=${utm_medium};;path=/`;
    attach += `&utm_medium=${utm_medium}`;
  }
  if (utm_campaign) {
    document.cookie = `UTM_CAMPAIGN=${utm_campaign};;path=/`;
    attach += `&utm_campaign=${utm_campaign}`;
  }
  if (reminder) {
    document.cookie = `reminder=${reminder};;path=/`;
    attach += `&reminder=${reminder}`;
  }
  if (marketing_source) {
    attach += `&marketingSource=${marketing_source}`;
  }
  if (clickId) {
    document.cookie = `clickId=${clickId};;path=/`;
    attach += `&clickId=${clickId}`;
  }
  if (browserwidtth === 'eventdwn') {
    console.log('    coming :>> ');
    return fetch(`${href}?clientId=${clientId}&dwnc=${cookie}${attach}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.userID || result.extensionLink || result.clientId) {
          document.cookie = `CX_userId=${result.userID};;path=/`;
          document.cookie = `CX_CLIENT=${result.clientId};;path=/`;
          window.location.href = result.extensionLink;
        }
      })
      .catch((error) => console.log('error', error));
  }
  if (browserwidtth === 'playstore') {
    window.location.href = `https://cashx.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.cashx%26referrer%3Dutm_campaign%253D${Base64.encode(utm_campaign)}%2526utm_source%253D${Base64.encode(utm_source)}%2526utm_medium%253D${Base64.encode(utm_medium)}%2526clientId%253D${Base64.encode(clientId)}%2526user_id%253D${Base64.encode(valOfUserId)}%2526marketingSource%253D${Base64.encode(marketing_source)}&apn=com.cashx&afl=https://play.google.com/store/apps/details?id%3Dcom.cashx%26referrer%3Dutm_campaign%253D${Base64.encode(utm_campaign)}%2526utm_source%253D${Base64.encode(utm_source)}%2526utm_medium%253D${Base64.encode(utm_medium)}%2526clientId%253D${Base64.encode(clientId)}%2526user_id%253D${Base64.encode(valOfUserId)}%2526marketingSource%253D${Base64.encode(marketing_source)}`;
    return fetch(`${href}?clientId=${clientId}&dwnc=${cookie}${attach}`, requestOptions)
      .then(() => {
        console.log('working :>> ');
      })
      .catch((error) => console.log('error', error));
  }
  if (browserwidtth === 'clickid') {
    return fetch(`${href}`, requestOptions)
      .then(() => {
        console.log('working :>> ');
      })
      .catch((error) => console.log('error', error));
  }
  if (browserwidtth === 'appstore') {
    //window.location.href = `https://cashx.page.link/?link=https://click.google-analytics.com/redirect?tid%3DUA-196007894-1%26url%3Dhttps://itunes.apple.com/us/app/cashX/1573865611%26aid%3Dcom.cashx%26idfa%3D%2525%257Bidfa%257D%26cs%3D${Base64.encode(utm_source)}%26cm%3D${Base64.encode(utm_medium)}%26cn%3D${Base64.encode(utm_campaign)}%26clientId%3D${Base64.encode(clientId)}%26userId%3D${Base64.encode(valOfUserId)}%26marketingSource%3D${Base64.encode(marketing_source)}&isi=1573865611&ibi=com.cashx`;
    return fetch(`${href}?clientId=${clientId}&dwnc=${cookie}${attach}`, requestOptions)
      .then(() => {
        window.location.href = `https://cashx.page.link/?link=https://click.google-analytics.com/redirect?tid%3DUA-196007894-1%26url%3Dhttps://itunes.apple.com/us/app/cashX/1573865611%26aid%3Dcom.cashx%26idfa%3D%2525%257Bidfa%257D%26cs%3D${Base64.encode(utm_source)}%26cm%3D${Base64.encode(utm_medium)}%26cn%3D${Base64.encode(utm_campaign)}%26clientId%3D${Base64.encode(clientId)}%26userId%3D${Base64.encode(valOfUserId)}%26marketingSource%3D${Base64.encode(marketing_source)}&isi=1573865611&ibi=com.cashx`;
        console.log('working :>> ');
      })
      .catch((error) => { console.log('error', error) });
  }
  return fetch(`${href}?clientId=${clientId}&dwnc=${cookie}&source=${browserwidtth}${attach}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.userID || result.clientId) {
        document.cookie = `CX_userId=${result.userID};;path=/`;
        document.cookie = `CX_CLIENT=${result.clientId};;path=/`;
      }
    })
    .catch((error) => console.log('error', error));
}
// `https://cashx.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.cashx%26referrer%3Dutm_campaign%253Dsweepstacks%2526utm_source%253Dbhoomi%2526utm_medium%253Dtest%2526clientId%253Dmeredith%2526user_id%253D1234565%2526marketingSource%253Demail&apn=com.cashx&afl=https://play.google.com/store/apps/details?id%3Dcom.cashx%26referrer%3Dutm_campaign%253Dsweepstacks%2526utm_source%253Dbhoomi%2526utm_medium%253Dtest%2526clientId%253Dmeredith%2526user_id%253D1234565%2526marketingSource%253Demail`
