/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import InputRow from '../../View/InputRow';
import './index.css';
import Loading from '../../images/google.gif';
// import Email from '../../images/email.png';
import { emailValidator, passwordValidator } from '../../utils/regex';
// import { userSignUp, userLogin } from '../../utils/apiEndPoint';
import { userSignUp } from '../../utils/apiEndPoint';
import fetchPost from '../../utils/fetchPost';
// import cookie from '../../utils/cookie';
import { ThemeContext } from '../../utils/theme';
import { cookieGet } from '../../utils/constants';
import dailymail from '../../images/promotion/dailymail_logo.png';
import accuweather from '../../images/promotion/accuweather_logo.png';
import getParms from '../../utils/getParms';

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      backendError: '',
      submitLoading: false,
    };
    this.signup = this.signup.bind(this);
  }

  signup() {
    this.setState({ submitLoading: true, backendError: '' });
    const { email, password } = this.state;
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    if (emailError || passwordError) {
      this.setState({
        email: { ...this.state.email, error: emailError },
        password: { ...this.state.password, error: passwordError },
        submitLoading: false,
      });
      return;
    }
    this.setState({
      email: { ...this.state.email, error: '' },
      password: { ...this.state.password, error: '' },
    });
    const monthNames = ['Jan', 'Feb', 'Mar', 'Ap', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    const day = new Date().getDate();
    const month = monthNames[new Date().getMonth()];
    const year = new Date().getFullYear();
    const cxDownload = cookieGet('CX_DWN');
    const loginData = {
      firstName: '',
      lastName: '',
      phone: '',
      email: email.value,
      password: password.value,
      userProviderType: 'cashX',
      providerUid: '',
      providerToken: '',
      picUrl: '',
      typeOfSignup: 'cashxSweepStake',
      paypalVerified: false,
      cashback: '0.00',
      day,
      month,
      year,
      cx_down: cxDownload,
    };

    fetchPost(userSignUp, loginData)
      .then((res) => {
        if (res.status !== 'success') {
          const error = res.data && res.data.modalMessage ? res.data.modalMessage : res.message;
          this.setState({ backendError: error, submitLoading: false });
          throw error;
        }
        if (res.status === 'success') {
          // this.props.text = res.status;
          this.props.parentCallback(res);
          this.setState({
            submitLoading: false,
          });
        }
      })
      .then(() => {
        getParms();
      })
      // .then(() => fetchPost(userLogin, { email: email.value, password: password.value }))
      // .then((res) => {
      //   console.log('res---fetchpost :>> ', res);
      //   if (res.accessToken) {
      //     cookie.setCookie({ userToken: res.accessToken });
      //     cookie.setCookie({ userInfo: JSON.stringify(loginData) });
      //     this.props.onLogin(loginData);
      //     this.setState({ submitLoading: false });
      //     return res.accessToken;
      //   }
      //   this.setState({ backendError: res.message, submitLoading: false });
      //   throw res.message;
      // })
      .catch((e) => {
        if (typeof e === 'string') {
          this.setState({
            backendError: e,
            submitLoading: false,
          });
        } else {
          this.setState({
            backendError: 'Something went wrong',
            submitLoading: false,
          });
        }
      });
  }

  render() {
    const {
      email,
      password,
      backendError,
      submitLoading,
    } = this.state;
    let error = backendError || '';
    let split = [];
    if (error.indexOf('Sign In') !== -1) {
      split = error.split('.');
      error = '';
      for (let i = 0; i < split.length; i++) {
        const texts = split[i] ? split[i].toLowerCase() : '';
        if (texts.indexOf('sign in') === -1 && texts !== '') {
          error += `${split[i]}.`;
        }
      }
    }
    const theme = this.context;
    return (
      <div className="txtsignup">
        <div className="">
          <InputRow
            value={email.value}
            onChange={(e) => {
              this.setState({ email: { ...this.state.email, value: e } });
            }}
            type="email"
            placeholder="Enter Email"
            error={email.error}
          />
          <InputRow
            value={password.value}
            onChange={(e) => {
              this.setState({ password: { ...this.state.password, value: e } });
            }}
            type="password"
            error={password.error}
            placeholder="Enter Password"
          />
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {error ? (
              <>
                <p style={{
                  color: 'red', textAlign: 'center', margin: 0, fontSize: 12,
                }}
                >
                  {error}
                </p>
                {split.length ? (
                  <p style={{
                    color: 'red', margin: 4, textAlign: 'center', fontSize: 12,
                  }}
                  >
                    {' '}
                    Please
                    {' '}
                    check
                    {/* <span
                      className="cashx-signin-link"
                      style={{ color: theme.primary, cursor: 'pointer' }}
                      onClick={this.props.login}
                    >
                      Sign in
                    </span> */}
                  </p>
                ) : null}
              </>
            ) : null}
          </div>

          {submitLoading ? (
            <div
              className="SweepStake-signin-button"
              style={{
                justifyContent: 'center',
                backgroundColor: theme.primary,
                color: 'white',
                paddingTop: 7,
                paddingBottom: 7,
              }}
            >
              <img
                src={Loading}
                alt="Loading..."
                style={{ marginRight: 0, height: 30 }}
              />
            </div>
          ) : (

            <div
              className="SweepStake-signin-button"
              onClick={this.signup}
              style={{ backgroundColor: theme.primary, color: 'white' }}
            >
              Enter to Win
            </div>
          )}

          <div className="SweepStake-clientlogo-gen">
            <span><img src={dailymail} alt="dailymail logo" /></span>
            <span><img src={accuweather} alt="accuweather logo" /></span>
            {/* <span><img src={usaToday} alt="usaToday logo" /></span> */}
          </div>
          <div className="SweepStake-condition">
            <p>
              Must be 18+ to enter. Subject to
              {' '}
              <a style={{ color: '#464646' }} href="/sweepstake-rules-airpods" target="_blank">Official Rules.</a>
            </p>
            <p>
              {' '}
              cash
              <sup>x</sup>
              {' '}
              extension only works on desktop Chrome browser.
            </p>
          </div>

        </div>
      </div>
    );
  }
}
Signup.contextType = ThemeContext;
