import React from 'react';
import './index.css';
import install from '../../../images/promotion/howItWorks_1.png';
import activate from '../../../images/promotion/howItWorks_2.png';

export default function HowBox() {
  return (
    <div className="outer-wrapper1 ">
      <header className="hw-header">
        How cash
        <sup>x</sup>
        {' '}
        Works:
      </header>
      <div className="howitworks container1">
        <div className="howitworks-wrapper-banner left">
          <div className="hw-lst">
            <p className="num">1</p>
            <p className="hw-img"><img src={install} alt="howItWorks" /></p>
          </div>
          <div className="text-hw">
            <p>
              Install cash
              <sup>x</sup>
              {' '}
              extension on your desktop Chrome browser.
            </p>
          </div>

        </div>
        <div className="howitworks-wrapper-banner right">
          <div className="hw-lst">
            <p className="num">2</p>
            <p className="hw-img"><img src={activate} alt="howItWorks" /></p>
          </div>
          <div className="text-hw">
            <p>
              Click “Activate” at check out, and let cash
              <sup>x</sup>
              {' '}
              do the rest of the work for you.
            </p>
          </div>
        </div>

      </div>
    </div>

  );
}
