/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import './index.css';
import { CHROME_EXTENSION_LINK } from '../../utils/constants';

export default function FeedbackSubmitted(props) {
  return (
    <div>
      <Helmet>
        <title>Feedback Submitted | cashX</title>
        <meta name="description" content="Successful submission of feedback." />
      </Helmet>
      <Header user={props.user} {...props} />
      <div style={{ marginTop: 100, minHeight: '64vh' }}>
        <div style={{ width: 360, margin: 'auto', marginTop: 200 }}>
          <p style={{
            textAlign: 'center', fontWeight: 'bold', fontSize: 20, marginBottom: 5,
          }}
          >
            Thanks...
          </p>
          <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>
            We Hope To See You Soon!
          </p>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '60px',
          }}
          >
            <button
              type="button"
              className="yellowButton"
              onClick={() => {
                props.history.push('/');
              }}
            >
              Go to cash
              <sup>x</sup>
            </button>
            <button
              type="button"
              className="pinkButton"
              onClick={() => {
                window.open(CHROME_EXTENSION_LINK);
              }}
            >
              Download cash
              <sup>x</sup>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
FeedbackSubmitted.propTypes = {
  user: PropTypes.any,
  history: PropTypes.any,
};
