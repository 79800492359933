import { API_BASE_URL } from './constants';

export default async function fgh(url, headers) {
  const res = await fetch(`${API_BASE_URL}${url}`, {
    method: 'GET',
    headers,
  });
  const jsonRes = await res.json();
  return jsonRes;
}
