/* eslint-disable no-sequences */
/* eslint-disable prefer-destructuring */
/* eslint-disable semi */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import './index.css';
import aes from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import InputRow from '../../View/InputRow';
import { ThemeContext } from '../../utils/theme';
import Loading from '../../images/google.gif';
import { emailValidator } from '../../utils/regex';
import cookie from '../../utils/cookie';
import { userLogin, getProfile, dashboardInfo } from '../../utils/apiEndPoint';
import fetchPost from '../../utils/fetchPost';
import fgh from '../../utils/fetchGetWithHeaders';
import getParms from '../../utils/getParms';

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      submitLoading: false,
      backendError: '',
      checked: true,
    };
    this.signin = this.signin.bind(this);
  }

  componentDidMount() {
    const data = this.getCreds('loginData');
    if (data) {
      const jsonData = JSON.parse(data);
      this.setState({
        email: { ...this.state.email, value: jsonData.email },
        password: { ...this.state.password, value: jsonData.password },
      });
    }
  }

  setCreds(pair) {
    const key = Object.keys(pair)[0];
    const val = Object.values(pair)[0];
    const encryptedKey = aes.encrypt(key, 'cashx345').toString();
    const encryptedVal = aes.encrypt(val, 'cashx345').toString();
    localStorage.setItem(key, encryptedKey);
    localStorage.setItem(encryptedKey, encryptedVal);
  }

  getCreds(name) {
    const result = localStorage.getItem(name);
    if (result) {
      const encryptedVal = localStorage.getItem(result);
      const decryptedVal = aes.decrypt(encryptedVal, 'cashx345').toString(Utf8);
      return decryptedVal;
    }
    return null;
  }

  removeCreds(name) {
    const encryptedKey = localStorage.getItem(name);
    localStorage.removeItem(name);
    localStorage.removeItem(encryptedKey);
  }

  signin() {
    this.setState({ submitLoading: true, backendError: '' });
    const { email, password } = this.state;
    const emailError = emailValidator(email.value);
    let passwordError = '';
    if (!password.value) {
      passwordError = 'Password cannot be empty.';
    }
    if (emailError || passwordError) {
      this.setState({
        email: { ...this.state.email, error: emailError },
        password: { ...this.state.password, error: passwordError },
        submitLoading: false,
      });
      return;
    }
    const loginData = {
      email: email.value,
      password: password.value,
    };
    const requestData = {
      firstName: '',
      lastName: '',
      phone: '',
      password: password.value,
      email: email.value,
      userProviderType: 'cashX',
      providerUid: '',
      providerToken: '',
      picUrl: '',
      checked: false,
    };
    fetchPost(userLogin, loginData)
      .then((res) => {
        if (res.accessToken) {
          cookie.setCookie({ userToken: res.accessToken });
          if (this.state.checked) {
            this.setCreds({ loginData: JSON.stringify(loginData) });
          } else {
            this.removeCreds('loginData');
          }
        } else {
          this.setState({ backendError: res.message, submitLoading: false });
          throw res.message;
        }
        this.setState({ submitLoading: false });
      })
      .then(() => {
        const userToken = cookie.getCookie('userToken');
        return fgh(getProfile, {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        });
      })
      .then((res) => {
        const name = res.name.split(' ');
        requestData.firstName = name[0]
        requestData.lastName = name[name.length - 1];
        requestData.providerUid = res.providerId
      })
      .then(() => {
        const userToken = cookie.getCookie('userToken');
        return fgh(dashboardInfo, {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        });
      })
      .then((res) => {
        requestData.currentMonthCashBack = parseFloat(res.currentMonthCashBack).toFixed(2);
        requestData.cashback = parseFloat(res.cashback).toFixed(2);
        requestData.paypalVerified = res.paypalVerified
        requestData.day = res.responseTimeDto.day
        requestData.month = res.responseTimeDto.month
        requestData.paypalEmail = res.paypalEmail;
        requestData.amazonEnabled = res.amazonEnabled;
        requestData.year = res.responseTimeDto.year
        requestData.clientId = res.clientId || 'intentx';
        cookie.setCookie({ userInfo: JSON.stringify(requestData) });
        this.props.onLogin(requestData);
      })
      .then(() => {
        getParms();
      })
      .catch((e) => {
        if (typeof e === 'string') {
          this.setState({
            backendError: e,
            submitLoading: false,
          });
        } else {
          this.setState({
            backendError: 'Something went wrong',
            submitLoading: false,
          });
        }
      });
  }

  render() {
    const theme = this.context;
    const {
      email,
      password,
      submitLoading,
      backendError,
      checked,
    } = this.state;
    return (
      <div className="">
        <div className="">
          <InputRow
            value={email.value}
            onChange={(e) => {
              this.setState({ email: { ...this.state.email, value: e } });
            }}
            type="email"
            placeholder="Enter Email"
            error={email.error}
          />
          <InputRow
            value={password.value}
            onChange={(e) => {
              this.setState({ password: { ...this.state.password, value: e } });
            }}
            type="password"
            error={password.error}
            placeholder="Enter Password"
          />
          <div className="feat-wrapper" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {!checked ? (
                <span
                  onClick={() => {
                    this.setState((prevState) => ({
                      checked: !prevState.checked,
                    }));
                  }}
                  className="checkmark-before"
                />
              ) : (
                <span
                  onClick={() => {
                    this.setState((prevState) => ({
                      checked: !prevState.checked,
                    }));
                  }}
                  className="checkmark-after"
                  style={
                    checked
                      ? {
                        backgroundColor: theme.primary,
                        borderColor: theme.primary,
                        borderRadius: '50%',
                      }
                      : {
                        backgroundColor: theme.primary,
                        borderColor: theme.primary,
                      }
                  }
                >
                  <svg style={{ position: 'relative', left: -1 }} fill="#fff" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -46 417.81333 417" width="12px"><path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" /></svg>
                </span>
              )}
              <p style={{
                margin: 0, color: theme.tertiary, fontSize: 12, lineHeight: '20px',
              }}
              >
                Keep me Signed In
              </p>
            </div>
            <div>
              <p
                onClick={() => this.props.forgotPwd(true)}
                style={{
                  margin: 0,
                  color: theme.primary,
                  fontSize: 12,
                  cursor: 'pointer',
                  lineHeight: '20px',
                }}
              >
                Forgot password?
              </p>
            </div>
          </div>
          {backendError ? (
            <p style={{ color: 'red', textAlign: 'center', margin: 5 }}>
              {backendError}
            </p>
          ) : null}
          {submitLoading ? (
            <div
              className="signin-button"
              style={{
                justifyContent: 'center',
                backgroundColor: theme.primary,
                color: 'white',
                paddingTop: 7,
                paddingBottom: 7,
              }}
            >
              <img
                src={Loading}
                alt="Loading..."
                style={{ marginRight: 0, height: 30 }}
              />
            </div>
          ) : (
            <div
              className="signin-button"
              onClick={this.signin}
              style={{ backgroundColor: theme.primary, color: 'white' }}
            >
              Sign In
            </div>
          )}
        </div>
        <h4 className="sign-up-option">
          You don&apos;t have an account?
          {' '}
          <span
            className=""
            style={{ color: theme.primary, cursor: 'pointer', textDecoration: 'underline' }}
            onClick={this.props.signup}
          >
            Join Free
          </span>
        </h4>
      </div>
    );
  }
}
SignIn.contextType = ThemeContext;
