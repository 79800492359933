/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import './index.css';
import Footer from '../../View/Footer';
import Header from '../../View/Header';
import one from '../../images/about/01.png';
import two from '../../images/about/02.png';
import three from '../../images/about/03.png';
import four from '../../images/about/04.png';
import five from '../../images/about/05.png';
import { ThemeContext } from '../../utils/theme';
import { CHROME_EXTENSION_LINK } from '../../utils/constants';

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const theme = this.context;
    return (
      <div>
        <Helmet>
          <title>About | cashX</title>
          <meta name="description" content="About the cashx website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="about">
          <div className="container">
            <div className="banner-heading">
              <p>Our Mission :</p>
              <p className="primary-color" style={{ color: theme.primary }}>Save You Serious Cash</p>
            </div>
            <div>
              <img src={one} alt="cashX savings" />
            </div>
          </div>
          <div className="about-wrapper container left-text founded">
            <div className="text-wrapper">
              <p className="heading">
                cash
                <sup>x</sup>
                {' '}
                was founded with the goal of making it easier to save money.
              </p>
              <p>
                Using multiple online shopping extensions to run coupon codes and earn cash back can be exhausting. Why use two services to do the work of one? That’s why we built cash
                <sup>x</sup>
                , an online shopping extension designed to deliver maximum savings. With each purchase, we comb through coupon codes to save you money, then give you a cash back reward. No points system, no gimmicks, just more money.
              </p>
              {/* <p className="italic-text" style={{ color: theme.primary }}>Savings lost.</p> */}
            </div>
            <div className="image-wrapper">
              <img src={two} alt="cashX savings" />
            </div>
          </div>
          <div className="grey-bg">
            <div className="about-wrapper container right-text search ">
              <div className="image-wrapper">
                <img src={three} alt="cashX savings" />
              </div>
              <div className="text-wrapper">
                <p className="heading">
                  We wanted to make it easier for you to save money.
                </p>
                <p>
                  Forget the manual process of copy and pasting codes at checkout. Even better, forget about even having to search for a code to use at checkout. Our cash
                  <sup>x</sup>
                  {' '}
                  Chrome extension makes it super easy to find a coupon or deal and automatically apply it. We auto-apply the best discount to your checkout cart, and voila, you’ve captured the most savings.
                </p>
                {/* <p className="italic-text" style={{ color: theme.primary }}>Again, savings lost.</p> */}
              </div>
            </div>
          </div>
          <div className="gray-bg">
            {/* <div className="money container">
              <p className="heading">
                We wanted to make it easier
                <br />
                {' '}
                to save money.
              </p>
              <p className="text-wrapper">Forget all of this manual process of copy and pasting codes at checkout. Even better, forget about having to search for a code to use at checkout. Our cashX Chrome extension makes it super easy to find a coupon or deal and automatically apply it.</p>
              <p className="italic-text small" style={{ color: theme.primary }}>Savings found!</p>
            </div> */}
            <div className="about-wrapper container right-text copy-text">
              <div className="text-wrapper">
                <p className="heading">
                  We want you to save money and get cash back each time you check out.
                </p>
                <p>That’s why we offer a percentage of cash back at a wide range of retail sites. Every time you check out at a participating retailer, we give you earned cash back. Each month, we calculate your total earnings and pay it back to you.</p>
                {/* <p className="italic-text small">The best savings found!</p> */}
              </div>
              <div className="image-wrapper">
                <img src={four} alt="cashX savings" />
              </div>

            </div>
            {/* <div className="save money">
              <p className="heading">
                Then we thought,
                <br />
                ‘why not let people save money
                {' '}
                <br />
                every time they checkout?’
              </p>
              <p className="text-wrapper">That’s when cash back came into play. We offer a certain percentage of cash back at participating sites, and every time you check out at one of those sites, we calculate your earned cash back. Then we cumulate all those earnings each month and pay it back to you.</p>
            </div> */}
          </div>
          <div className="about-chrome container">
            <p className="heading pink" style={{ color: theme.primary }}>At this point, let’s just say cha-ching!</p>

            <div>
              <img src={five} alt="cashX savings" />
            </div>
            <div className="add-btn-wrapper">
              <button className="add-chrome-btn" type="button" onClick={() => { window.open(CHROME_EXTENSION_LINK, '_blank'); }} style={{ backgroundColor: theme.primary }}>Add to Chrome — it’s free</button>
              <span className="backdrop-btn" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
About.propTypes = {
  user: {
    firstName: PropTypes.string,
  },
};
About.contextType = ThemeContext;
