/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import './index.css';
import InputRow from '../../View/InputRow';
import { emailValidator } from '../../utils/regex';
import Loading from '../../images/google.gif';
import { ThemeContext } from '../../utils/theme';
import { forgotPassword } from '../../utils/apiEndPoint';
import { API_BASE_URL } from '../../utils/constants';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        error: '',
      },
      backendErr: '',
      submitLoading: false,
    };
    this.handlePress = this.handlePress.bind(this);
  }

  handlePress() {
    const { email } = this.state;
    this.setState({ submitLoading: true });
    const emailError = emailValidator(email.value);
    if (emailError) {
      this.setState({ email: { ...this.state.email, error: emailError }, submitLoading: false });
      return;
    }
    this.setState({ email: { ...this.state.email, error: '' } });
    fetch(`${API_BASE_URL}${forgotPassword}?email=${email.value}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          this.props.showEmail(true);
          this.setState({ submitLoading: false });
        } else {
          this.setState({ submitLoading: false, backendErr: res.message });
        }
      })
      .catch(() => {
        this.setState({ submitLoading: false, backendErr: 'Something went wrong' });
      });
  }

  render() {
    // const theme = this.context;
    const { email, submitLoading, backendErr } = this.state;
    return (
      <div className="modal-backdrop">
        <div id="forgot-pwd">
          <p id="close" onClick={() => this.props.forgotPwd(false)}>&#9587;</p>
          <h3 style={{ textAlign: 'center' }}>Forgot Your Password? We can help.</h3>
          <p id="desc">
            Enter the email address associated with your cash
            <sup>x</sup>
            {' '}
            account. We&apos;ll email you a link with a new password
          </p>
          <InputRow
            className="reset-input"
            value={email.value}
            onChange={(e) => {
              this.setState({ email: { ...this.state.email, value: e } });
            }}
            type="email"
            placeholder="Email"
            error={email.error}
          />
          {backendErr ? <p className="error">{backendErr}</p> : null}
          <button
            className={submitLoading ? 'reset-btn load' : 'reset-btn'}
            onClick={this.handlePress}
            type="button"
          >
            {submitLoading ? <img style={{ height: '27px' }} src={Loading} alt="Loading" />
              : 'Reset Password'}
          </button>
        </div>
      </div>
    );
  }
}
ForgotPassword.contextType = ThemeContext;
