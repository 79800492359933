import { API_BASE_URL } from './constants';

export default async function fetchPost(
  url,
  body,
  headers = {
    'Content-Type': 'application/json',
  },
) {
  const res = await fetch(API_BASE_URL + url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  });
  const jsonRes = await res.json();
  return jsonRes;
}
