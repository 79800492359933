import aes from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const cookie = {
  setCookie: (pair) => {
    const key = Object.keys(pair)[0];
    const val = Object.values(pair)[0];
    const encryptedKey = aes.encrypt(key, 'cashx345').toString();
    const encryptedVal = aes.encrypt(val, 'cashx345').toString();
    localStorage.setItem(key, encryptedKey);
    localStorage.setItem(encryptedKey, encryptedVal);
  },
  getCookie: (name) => {
    const result = localStorage.getItem(name);
    if (result) {
      const encryptedVal = localStorage.getItem(result);
      const decryptedVal = aes.decrypt(encryptedVal, 'cashx345').toString(Utf8);
      return decryptedVal;
    }
    return null;
  },
  removeAllCookie: () => {
    const encryptedLogin = localStorage.getItem('loginData');
    if (encryptedLogin) {
      const encryptedVal = localStorage.getItem(encryptedLogin);
      localStorage.clear();
      localStorage.setItem('loginData', encryptedLogin);
      localStorage.setItem(encryptedLogin, encryptedVal);
    } else {
      localStorage.clear();
    }
  },
  removeCookie: (name) => {
    const result = localStorage.getItem(name);
    if (result) {
      localStorage.removeItem(result);
      localStorage.removeItem(name);
    } else {
      localStorage.removeItem(name);
    }
  },
  getCookieurl: (name) => {
    const result = document.cookie.match(
      new RegExp(`${name}=([^;]+)`),
    );
    if (result) {
      return result[1];
    }
    return null;
  },
};
export default cookie;
