/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import './index.css';
import SideRail from '../SideRail';

export default function Profile(props) {
  return (
    <div>
      <Helmet>
        <title>{props.title ? props.title : 'cashX'}</title>
        <meta name="description" content={props.description ? props.description : 'cashX website helps you save more'} />
      </Helmet>
      <Header user={props.user} {...props} />
      <div className="profile-main">
        <SideRail {...props} />
        {props.children}
      </div>
      <Footer />
    </div>

  );
}
Profile.propTypes = {
  user: {
    name: PropTypes.string,
  },
};
