/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import appstore from '../../images/howtouseit-mobweb/app-store-badges/appstore.png';
import googleplaystore from '../../images/howtouseit-mobweb/app-store-badges/google-play-badge.png';
import '../FAQ/index';
import { ThemeContext } from '../../utils/theme';

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  componentDidMount() {
    const questions = [
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque iaculis efficitur cursus. Fusce aliquet, quam at posuere rhoncus, dui tellus elementum lacus, vitae fermentum dui massa a libero. Nullam condimentum rhoncus metus, sit amet fermentum lorem tempus quis. Mauris ac facilisis odio, id tempor lacus. Vestibulum et orci nibh. Nam at erat faucibus, iaculis ante ac, rhoncus sem. Vestibulum eleifend gravida eros quis cursus. Proin diam augue, dictum eu magna id, scelerisque lacinia nisl. Curabitur nunc dolor, aliquam at nunc ut, blandit viverra quam. Ut egestas, nibh eu facilisis gravida, urna purus scelerisque turpis, ut sodales nisi massa in sapien.',
        open: false,
      },
    ];
    this.setState({ questions });
  }

  toggleAccordion(index) {
    const { questions } = this.state;
    const sectionsBody = [];
    questions.forEach((que, i) => {
      if (i === index) {
        que.open = !que.open;
      } else {
        que.open = false;
      }
      sectionsBody.push(que);
    });
    this.setState({ questions: sectionsBody });
  }

  // eslint-disable-next-line class-methods-use-this
  returnQue(val) {
    let returnValue = null;
    switch (val) {
      case 0:
        returnValue = (
          <span className="acc-heading text mobfqh">
            What is cash
            <sup>x</sup>
            {' '}
            app ?
          </span>
        );
        break;
      case 1:
        returnValue = (
          <span className="acc-heading text mobfqh">
            How does cash
            <sup>x</sup>
            {' '}
            app work?
          </span>
        );
        break;
      case 2:
        returnValue = (
          <span className="acc-heading text mobfqh">
            How do I install cash
            <sup>x</sup>
            {' '}
            in my phone?
          </span>
        );
        break;
      case 3:
        returnValue = (
          <span className="acc-heading text mobfqh">
            What are the current qualifying retailers for cash
            <sup>x</sup>
            ?
          </span>
        );
        break;
      case 4:
        returnValue = (
          <span className="acc-heading text mobfqh">

            How do i get my cashback?
          </span>
        );
        break;
      case 5:
        returnValue = (
          <span className="acc-heading text mobfqh">
            What are the cash back payment methods?
          </span>
        );
        break;
      default:
        returnValue = (
          <span className="acc-heading text mobfqh">
            When do I receive my earned cash back?
          </span>
        );
    }
    return returnValue;
  }

  returnAns(val) {
    let returnValue = null;
    switch (val) {
      case 0:
        returnValue = (
          <p className="acc-sub-part">
            cash
            <sup>x</sup>
            {' '}
            is a shopping app that helps you save and earn money when you make purchases on qualifying retailer websites.
          </p>
        );
        break;
      case 1:
        returnValue = (
          <>
            <p className="acc-sub-part heading">
              cash
              <sup>x</sup>
              {' '}
              saves you money at checkout
            </p>
            <p className="acc-sub-part">
              The cash
              <sup>x</sup>
              {' '}
              app works by finding great coupons and deals on retailer sites, then automatically applying discounts and savings to your cart before your purchase is completed.
              {' '}
            </p>
            <p className="acc-sub-part heading">
              Earn cash back when you use cash
              <sup>x</sup>
              app
            </p>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              app also allows you to earn cash back on purchases completed on qualifying retailer websites.
            </p>
          </>
        );
        break;
      case 2:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              app is available for android® and iOS®. Simply visit the App Store or Google Play on your phone, or use these buttons to download cash
              {' '}
              <sup>x</sup>
              {' '}
              app now.
            </p>
            <div className="badgesforapp1">
              <a
                href="www.crome.com"
              >
                <img src={appstore} alt="cashX point1" />
              </a>
            </div>
            <div className="badgesforapp1">
              <a href="https://play.google.com/store/apps/details?id=com.cashx">
                <img src={googleplaystore} alt="cashX point1" />
              </a>
            </div>

          </>
        );
        break;
      case 3:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              works with a wide selection of online retail partners. If your selected retailer participates in our program, the cash
              <sup>x</sup>
              {' '}
              app will automatically pop up for you to activate cash back during shopping and check out.
            </p>
          </>
        );
        break;
      case 4:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              will deposit your earned cash back to your chosen payment method each month.
              {' '}
              <br />
              {' '}
              <br />
              Payment options include your PayPal account or an Amazon gift card.
            </p>
          </>
        );
        break;
      case 5:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              pays out your cash back earnings into your PayPal account or an Amazon gift card at the beginning of each month.
            </p>
          </>
        );
        break;
      default:
        returnValue = (
          <p className="acc-sub-part">
            cash
            <sup>x</sup>
            {' '}
            pays out your cash back earnings into your PayPal account or an Amazon gift card at the beginning of each month.
          </p>
        );
    }
    return returnValue;
  }

  render() {
    const { questions } = this.state;
    const theme = this.context;
    const questionsView = [];
    questions.forEach((element, i) => {
      questionsView.push(
        <div
          className={`accordion ${theme.name}-divm ${element.open ? 'open' : ''}`}
          key={i}
        >
          <div
            className="accordionSectionHeader"
            onClick={() => this.toggleAccordion(i)}
            role="button"
            onKeyPress={() => { }}
          >
            {/* <span className="acc-heading icon">{element.open ? '-' : '+'}</span> */}
            {this.returnQue(i)}
          </div>
          <div
            className="accordion-form"
            onClick={() => this.toggleAccordion(i)}
            onKeyPress={() => { }}
          >
            {this.returnAns(i)}
          </div>
        </div>,
      );
    });
    return (
      <div>
        <Helmet>
          <title>Frequently asked questions | cashX</title>
          <meta name="description" content="Frequently asked questions for cashX website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="main-faq container">
          <p className="faq-heading">
            Got questions?
            <br />
            {' '}
            We have answers.
          </p>
          {questionsView}
          {/* <div style={{ marginTop: 40 }}>
            <p id="got-more">Got more questions?</p>
            <p className="mail-info">Please send us an email at</p>
            <p
              className="mail-info"
            >
              <span
                role="button"
                onKeyPress={() => {}}
                onClick={() => {
                  window.location.href = 'mailto:support@cashx.com';
                }}
                style={{ cursor: 'pointer', color: theme.primary }}
              >
                support@cashx.com
              </span>
            </p>
          </div> */}
        </div>
        <Footer />
      </div>
    );
  }
}
FAQ.propTypes = {
  user: PropTypes.object,
};
FAQ.contextType = ThemeContext;
