/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-throw-literal */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */

import React, { Component } from 'react';
import Signup from '../SweepstakeSignUp';
import GoogleLogo from '../../images/google-logo-signup.png';
import PayPalLogo from '../../images/paypal.png';
import FBLogo from '../../images/fb-logo-signup.png';
import Loading from '../../images/google.gif';
import fgh from '../../utils/fetchGetWithHeaders';
import { getProfile } from '../../utils/apiEndPoint';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import ShowEmail from '../ShowEmail';
// import fetchPost from '../../utils/fetchPost';
import getParms from '../../utils/getParms';

import {
  API_BASE_URL,
} from '../../utils/constants';

import cookie from '../../utils/cookie';

// const OAUTH2_REDIRECT_URIP = 'http://localhost:3000/promotions/sweepstake-airpod.html';
let OAUTH2_REDIRECT_URIP;
if (window.screen.availWidth <= 767) {
  OAUTH2_REDIRECT_URIP = 'http://getcashx.com/promotions/sweepstake-airpod.html';
} else {
  OAUTH2_REDIRECT_URIP = 'http://getcashx.com/promotions/thankyou';
}
const promotion = '&promotion=sweepstakesociallogin';

function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fbLoading: false,
      googleLoading: false,
      backendError: '',
      showEmail: false,
      paypalLoading: false,
    };
    this.getProfile = this.getProfile.bind(this);
  }

  async componentDidMount() {
    // console.log('this.props.cxcookie :>> ', this.props.cxcookie);
    const param = this.props.location.search;
    const { search } = this.props.location;
    if (param) {
      let token = '';
      let data = {};
      if (param.indexOf('token') === -1 && param.indexOf('code') !== -1) {
        const searchArray = search.split('=')[1];
        const code = searchArray.split('&')[0];
        data = {
          firstName: '',
          lastName: '',
          phone: '',
          password: '',
          email: '',
          userProviderType: 'paypal',
          providerUid: '',
          providerToken: code,
          picUrl: '',
          typeOfSignup: 'cashxSweepStake',
        };
        // fetchPost(savePayPalUser + code, null)
        //   .then((res) => {
        //     if (res.value !== undefined && res.value !== null) {
        //       data.email = res.value;
        //       token = res.token;
        //       data.userId = res.userId;
        //       cookie.setCookie({ userToken: res.token });
        //       cookie.setCookie({ userInfo: JSON.stringify(data) });
        //     } else {
        //       throw new Error('Something went wrong');
        //     }
        //   })
        //   .then(() => this.getProfile(token, data))
        //   .catch(() => {
        //     this.setState({ backendError: 'Something went wrong' });
        //   });
        if (code) {
          await this.getProfile(code, data);
        }
      } else {
        token = param.split('token=')[1];
        // console.log('token :>> ', token);
        data = {
          firstName: '',
          lastName: '',
          phone: '',
          password: '',
          email: '',
          userProviderType: 'google',
          providerUid: '',
          providerToken: token,
          picUrl: '',
          // typeOfSignup: 'social',
          typeOfSignup: 'cashxSweepStake',
        };
        if (token) {
          await this.getProfile(token, data);
        }
      }
    } else {
      const info = cookie.getCookie('userInfo');
      if (info) {
        if (window.screen.availWidth <= 767) {
          this.props.history.push('/promotions/sweepstake-airpod.html');
        } else {
          this.props.history.push('/promotions/thankyou');
        }
      }
    }
  }

  async getProfile(token, data) {
    console.log('fgetprofilecoming');
    return fgh(getProfile, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }).then((res) => {
      console.log('res :>> ', res);
      const name = res.name.split(' ');
      data.firstName = name[0];
      data.lastName = name[name.length - 1];
      data.email = res.email;
      if (res.email !== '') {
        res.status = 'success';
        this.props.parentCallback(res);
      }
      cookie.setCookie({ userToken: token });
    })
      .then(() => {
        getParms();
      })
      .catch(() => {
        this.setState({ backendError: 'Something went wrong ' });
      });
  }

  render() {
    const cxDownload = getCookie('CX_DWN');
    const valOfClient = getCookie('CX_CLIENT');
    const theme = this.context;
    const {
      fbLoading, googleLoading, backendError, showEmail, paypalLoading,
    } = this.state;

    return (
      <div className="sweepstake-login-main">
        <div className="sweepstake-login-container">
          <p id="sweepstake-welcome">
            We’re giving away AirPods Pro.
          </p>
          <p
            id="sweepstake-login-msg"

          >
            No purchase necessary. Enter for free
          </p>
          <div className="sweepstake-social-button-container">
            {googleLoading ? (
              <div
                className="sweepstake-social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="sweepstake-loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = `${API_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URIP}?cx_down=${cxDownload}${promotion}`;
                }}
                className="sweepstake-social-button"
              >
                <img src={GoogleLogo} alt="Google" />
                <span>Enter with Google</span>
              </div>
            )}
            {fbLoading ? (
              <div
                className="sweepstake-social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="sweepstake-loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_uri=${OAUTH2_REDIRECT_URIP}?cx_down=${cxDownload}`;
                }}
                className="sweepstake-social-button"
              >
                <img src={FBLogo} alt="Facebook Logo" />
                <span>Enter with Facebook</span>
              </div>
            )}
            {paypalLoading ? (
              <div
                className="sweepstake-social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="sweepstake-loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = `${API_BASE_URL}/cx/v1/paypal/paypal-login?redirect_uri=${OAUTH2_REDIRECT_URIP}&clientId=${valOfClient}&cx_down=${cxDownload}`;
                }}
                className="sweepstake-social-button"
              >
                <img src={PayPalLogo} alt="PayPal" />
                <span>Enter with PayPal</span>
              </div>
            )}
            {backendError ? (
              <p style={{ color: 'red', margin: 0, textAlign: 'center' }}>
                {backendError}
              </p>
            ) : null}
            <div className="sweepstake-or-div">
              <span
                className="sweepstake-or"
                style={
                  backendError
                    ? { top: 210, color: theme.tertiary }
                    : { color: theme.tertiary }
                }
              >
                OR
              </span>
            </div>
          </div>

          <Signup parentCallback={this.props.parentCallback} />

        </div>

        {showEmail
          ? <ShowEmail checkEmail={(val) => this.setState({ showEmail: val })} />
          : null}
        {/* <Footer /> */}
      </div>
    );
  }
}
Login.contextType = ThemeContext;
