/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import './index.css';

export default function InputRow(props) {
  return (
    <div className="cashx-input-container">
      <input
        className="cashx-input-row"
        type={props.type}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        value={props.value}
        style={
          props.error
            ? {
              borderColor: 'red',
              borderWidth: 1,
              borderStyle: 'solid',
              marginBottom: 0,
            }
            : { marginBottom: 10 }
        }
      />
      {props.error ? (
        <p
          style={{
            color: 'red',
            marginTop: 3,
            marginBottom: 3,
            marginLeft: 6,
            marginRight: 6,
            fontSize: 9,
          }}
        >
          {props.error}
        </p>
      ) : null}
    </div>
  );
}
