/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import { ThemeContext } from '../../utils/theme';
import InputRow from '../../View/InputRow';
import Loading from '../../images/google.gif';
import { emailValidator, passwordValidator } from '../../utils/regex';

export default class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      backendError: '',
    };
    this.verify = this.verify.bind(this);
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const searchParam = decodeURIComponent(this.props.location.search.split('?id=')[1]);
      this.setState({ email: { ...this.state.email, value: searchParam } });
    }
  }

  verify() {
    this.setState({ submitLoading: true, backendError: '' });
    const { email, password } = this.state;
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    if (emailError || passwordError) {
      this.setState({
        email: { ...this.state.email, error: emailError },
        password: { ...this.state.password, error: passwordError },
        submitLoading: false,
      });
      return;
    }
    this.setState({ submitLoading: false });
  }

  render() {
    const theme = this.context;
    const {
      email,
      password,
      submitLoading,
      backendError,
    } = this.state;
    return (
      <div className="login-main">
        <Helmet>
          <title>Verify Email Address | cashX</title>
          <meta name="description" content="Verify your email with cashX" />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="login-container">
          <div>
            <p id="welcome">
              Welcome!
            </p>
            <p
              id="login-msg"
              style={{ color: theme.tertiary, marginTop: 7 }}
            >
              Verify your email address
            </p>
            <div style={{ marginTop: 30 }}>
              <InputRow
                value={email.value}
                onChange={(e) => {
                  this.setState({ email: { ...this.state.email, value: e } });
                }}
                type="email"
                placeholder="Enter Email"
                error={email.error}
              />
              <InputRow
                value={password.value}
                onChange={(e) => {
                  this.setState({ password: { ...this.state.password, value: e } });
                }}
                type="password"
                error={password.error}
                placeholder="Enter Password"
              />
              {backendError ? (
                <p style={{ color: 'red', textAlign: 'center', margin: 5 }}>
                  {backendError}
                </p>
              ) : null}
              {submitLoading ? (
                <div
                  className="signin-button"
                  style={{
                    justifyContent: 'center',
                    backgroundColor: theme.primary,
                    color: 'white',
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <img
                    src={Loading}
                    alt="Loading..."
                    style={{ marginRight: 0, height: 30 }}
                  />
                </div>
              ) : (
                <div
                  className="signin-button"
                  onClick={this.verify}
                  style={{ backgroundColor: theme.primary, color: 'white' }}
                >
                  Verify
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
VerifyEmail.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};
VerifyEmail.contextType = ThemeContext;
