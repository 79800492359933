export const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) {
    return 'Email cannot be empty.';
  }
  if (!re.test(email)) {
    return 'Ooops! We need a valid email address.';
  }

  return '';
};

export const passwordValidator = (password) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
  if (!password || password.length <= 0) {
    return 'Password cannot be empty.';
  }
  if (!re.test(password)) {
    return 'Password must have atleast : 1 capital and 1 small letter and 1 number. Minimum Length of the password should be 6 letters.';
  }

  return '';
};
export const cnfpasswordValidator = (cnfPwd, pwd) => {
  if (!cnfPwd || cnfPwd.length <= 0) {
    return 'Confirm password cannot be empty.';
  }
  if (cnfPwd !== pwd) {
    return 'Passwords are not same';
  }

  return '';
};
export const nameValidator = (name, fieldName) => {
  if (!name || name.length <= 0) {
    return `${fieldName} cannot be empty`;
  }
  return '';
};
