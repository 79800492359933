/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-throw-literal */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import './header.css';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import logo from '../../images/logo_2x.png';
import whiteLogo from '../../images/white-logo_2x.png';
import cookie from '../../utils/cookie';
import { ThemeContext } from '../../utils/theme';
import { logout } from '../../utils/apiEndPoint';
import { CHROME_EXTENSION_LINK, API_BASE_URL } from '../../utils/constants';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageScrolled: false,
      dropwDownOpen: false,
      hamburger: false,
      showToast: false,
    };
    this.scrolling = this.scrolling.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolling);
  }

  /*   componentDidUpdate(props) {
      console.log(props);
    } */

  scrolling() {
    const offset = window.pageYOffset;
    if (offset > 33) {
      this.setState({ pageScrolled: true });
    } else {
      this.setState({ pageScrolled: false });
    }
  }

  render() {
    const theme = this.context;
    const { user } = this.props;
    const {
      pageScrolled, dropwDownOpen, showToast, hamburger,
    } = this.state;
    return (
      <div id="header" className={pageScrolled ? `scrolling ${theme.name}-scrolling` : `${theme.name}-header`}>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-PY8JSH67MK" />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-PY8JSH67MK');
            gtag('config', 'AW-387232000');

            `}
          </script>
        </Helmet>
        <div className="container">
          <div className="header-wrapper">
            {pageScrolled
              ? <a href="/"><img src={whiteLogo} alt="cashX" id="header-logo" /></a>
              : <a href="/"><img src={logo} alt="cashX" id="header-logo" /></a>}
            <div
              // className="burger"
              className={hamburger ? 'closehm' : 'burger openhm'}
              onClick={() => {
                this.setState({ hamburger: true });
              }}
            >
              <div />
              <div />
              <div />
            </div>
            <div
              // className="burger"
              className={hamburger ? 'openhm clx' : 'closehm'}
              onClick={() => {
                this.setState({ hamburger: false });
              }}
            >
              X
            </div>
            <div id="menu" className={hamburger ? 'openhm' : 'closehm'}>

              {/* {
                pageScrolled
                  ? '' : <a href="/">Home</a>
              } */}
              <NavLink
                exact
                to="/"
                activeClassName="active-menu-item"
              >
                Home
              </NavLink>

              <NavLink
                to="/how-it-works"
                activeClassName="active-menu-item"
              >
                How It Works
              </NavLink>
              <NavLink
                to="/about"
                activeClassName="active-menu-item"
              >
                About
              </NavLink>
              <NavLink
                to="/faq"
                activeClassName="active-menu-item"
              >
                FAQ
              </NavLink>
              {user ? (
                <a
                  id="login"
                  className="user-name"
                  style={{ marginTop: 4 }}
                  onClick={() => {
                    this.setState({ dropwDownOpen: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ dropwDownOpen: false });
                  }}
                  onBlur={() => {
                    this.setState({ dropwDownOpen: false });
                  }}
                >
                  {user.firstName ? `Hi ${user.firstName}` : 'Hello'}
                  <span className={dropwDownOpen ? 'arrow' : 'arrow  open'}>&#9652;</span>
                  <div className={dropwDownOpen ? 'dropdown-wrapper' : 'dropdown-wrapper  hidden'}>
                    <div className="dropdown">
                      <NavLink to="/account" activeClassName="dropdown-active">Accounts</NavLink>
                      <NavLink to="/payout" activeClassName="dropdown-active">Payouts</NavLink>
                      <p
                        className="logout"
                        onClick={() => {
                          const userToken = cookie.getCookie('userToken');
                          if (userToken === null) {
                            cookie.removeAllCookie();
                            window.location.href = '/';
                          }
                          fetch(`${API_BASE_URL}${logout}`, {
                            method: 'GET',
                            headers: {
                              Authorization: `Bearer ${userToken}`,
                              'Content-Type': 'application/json',
                            },
                          })
                            .then((res) => res.text())
                            .then((res) => {
                              if (res === 'success') {
                                cookie.removeAllCookie();
                                sessionStorage.setItem('logout', JSON.stringify(true));
                                window.location.href = '/';
                              } else {
                                throw 'Something went wrong';
                              }
                            })
                            .catch(() => {
                              this.setState({ showToast: true });
                              setTimeout(() => this.setState({ showToast: false }), 2000);
                            });
                        }}
                      >
                        Logout
                      </p>
                    </div>
                  </div>
                </a>
              ) : (
                <NavLink
                  to="/login"
                  activeClassName="active-menu-item"
                  id="login"
                >
                  Login
                </NavLink>
              )}
              <div className="sign-up-btn">
                <button
                  type="button"
                  id="join-free"
                  onClick={() => {
                    if (user) {
                      window.open(CHROME_EXTENSION_LINK, '_blank');
                    } else {
                      this.props.history.push('/register');
                    }
                  }}
                >
                  Join Free
                </button>
                <span className="backdrop-btn" />
              </div>
            </div>
          </div>
        </div>
        {
          showToast ? (
            <div className="error-toast">
              {' '}
              <p>We were not able to log you out. Please try again in sometime</p>
            </div>
          ) : null
        }
      </div>
    );
  }
}
Header.propTypes = {
  user: {
    name: PropTypes.string,
  },
  history: PropTypes.object,
};
Header.contextType = ThemeContext;
