import React from 'react';
import './index.css';
import install from '../../../images/promotion/save.png';

export default function SaveFavorite() {
  return (
    <div className="outer-wrapper-save ">
      <div className="save-f container-save">
        <div className="wrapper-save left">
          <p className="sw-img"><img src={install} alt="save" /></p>
        </div>
        <div className="wrapper-save right">
          <div className="text-sw">
            <p>Save on all your favorite things</p>
            <span>Easily pay less for products you’re already buying online.</span>
          </div>
        </div>

      </div>
    </div>

  );
}
