/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Home from '../ViewController/Home';
import HowItWorks from '../ViewController/HowItWorks';
import HowToUseIt from '../ViewController/HowToUseIt';
import About from '../ViewController/About';
import FAQ from '../ViewController/FAQ';
import AppFAQ from '../ViewController/AppFAQ';
import Login from '../ViewController/Login';
// import SweepStakeLogin from '../ViewController/SweepStakeLogin';
import ReferralThankyou from '../ViewController/ReferralThankyou';
import ReferralGet from '../ViewController/ReferralGet';
import SweepstakeRegisterd from '../ViewController/SweepstakeRegisterd';
import Register from '../ViewController/Register';
import Feedback from '../ViewController/Feedback';
import Account from '../ViewController/Account';
import { themes, ThemeContext } from '../utils/theme';
import PrivacyPolicy from '../ViewController/PrivacyPolicy';
import TermsOfService from '../ViewController/TermsOfService';
import SweepstakeRules from '../ViewController/SweepstakeRules';
import Cashx from '../ViewController/LandingPage/Cashx';
import SweepstakeAirfyer from '../ViewController/LandingPage/SweepstakeAirfyer';
import SweepstakeAirpods from '../ViewController/LandingPage/SweepstakeAirpods';
import ThankyouSweepstake from '../ViewController/LandingPage/ThankyouSweepstake';
import cookie from '../utils/cookie';
import Payout from '../ViewController/Payout';
import EditAccount from '../ViewController/EditAccount';
import PaymentMethod from '../ViewController/PaymentMethod';
import ChangePassword from '../ViewController/ChangePassword';
/* import fgh from '../utils/fetchGetWithHeaders';
import { dashboardInfo } from '../utils/apiEndPoint'; */
import WebsiteTermsofUse from '../ViewController/WebsiteTermsofUse';
import ResetPassword from '../ViewController/ResetPassword';
import VerifyEmail from '../ViewController/VerifyEmail';
import FeedbackSubmitted from '../ViewController/FeedbackSubmitted';
import ResetPasswordSuccess from '../ViewController/ResetPasswordSuccess';
import droppingCookie from '../utils/droppingCookie';
import Loader from '../ViewController/Loader';

const authentication = {
  isAuthenticated: false,
  authenticate() {
    authentication.isAuthenticated = true;
  },
  signOut() {
    sessionStorage.clear();
    authentication.isAuthenticated = false;
  },
};
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: themes.cashx,
      user: null,
      loading: true,
    };
  }

  componentDidMount() {
    const val = JSON.parse(cookie.getCookie('userInfo')) || '';
    if (val) {
      this.setState({ user: val, loading: false });
      authentication.authenticate();
    } else {
      this.setState({ loading: false });
    }
    const { href } = window.location;
    if ((href.indexOf('source') !== -1 || href.indexOf('clientId') !== -1) && (!href.includes('/promotions/'))) {
      droppingCookie();
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div />
      );
    }
    const { user } = this.state;
    return (
      <ThemeContext.Provider value={this.state.theme}>
        <Router>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Home user={user} {...props} />
              )}
            />
            <Route
              path="/how-it-works"
              render={(props) => (
                <HowItWorks user={user} {...props} />
              )}
            />
            <Route
              path="/How-to-useit"
              render={(props) => (
                <HowToUseIt user={user} {...props} />
              )}
            />
            <Route
              path="/feedback"
              render={(props) => (
                <Feedback user={user} {...props} />
              )}
            />
            <Route
              path="/feedback-submitted"
              render={(props) => (
                <FeedbackSubmitted user={user} {...props} />
              )}
            />
            <Route
              path="/about"
              render={(props) => (
                <About user={user} {...props} />
              )}
            />
            <Route
              path="/faq"
              render={(props) => (
                <FAQ user={user} {...props} />
              )}
            />
            <Route
              path="/loader"
              render={(props) => (
                <Loader />
              )}
            />
            <Route
              path="/app-faq"
              render={(props) => (
                <AppFAQ user={user} {...props} />
              )}
            />
            <Route
              path="/login"
              render={(props) => (
                <Login
                  user={user}
                  onLogin={(val) => {
                    this.setState({ user: val });
                    authentication.authenticate();
                  }}
                  onLogout={() => {
                    this.setState({ user: null });
                    authentication.signOut();
                  }}
                  {...props}
                />
              )}
            />
            <Route
              path="/register"
              render={(props) => (
                <Register
                  user={user}
                  onLogin={(val) => {
                    authentication.authenticate();
                    this.setState({ user: val });
                    props.history.push('/edit-details');
                  }}
                  {...props}
                />
              )}
            />
            <Route
              path="/resetPassword"
              render={(props) => (
                <ResetPassword
                  user={user}
                  onLogout={() => {
                    this.setState({ user: null });
                    authentication.signOut();
                  }}
                  {...props}
                />
              )}
            />
            <Route
              path="/reset-success"
              render={(props) => (
                <ResetPasswordSuccess
                  user={user}
                  onLogout={() => {
                    this.setState({ user: null });
                    authentication.signOut();
                  }}
                  {...props}
                />
              )}
            />
            <Route
              path="/verifyEmail"
              render={(props) => (
                <VerifyEmail
                  user={user}
                  onLogout={() => {
                    this.setState({ user: null });
                    authentication.signOut();
                  }}
                  {...props}
                />
              )}
            />
            <Route
              path="/privacy-policy"
              render={(props) => (
                <PrivacyPolicy user={user} {...props} />
              )}
            />
            <Route
              path="/website-terms-of-use"
              render={(props) => (
                <WebsiteTermsofUse user={user} {...props} />
              )}
            />
            <Route
              path="/terms-of-service"
              render={(props) => (
                <TermsOfService user={user} {...props} />
              )}
            />
            <Route
              path="/sweepstake-rules-airpods"
              render={(props) => (
                <SweepstakeRules user={user} {...props} />
              )}
            />
            <Route
              path="/promotions/cashx.html"
              render={(props) => (
                <Cashx user={user} {...props} />
              )}
            />
            <Route
              path="/promotions/sweepstake-airfryer.html"
              render={(props) => (
                <SweepstakeAirfyer user={user} {...props} />
              )}
            />
            <Route
              path="/promotions/thankyou"
              render={(props) => (
                <ThankyouSweepstake user={user} {...props} />
              )}
            />
            <Route
              path="/promotions/sweepstake-airpod.html"
              render={(props) => (
                <SweepstakeAirpods
                  user={user}
                  onLogin={(val) => {
                    authentication.authenticate();
                    this.setState({ user: val });
                    props.history.push('/promotions/sweepstake-airpod.html');
                  }}
                  {...props}
                />

              )}
            />
            {/* <Route
              path="/sweepstake-login"
              render={(props) => (
                <SweepStakeLogin
                  user={user}
                  onLogin={(val) => {
                    this.setState({ user: val });
                    authentication.authenticate();
                  }}
                  onLogout={() => {
                    this.setState({ user: null });
                    authentication.signOut();
                  }}
                  {...props}
                />
              )}
            /> */}
            <PrivateRoute
              path="/payout"
            >
              <PayoutsPage
                user={user}
                infoUpdate={(val) => this.setState({ user: val })}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/account"
            >
              <AccountPage
                user={user}
                infoUpdate={(val) => this.setState({ user: val })}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/edit-details"
            >
              <EditPage
                user={user}
                infoUpdate={(val) => {
                  const newUser = this.state.user;
                  newUser.email = val.email;
                  newUser.firstName = val.firstName;
                  newUser.lastName = val.lastName;
                  this.setState({
                    user: newUser,
                  });
                  cookie.setCookie({ userInfo: JSON.stringify(newUser) });
                }}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/payment-method"
            >
              <Payment
                user={user}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/change-password"
            >
              <PasswordChange
                user={user}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/friend-referral"
            >
              <ReferalG
                user={user}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/Sweepstake-registered"
            >
              <SweepstakeRegister
                user={user}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
            <PrivateRoute
              path="/referral-thankyou"
            >
              <ReferralThanks
                user={user}
                onLogout={() => {
                  this.setState({ user: null });
                  authentication.signOut();
                }}
              />
            </PrivateRoute>
          </Switch>
        </Router>
      </ThemeContext.Provider>
    );
  }
}
function Payment(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <PaymentMethod user={props.user} history={history} location={location} onLogout={props.onLogout} />
  );
}

function EditPage(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <EditAccount user={props.user} history={history} location={location} infoUpdate={props.infoUpdate} onLogout={props.onLogout} />
  );
}

function PayoutsPage(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <Payout user={props.user} history={history} location={location} infoUpdate={props.infoUpdate} onLogout={props.onLogout} />
  );
}
function AccountPage(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <Account user={props.user} history={history} location={location} infoUpdate={props.infoUpdate} onLogout={props.onLogout} />
  );
}
function PasswordChange(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <ChangePassword user={props.user} history={history} location={location} onLogout={props.onLogout} />
  );
}
function ReferalG(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <ReferralGet user={props.user} history={history} location={location} infoUpdate={props.infoUpdate} onLogout={props.onLogout} />
  );
}
function SweepstakeRegister(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <SweepstakeRegisterd user={props.user} history={history} location={location} infoUpdate={props.infoUpdate} onLogout={props.onLogout} />
  );
}
function ReferralThanks(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <ReferralThankyou user={props.user} history={history} location={location} infoUpdate={props.infoUpdate} onLogout={props.onLogout} />
  );
}
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (authentication.isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}
