import React, { useEffect } from 'react';
import './index.css';
import PropTypes from 'prop-types';
import linestepsGraphic from '../../../images/promotion/download-app.png';
// import cookie from '../../../utils/cookie';

// import dailymail from '../../../images/promotion/dailymail_logo.png';
// import accuweather from '../../../images/promotion/accuweather_logo.png';
import playstore from '../../../images/google-play-badge.png';
import appstore from '../../../images/howtouseit-mobweb/app-store-badges/appstore.png';
import landingpagecookie from '../../../utils/landingpagecookie';
import { API_BASE_URL } from '../../../utils/constants';

function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}
const PromotionMobileBanner = (props) => {
  const {
    txt, bimg, deviceos,
  } = props;
  const [useridd, setUseridd] = React.useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        setInterval(() => {
          const seridd = getCookie('CX_userId');
          setUseridd(seridd);
        }, 3000);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [useridd]);

  function fireevent(store) {
    console.log('store :>> ', store);
    if (store === 'playstore') {
      landingpagecookie(`${API_BASE_URL}/cx/v1/user/download`, 'playstore');
    }
    if (store === 'appstore') {
      landingpagecookie(`${API_BASE_URL}/cx/v1/user/download`, 'appstore');
    }
  }

  return (
    <div className="home-wrapper-genm container">
      <div className="text-wrapper-banner-genm">
        <p className="heading-genm">
          Download your cash
          <sup>x</sup>
          {' '}
          app on
          {' '}
          {deviceos === 'Android' ? 'android' : 'iOS'}
          {' '}
          today and win an
          {' '}
          {txt}
          !.
        </p>
        <p className="twoinone-genm">No purchase necessary. Enter for free</p>
        <div className="text-descr-genm">
          <span className="stepsm"><img src={linestepsGraphic} alt="linesteps_graphic" width="41" height="174" /></span>
          <ul>
            <li>
              <b>
                Download
                {' '}
                {' '}
                <span style={{ color: '#EC168C', padding: '0px 3px', marginTop: '-4px' }}>
                  cash
                  <sup>x</sup>
                </span>
                {' '}
                now!
              </b>

              {deviceos === 'Android'

                ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      fireevent('playstore');
                    }}
                    style={{ border: '0px', background: 'transparent' }}
                  >
                    <img src={playstore} alt="playstore" width="184" height="81" />
                  </button>
                )
                : (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      fireevent('appstore');
                    }}
                    style={{ border: '0px', background: 'transparent' }}
                  >
                    <img src={appstore} alt="appstore" className="appstorebtn" width="132" height="40" />
                  </button>
                )}
            </li>
            <li>
              <b>
                Register on cash
                <sup>x</sup>
                {' '}
                — it’s free.
              </b>
            </li>
            <li>
              <b> You could be the winner!</b>
              <p>
                You’re automatically entered into the sweepstake and
                we’ll notify you if you’re the winner.
              </p>
              <div className="must-link-genm">
                {' '}
                <p>

                  Must be 18+ to enter. Subject to
                  {' '}
                  <a style={{ color: '#464646' }} href="/sweepstake-rules-airpods" target="_blank">Official Rules.</a>
                </p>

              </div>
            </li>

          </ul>

        </div>
      </div>
      <div className="image-wrapper-banner-gen">
        <img src={bimg} alt="cashX Savings" />
      </div>

    </div>

  );
};
PromotionMobileBanner.propTypes = {
  txt: PropTypes.string.isRequired,
  deviceos: PropTypes.string.isRequired,
  bimg: PropTypes.string.isRequired,
  // userId: PropTypes.string.isRequired,
  // clientId: PropTypes.string.isRequired,
  // utmcampaign: PropTypes.string.isRequired,
  // utmSource: PropTypes.string.isRequired,
  // utmMedium: PropTypes.string.isRequired,
  // reminder: PropTypes.string.isRequired,
  // marketingSource: PropTypes.string.isRequired,
};
export default PromotionMobileBanner;
