/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import './index.css';
import Helmet from 'react-helmet';
import Footer from '../../../View/Footer';
import HowBox from '../../../View/Components/HowBox';
import FavoriteStore from '../../../View/Components/FavoriteStore';
import SaveFavorite from '../../../View/Components/SaveFavorite';
import PromotionBanner from '../../../View/Components/PromotionBanner';
import Header from '../../../View/PromotionHeaderSub';
import landingCashX from '../../../images/promotion/Airfryer.png';
import SweepStakeLogin from '../../SweepStakeLogin';
import SweepstakeRegisterd from '../../SweepstakeRegisterd';
// import dailymail from '../../../images/promotion/dailymail_logo.png';
// import accuweather from '../../../images/promotion/accuweather_logo.png';
// import usaToday from '../../../images/promotion/usa_today_logo.png';
import { ThemeContext } from '../../../utils/theme';
// import {
//   CHROME_EXTENSION_LINK,
// } from '../../../utils/constants';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const theme = this.context;
    return (
      <div id="sweep">
        <Helmet>
          <title>cashX: We’re giving away an Air Fryer</title>
        </Helmet>
        <Header user={this.props.user} {...this.props} txt="air fyer" />
        <div className="home1">

          {this.props.user === null
            && (
              <div className="mobilesweepview">
                <SweepStakeLogin user={this.props.user} {...this.props} />
              </div>
            )}
          {this.props.user !== null && (
            <div className="mobilesweepview">
              <SweepstakeRegisterd user={this.props.user} {...this.props} />
            </div>
          )}
          <div className="desktopsweepview">
            <PromotionBanner
              txt="air fryer"
              theme={theme}
              bimg={landingCashX}
            />
            <SaveFavorite />
            <HowBox />
            <div className="favstore">
              <FavoriteStore />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
Home.contextType = ThemeContext;
