/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React, { Component } from 'react';
import './index.css';
import Helmet from 'react-helmet';
import Footer from '../../../View/Footer';
import HowBox from '../../../View/Components/HowBox';
import FavoriteStore from '../../../View/Components/FavoriteStore';
import Header from '../../../View/PromotionHeaderSub';
import landingCashX from '../../../images/promotion/landing_1.png';
import dailymail from '../../../images/promotion/dailymail_logo.png';
import accuweather from '../../../images/promotion/accuweather_logo.png';
import usaToday from '../../../images/promotion/usa_today_logo.png';
import two from '../../../images/home/02.png';
import three from '../../../images/home/03.png';
import four from '../../../images/home/04.png';
import { ThemeContext } from '../../../utils/theme';
import landingpagecookie from '../../../utils/landingpagecookie';
import { cookieGet, API_BASE_URL } from '../../../utils/constants';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const valOfUserId = cookieGet('CX_userId');
    if (!valOfUserId) {
      landingpagecookie(`${API_BASE_URL}/cx/v1/user/landing-page`, 'Desktop');
    }
  }

  render() {
    const theme = this.context;
    return (
      <div>
        <Helmet>
          <title>cashX: Auto Apply Coupons and Get Cash Back Every Time You Shop</title>
        </Helmet>
        <div id="cashxL">
          <Header user={this.props.user} {...this.props} />
        </div>
        <div className="home1">
          <div className="home-wrapper1 container">
            <div className="text-wrapper-banner">
              <p className="heading">Auto apply coupons and get cash back every time you shop.</p>
              <p className="twoinone">2-in-1 in one extension.</p>
              <div className="text-descr">
                <p>Some apps save you money, some earn you cash back. We do both.</p>
                <p>
                  With one click, cash
                  <sup>x</sup>
                  {' '}
                  offers cash back whether a coupon works or not.
                </p>
              </div>
              <div className="add-btn-wrapper1">
                <button
                  className="add-chrome-btn1"
                  style={{ backgroundColor: theme.primary }}
                  type="button"
                  // onClick={() => { window.open(CHROME_EXTENSION_LINK, '_blank'); }}
                  onClick={(e) => {
                    e.preventDefault();
                    landingpagecookie('https://cashx-api.intentx.com/cx/v1/user/download', 'eventdwn');
                  }}
                >
                  Add to Chrome - it’s free

                </button>

              </div>
              <div className="clientlogo">
                <span><img src={dailymail} alt="dailymail logo" /></span>
                <span><img src={accuweather} alt="accuweather logo" /></span>
                <span><img src={usaToday} alt="usaToday logo" /></span>
              </div>
            </div>
            <div className="image-wrapper-banner">
              <img src={landingCashX} alt="cashX Savings" />
            </div>

          </div>
          <div className="howbox">
            <HowBox />
          </div>
          <FavoriteStore />

          <div className="home-wrapper1 container">
            <div className="text-wrapper">
              <p className="heading">You Add to Cart. We Find the Best Coupons.</p>
              <p>
                A lot of times, retailers offer coupons or deals that you may not know about. cash
                <sup>x</sup>
                {' '}
                combs through these to find the deal that provides you with the biggest discount, and automatically applies it to your cart.
              </p>
            </div>
            <div className="image-wrapper">
              <img src={two} alt="cashX Savings" />
            </div>
          </div>
          <div className="home-wrapper1 container checkout-part">
            <div className="image-wrapper">
              <img src={three} alt="cashX Savings" />
            </div>
            <div className="text-wrapper">
              <p className="heading">
                Coupon or Not,
                <br />
                {' '}
                We Pay Cash Back.
              </p>
              <p>It doesn’t matter if you used any coupons or deals, our cash back is earned with each purchase at participating sites. We are serious about saving you money. We offer cash back at a range of popular online retailers.</p>
            </div>
          </div>
          <div className="home-wrapper1 container paypal-part">
            <div className="text-wrapper">
              <p className="heading">
                You Shop Each Month. We Pay You Each Month.
              </p>
              <p>
                As you shop each month using cash
                <sup>x</sup>
                , we cumulate your earned cash back and pay it back to you at the beginning of each month. There is no points system or complicated rewards process—we pay you cash through PayPal or Amazon gift cards.
              </p>
            </div>
            <div className="image-wrapper">
              <img src={four} alt="cashX Savings" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
Home.contextType = ThemeContext;
