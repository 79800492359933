import React, { Component } from 'react';

export default class Loader extends Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <img
                    src={require('../../images/loader-cashx.gif')}
                    alt="Loading..."
                    style={{
                        width: 100,
                        height: 100,
                        marginTop: '20%'
                    }}
                />
            </div>

        )
    }
}