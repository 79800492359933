export const userSignUp = '/cx/v1/user/signup';
export const userLogin = '/auth/login';
export const logout = '/user/logout';
export const userPayouts = '/cx/v1/payout/list?clientId=';
export const orderHistory = '/cx/v1/payout/history?clientId=';
export const getProfile = '/user/me';
export const editProfile = '/user/account';
export const updatePassword = '/user/account/password';
export const dashboardInfo = '/user/account';
export const savePassword = '/cx/v1/user/savePassword';
export const forgotPassword = '/cx/v1/user/resetPassword';
export const saveFeedback = '/cx/v1/feedback/save';
export const addPayPal = '/user/addPaypal?paypalId=';
export const amazonOrPayPal = '/user/activateAmazonOrPaypal?isAmazon=';
export const addPayPalId = '/cx/v1/paypal/savePaypalEmailId?code=';
export const savePayPalUser = '/cx/v1/paypal/savePaypalUser?code=';
export const referral = '/cx/v1/referral/save';
