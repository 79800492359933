/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
// import React, { useContext, useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// import Header from '../../View/Header';
import Footer from '../../View/Footer';
// import ThankyouSweepstake from '../LandingPage/ThankyouSweepstake';
import ActivityIndicator from '../../View/ActivityIndicator';
import Header from '../../View/PromotionHeaderSub';

import point1 from '../../images/howtouseit-mobweb/images/point1.png';
import point2 from '../../images/howtouseit-mobweb/images/point2.png';
import point3 from '../../images/howtouseit-mobweb/images/point3.png';
import point4 from '../../images/howtouseit-mobweb/images/point4.png';
import point5 from '../../images/howtouseit-mobweb/images/point5.png';
import point6 from '../../images/howtouseit-mobweb/images/point6.png';
import point7 from '../../images/howtouseit-mobweb/images/point7.png';
import appstore from '../../images/howtouseit-mobweb/app-store-badges/appstore.png';
import googleplaystore from '../../images/howtouseit-mobweb/app-store-badges/google-play-badge.png';
import one from '../../images/howtouseit-mobweb/numbers/1.png';
import two from '../../images/howtouseit-mobweb/numbers/2.png';
import three from '../../images/howtouseit-mobweb/numbers/3.png';
import four from '../../images/howtouseit-mobweb/numbers/4.png';
import five from '../../images/howtouseit-mobweb/numbers/5.png';
import six from '../../images/howtouseit-mobweb/numbers/6.png';
import seven from '../../images/howtouseit-mobweb/numbers/7.png';
// import three from '../../images/howitworks/03.png';

import './index.css';
// import { ThemeContext } from '../../utils/theme';
// import {
//   // CHROME_EXTENSION_LINK,
//   // cookieGet,
// } from '../../utils/constants';

// function getCookie(name) {
//   const result = document.cookie.match(
//     new RegExp(`${name}=([^;]+)`),
//   );
//   if (result) {
//     return result[1];
//   }
//   return null;
// }

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export default function HowItWorks(props) {
  // const [cxDownload, setcxDownload] = useState([]);
  // const [getmcampign, setGetmcampign] = useState([]);
  const [loading, setLoading] = useState(true);
  const a = getMobileOperatingSystem();
  console.log('a :>> ', a);
  useEffect(() => {
    // setInterval(() => {
    // const cxDownload1 = cookieGet('CX_DWN');
    // setcxDownload(cxDownload1);
    // const getmcampign1 = getCookie('UTM_CAMPAIGN');
    // setGetmcampign(getmcampign1);
    // }, 2000);
    setLoading(false);
  }, []);

  // const theme = useContext(ThemeContext);

  if (loading) {
    return (
      <div className="login-main">
        <Helmet>
          <title>How It Works | cashX</title>
          <meta name="description" content="Feedback | cashX" />
        </Helmet>
        <Header user={props.user} {...props} />
        <div className="login-container">
          <ActivityIndicator />
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <>
      <div className="mobapp">
        <Helmet>
          <title>cashX: We’re giving away an AirPods Pro</title>
        </Helmet>
        <Header user={props.user} {...props} txt="AirPods Pro" />

        <div className="sweepappmobile">
          <div className="part1">
            <div className="p1">
              <h2>
                How
                {' '}
                <br />
                {' '}
                cash
                <sup>x</sup>
                {' '}
                app
                {' '}
                <br />
                {' '}
                Works:
              </h2>
              <div className="num">
                <img src={one} alt="cashX Savings" />
                <p>
                  Open your cash
                  <sup>x</sup>
                  {' '}
                  app in your phone and find your favorite store.

                </p>
              </div>
            </div>
            <div className="p2">
              <img src={point1} alt="cashX point1" />
            </div>

          </div>
          <div className="part2">
            <div className="p2">
              <img src={point2} alt="cashX point1" />
            </div>
            <div className="p1">
              <div className="num">
                <img src={two} alt="cashX Savings" />
                <p>Search your favorite store.</p>
              </div>
            </div>

          </div>
          <div className="part3">
            <div className="p1">
              <div className="num">
                <img src={three} alt="cashX Savings" />
                <p>Select your favorite store and shop as usual.</p>
              </div>
            </div>
            <div className="p2">
              <img src={point3} alt="cashX point1" />
            </div>

          </div>
          <div className="part4">
            <div className="p2">
              <img src={point4} alt="cashX point1" />
            </div>
            <div className="p1">
              <div className="num">
                <img src={four} alt="cashX Savings" />
                <p>At the checkout activate our coupon robot to get the best savings!</p>
              </div>
            </div>

          </div>
          <div className="part5">
            <div className="p1">
              <div className="num">
                <img src={five} alt="cashX Savings" />
                <p>Coupons are not the only thing you can get with us, there is also plenty stores with cash back</p>
              </div>
            </div>
            <div className="p2">
              <img src={point5} alt="cashX point1" />
            </div>

          </div>
          <div className="part6">
            <div className="p2">
              <img src={point6} alt="cashX point1" />
            </div>
            <div className="p1">
              <div className="num">
                <img src={six} alt="cashX Savings" />
                <p>Finish your other to save money and earn cash back!</p>
              </div>
            </div>

          </div>
          <div className="part7">
            <div className="p1">
              <div className="num">
                <img src={seven} alt="cashX Savings" />
                <p>Get paid by PayPal or Amazon Gift Card every month.</p>
              </div>
            </div>
            <div className="p2">
              <img src={point7} alt="cashX point1" />
            </div>

          </div>

          <div className="steps-btn-wrapper sbw">
            <button
              className="add-chrome-btn1"
              type="button"
              cashx-data-position="16"
              style={{ backgroundColor: 'rgb(236, 22, 140)' }}
              onClick={() => { window.open('https://getcashx.com/app-faq', '_blank'); }}
            >
              See Frequently Asked Questions
            </button>
            <span className="backdrop-btn" />
          </div>

          <div className="badgesforapp">
            <img src={appstore} alt="cashX point1" />
            <a href="https://play.google.com/store/apps/details?id=com.cashx">
              <img src={googleplaystore} alt="cashX app" />
            </a>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}
HowItWorks.propTypes = {
  user: PropTypes.object,
};
