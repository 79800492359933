/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Profile from '../../View/Profile';
import PayPal from '../../images/paypal-logo.png';
import Amazon from '../../images/amazon.png';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import cookie from '../../utils/cookie';
import fetchPost from '../../utils/fetchPost';
import { amazonOrPayPal } from '../../utils/apiEndPoint';

export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paypal: true,
      amazon: false,
    };
    this.handleDone = this.handleDone.bind(this);
  }

  componentDidMount() {
    const userToken = cookie.getCookie('userToken');
    if (userToken === null) {
      this.props.history.push('/login');
      this.props.onLogout();
    }
    const { user } = this.props;
    this.setState({
      paypal: !user.amazonEnabled,
      amazon: user.amazonEnabled,
      submitLoading: false,
    });
  }

  handleDone() {
    const userToken = cookie.getCookie('userToken');
    this.setState({ submitLoading: true, error: '' });
    const { amazon } = this.state;
    fetchPost(amazonOrPayPal + amazon, {}, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    })
      .then((res) => {
        if (res.status === 'success') {
          this.props.history.push('/payout');
        } else {
          this.setState({
            error: 'Something went wrong. Please try again later',
            submitLoading: false,
          })
            .catch(() => {
              this.setState({
                error: 'Something went wrong. Please try again later',
                submitLoading: false,
              });
            });
        }
      });
  }

  render() {
    const theme = this.context;
    const { paypal, amazon, error } = this.state;
    return (
      <Profile {...this.props}>
        <div className="account" style={{ marginTop: 5 }}>
          <p className="heading-1" style={{ color: 'black' }}>
            cash
            <sup>x</sup>
            {' '}
            Payout
          </p>
          <p className="heading-2">Below is a look at your earned cash back and payouts per month.</p>
          <div className="payment-main-container" style={{ position: 'relative' }}>
            {error ? (
              <p style={{
                color: 'red',
                position: 'absolute',
                left: 2,
                fontSize: 15,
              }}
              >
                {error}
              </p>
            ) : null}
            <div>
              <div
                id="done"
                onClick={this.handleDone}
              >
                <p>Done</p>
              </div>
            </div>
          </div>
          <div className="main-body">
            <p>Select your payment method:</p>
            <div className="selection-container">
              <div
                className="payment-container"
                onClick={() => {
                  this.setState({
                    paypal: true,
                    amazon: false,
                  });
                }}
              >
                {paypal ? (
                  <p
                    className="checkmark-after"
                    style={{
                      backgroundColor: theme.primary,
                      borderColor: theme.primary,
                      borderRadius: '50%',
                    }}
                  >
                    <svg style={{ position: 'relative', left: -1 }} fill="#fff" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -46 417.81333 417" width="12px"><path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" /></svg>
                  </p>
                ) : <p className="checkmark-before" />}
                <img src={PayPal} alt="PayPal" style={{ width: 100, height: 49, marginTop: -12 }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div
                  className="payment-container"
                  onClick={() => {
                    this.setState({
                      paypal: false,
                      amazon: true,
                    });
                  }}
                >
                  {amazon ? (
                    <p
                      className="checkmark-after"
                      style={{
                        backgroundColor: theme.primary,
                        borderColor: theme.primary,
                        borderRadius: '50%',
                      }}
                    >
                      <svg style={{ position: 'relative', left: -1 }} fill="#fff" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -46 417.81333 417" width="12px"><path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" /></svg>
                    </p>
                  ) : <p className="checkmark-before" />}
                  <img src={Amazon} alt="Amazon" style={{ width: 100, height: 49, opacity: 1 }} />
                </div>
                {/* <span>Coming soon</span> */}
              </div>
            </div>
          </div>
        </div>

      </Profile>
    );
  }
}
PaymentMethod.contextType = ThemeContext;
