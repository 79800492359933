/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';

import '../SweepstakeAirfyer/index.css';
import PromotionBanner from '../../../View/Components/PromotionMobileBanner';
import landingCashX from '../../../images/promotion/airpodspro2xmob.png';
import { ThemeContext } from '../../../utils/theme';
// import cookie from '../../../utils/cookie';
import { cookieGet } from '../../../utils/constants';

function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const checkurl = window.location.href;
    const cxDownload = cookieGet('CX_DWN');
    // this.setState({ cxCookie: cxDownload });
    console.log('cxDownload :>> ', cxDownload);
    console.log('checkurl :>> ', checkurl);

    window.addEventListener('resize', this.screenresize);
  }

  render() {
    const valOfUserId = getCookie('CX_userId');
    console.log('valOfUserId :>> ', valOfUserId);
    console.log('this.props.deviceOs-------- :>> ', this.props.deviceos);

    const clientId = getCookie('CX_CLIENT');
    const utmcampaign = getCookie('UTM_CAMPAIGN');
    const utmSource = getCookie('UTM_SOURCE');
    const utmMedium = getCookie('UTM_MEDIUM');
    const reminder = getCookie('reminder');
    const marketingSource = getCookie('MARKETING_SOURCE');

    return (

      <>
        <PromotionBanner
          txt="AirPods Pro"
          bimg={landingCashX}
          userId={valOfUserId}
          clientId={clientId}
          utmcampaign={utmcampaign}
          utmSource={utmSource}
          utmMedium={utmMedium}
          reminder={reminder}
          marketingSource={marketingSource}
          deviceos={this.props.deviceos}
        />
      </>
    );
  }
}
Home.contextType = ThemeContext;
