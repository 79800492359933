/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import Logo from '../../images/logo_2x.png';
import Footer from '../../View/Footer';

export default function ResetPasswordSuccess(props) {
  return (
    <div>
      <div className="login-main">
        <Helmet>
          <title>Reset Password | cashX</title>
          <meta name="description" content="Reset your password for cashX" />
        </Helmet>
        <Header user={props.user} {...props} />
        <div
          className="login-container"
          style={{
            minHeight: '55vh', width: 465, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <img src={Logo} alt="cashX" />
          <p className="success-reset">
            You have successfully reset your password.
          </p>
          <button
            className="reset-password log-in"
            onClick={() => {
              props.history.push('/login');
            }}
            type="submit"
          >
            Log in
          </button>
        </div>
        <Footer />
      </div>
    </div>
  );
}
ResetPasswordSuccess.contextType = ThemeContext;
ResetPasswordSuccess.propTypes = {
  user: {
    name: PropTypes.string,
  },
  location: {
    search: PropTypes.string,
  },
  history: PropTypes.object,
};
