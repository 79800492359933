/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import './index.css';
import { ThemeContext } from '../../utils/theme';

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  componentDidMount() {
    const questions = [
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        open: false,
      },
      {
        ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque iaculis efficitur cursus. Fusce aliquet, quam at posuere rhoncus, dui tellus elementum lacus, vitae fermentum dui massa a libero. Nullam condimentum rhoncus metus, sit amet fermentum lorem tempus quis. Mauris ac facilisis odio, id tempor lacus. Vestibulum et orci nibh. Nam at erat faucibus, iaculis ante ac, rhoncus sem. Vestibulum eleifend gravida eros quis cursus. Proin diam augue, dictum eu magna id, scelerisque lacinia nisl. Curabitur nunc dolor, aliquam at nunc ut, blandit viverra quam. Ut egestas, nibh eu facilisis gravida, urna purus scelerisque turpis, ut sodales nisi massa in sapien.',
        open: false,
      },
    ];
    this.setState({ questions });
  }

  toggleAccordion(index) {
    const { questions } = this.state;
    const sectionsBody = [];
    questions.forEach((que, i) => {
      if (i === index) {
        que.open = !que.open;
      } else {
        que.open = false;
      }
      sectionsBody.push(que);
    });
    this.setState({ questions: sectionsBody });
  }

  // eslint-disable-next-line class-methods-use-this
  returnQue(val) {
    let returnValue = null;
    switch (val) {
      case 0:
        returnValue = (
          <span className="acc-heading text">
            What is cash
            <sup>x</sup>
            ?
          </span>
        );
        break;
      case 1:
        returnValue = (
          <span className="acc-heading text">
            How does cash
            <sup>x</sup>
            {' '}
            work?
          </span>
        );
        break;
      case 2:
        returnValue = (
          <span className="acc-heading text">
            How do I install cash
            <sup>x</sup>
            ?
          </span>
        );
        break;
      case 3:
        returnValue = (
          <span className="acc-heading text">
            What are the current qualifying retailers for cash
            <sup>x</sup>
            ?
          </span>
        );
        break;
      case 4:
        returnValue = (
          <span className="acc-heading text">

            How do i get my cashback?
          </span>
        );
        break;
      case 5:
        returnValue = (
          <span className="acc-heading text">
            What are the cash back payment methods?
          </span>
        );
        break;
      default:
        returnValue = (
          <span className="acc-heading text">
            When do I receive my earned cash back?
          </span>
        );
    }
    return returnValue;
  }

  returnAns(val) {
    let returnValue = null;
    switch (val) {
      case 0:
        returnValue = (
          <p className="acc-sub-part">
            cash
            <sup>x</sup>
            {' '}
            is a Google Chrome extension that helps you save and earn money when you make purchases on qualifying retailer websites.
          </p>
        );
        break;
      case 1:
        returnValue = (
          <>
            <p className="acc-sub-part heading">
              cash
              <sup>x</sup>
              {' '}
              saves you money at checkout
            </p>
            <p className="acc-sub-part">
              The cash
              <sup>x</sup>
              {' '}
              Chrome extension works by exposing great coupons and deals on retailer sites. Our tool automatically finds the most savings and applies them to your cart before a purchase is completed.
              {' '}
            </p>
            <p className="acc-sub-part heading">
              Earn cash back when you use cash
              <sup>x</sup>
            </p>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              also allows you to earn cash back on purchases completed on qualifying retailer websites.
            </p>
          </>
        );
        break;
      case 2:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              is easy to install. Simply visit the cash
              <sup>x</sup>
              {' '}
              extension page on the Google Chrome Web Store and download the cash
              <sup>x</sup>
              {' '}
              extension.
            </p>
            <p className="acc-sub-part">
              <a
                href="www.crome.com"
                style={{ color: '#EC168C', letterSpacing: '-0.75px', fontWeight: 'bold' }}
              >
                CLICK HERE TO DOWNLOAD CASHX
              </a>
            </p>
            <p className="acc-sub-part">
              Once the cash
              <sup>x</sup>
              {' '}
              extension is installed, we’ll do the rest. When you visit a qualifying retailer website, the cash
              <sup>x</sup>
              {' '}
              modal will automatically inform you of savings available on that website. As you make qualifying purchases, cash
              <sup>x</sup>
              {' '}
              will keep track of your earned cash back.
            </p>
          </>
        );
        break;
      case 3:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              works with a wide selection of online retail partners. If your selected retailer participates in our program, the cash
              <sup>x</sup>
              {' '}
              extension will automatically pop up for you to activate cash back during shopping and check out.
            </p>
          </>
        );
        break;
      case 4:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              will deposit your earned cash back to your chosen payment method each month. Payment options include your PayPal account or an Amazon gift card.
            </p>
          </>
        );
        break;
      case 5:
        returnValue = (
          <>
            <p className="acc-sub-part">
              cash
              <sup>x</sup>
              {' '}
              pays out your cash back earnings into your PayPal account or an Amazon gift card at the beginning of each month.
            </p>
          </>
        );
        break;
      default:
        returnValue = (
          <p className="acc-sub-part">
            cash
            <sup>x</sup>
            {' '}
            <span>
              {' '}
              keeps a total of your earned cash back in your cash
              <sup>x</sup>
              {' '}
              account. Once you have chosen your primary cash back method on your cash
              <sup>x</sup>
              {' '}
              profile, cash
              <sup>x</sup>
              {' '}
              automatically pays out your cash back earnings into your PayPal account or an Amazon gift card at the beginning of each month.
            </span>
            {' '}
            <br />
            <br />
            {' '}
            <span>To be paid out, you must have earned $5 or more for the pay out to be sent to your account. If less than $5 is earned during a month, your accumulated earnings will be rolled over to the next month. Once your accumulated earnings total $5 or more, your payout will be sent to your account automatically.</span>
          </p>
        );
    }
    return returnValue;
  }

  render() {
    const { questions } = this.state;
    const theme = this.context;
    const questionsView = [];
    questions.forEach((element, i) => {
      questionsView.push(
        <div
          className={`accordion ${theme.name}-div ${element.open ? 'open' : ''}`}
          key={i}
        >
          <div
            className="accordionSectionHeader"
            onClick={() => this.toggleAccordion(i)}
            role="button"
            onKeyPress={() => { }}
          >
            <span className="acc-heading icon">{element.open ? '-' : '+'}</span>
            {this.returnQue(i)}
          </div>
          <div className="accordion-form">
            {this.returnAns(i)}
          </div>
        </div>,
      );
    });
    return (
      <div>
        <Helmet>
          <title>Frequently asked questions | cashX</title>
          <meta name="description" content="Frequently asked questions for cashX website." />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div className="main-faq container">
          <p className="faq-heading">Frequently Asked Questions</p>
          {questionsView}
          {/* <div style={{ marginTop: 40 }}>
            <p id="got-more">Got more questions?</p>
            <p className="mail-info">Please send us an email at</p>
            <p
              className="mail-info"
            >
              <span
                role="button"
                onKeyPress={() => {}}
                onClick={() => {
                  window.location.href = 'mailto:support@cashx.com';
                }}
                style={{ cursor: 'pointer', color: theme.primary }}
              >
                support@cashx.com
              </span>
            </p>
          </div> */}
        </div>
        <Footer />
      </div>
    );
  }
}
FAQ.propTypes = {
  user: PropTypes.object,
};
FAQ.contextType = ThemeContext;
