/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import cookie from '../../utils/cookie';
import ActivityIndicator from '../../View/ActivityIndicator';
import fgh from '../../utils/fetchGetWithHeaders';
import fetchPost from '../../utils/fetchPost';
import SurveyImage from '../../images/survey-graphic.png';

import { getProfile, dashboardInfo, saveFeedback } from '../../utils/apiEndPoint';
import { cookieGet, API_BASE_URL, install } from '../../utils/constants';

export default class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: [],
      websites: '',
      showToast: false,
      error: '',
      otherReason: '',
      userId: '',
      loading: true,
    };
    this.submitFeedback = this.submitFeedback.bind(this);
  }

  async componentDidMount() {
    const userId = [];
    const downloadUserId = cookieGet('CX_userId');
    // eslint-disable-next-line camelcase
    const marketingsource1 = cookieGet('MARKETING_SOURCE');
    const source1 = cookieGet('UTM_SOURCE');
    const campaign1 = cookieGet('UTM_CAMPAIGN');
    const medium1 = cookieGet('UTM_MEDIUM');
    const reminder1 = cookieGet('reminder');

    let attach = '';
    console.log('marketingsource1 :>> ', marketingsource1);
    if (marketingsource1) {
      attach += `&marketingSource=${marketingsource1}`;
    }
    if (source1) {
      attach += `&utm_source=${source1}`;
    }
    if (campaign1) {
      attach += `&utm_campaign=${campaign1}`;
    }
    if (medium1) {
      attach += `&utm_medium=${medium1}`;
    }
    if (reminder1) {
      attach += `&reminder=${reminder1}`;
    }

    userId[1] = downloadUserId;
    const extensionUserId = cookieGet('extensionUserId');
    if (extensionUserId) {
      userId[1] = extensionUserId;
    }
    if (!userId[1]) {
      const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      };

      const cookie1 = makeid(20);
      document.cookie = `CX_DWN=${cookie1}`;
      const clientId = 'intentx';
      document.cookie = `CX_CLIENT=${clientId}`;
      // document.cookie = `CX_CLIENT=${clientId}`;
      // const marketing_source = getCookie('MARKETING_SOURCE');
      const requestOptions = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Cache: 'no-cache',
        },
      };

      fetch(`https://cashx-api.intentx.com/cx/v1/user/download?clientId=intentx&dwnc=${cookie1}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log('result :>> ', result);
          userId[1] = result.userID;
          document.cookie = `CX_userId=${result.userID}`;
          this.setState({ userId: userId[1] });
        })
        .catch(() => { })
        .then(async () => {
          if (this.props.location && this.props.location.search) {
            const searchParams = this.props.location.search.split('?');
            if (searchParams[1].indexOf('uninstall') !== -1) {
              await this.uninstallingProcess(userId, attach);
            }
          }
        });
    } else {
      this.setState({ userId: userId[1] });
      if (this.props.location && this.props.location.search) {
        const searchParams = this.props.location.search.split('?');
        if (searchParams[1].indexOf('uninstall') !== -1) {
          await this.uninstallingProcess(userId, attach);
        }
      }
    }
    const param = this.props.location.search;
    if (param) {
      if (param.indexOf('token=') !== -1) {
        const token = param.split('token=');
        const data = {

        };
        fgh(getProfile, {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token[1]}`,
        }).then((res) => {
          const name = res.name.split(' ');
          data.firstName = name[0];
          data.lastName = name[name.length - 1];
          data.email = res.email;
          cookie.setCookie({ userToken: token[1] });
        })
          .then(() => {
            const userToken = cookie.getCookie('userToken');
            return fgh(dashboardInfo, {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            });
          })
          .then((res) => {
            data.currentMonthCashBack = parseFloat(res.currentMonthCashBack).toFixed(2);
            data.cashback = parseFloat(res.cashback).toFixed(2);
            data.paypalVerified = res.paypalVerified;
            data.day = res.responseTimeDto.day;
            data.month = res.responseTimeDto.month;
            data.year = res.responseTimeDto.year;
            data.paypalEmail = res.paypalEmail;
            data.amazonEnabled = res.amazonEnabled;
            data.clientId = res.clientId || 'intentx';
            cookie.setCookie({ userInfo: JSON.stringify(data) });
            this.setState({ loading: false });
            this.props.onLogin(data);
          })
          .catch(() => {
            this.setState({ backendError: 'Something went wrong', loading: false });
          });
      } else {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  // & marketing_source=${ marketingSource }
  async uninstallingProcess(userId, attach) {
    fetch(`${API_BASE_URL + install}?userID=${userId[1]}${attach}&eventType=UNINSTALL`)
      .then((res) => res.text())
      .catch(() => { });
  }

  submitFeedback() {
    const {
      reason, otherReason, websites, userId,
    } = this.state;
    this.setState({ error: '', submitLoading: true });
    if (!reason.length) {
      this.setState({ error: 'Please select a reason', showToast: true });
      setTimeout(() => this.setState({
        showToast: false,
      }), 2000);
      return;
    } else if (reason.length && reason.indexOf(3) !== -1 && websites === '') {
      this.setState({ error: 'Please mention the websites', showToast: true });
      setTimeout(() => this.setState({
        showToast: false,
      }), 2000);
      return;
    } else if (reason.length && reason.indexOf(6) !== -1 && otherReason === '') {
      this.setState({ error: 'Please mention the reason', showToast: true });
      setTimeout(() => this.setState({
        showToast: false,
      }), 2000);
      return;
    }
    let clientId = cookieGet('CX_CLIENT');
    if (!clientId) {
      clientId = 'intentx';
    }
    fetchPost(saveFeedback, {
      answer1: reason.indexOf(1) !== -1 ? '1' : '0',
      answer2: reason.indexOf(2) !== -1 ? '1' : '0',
      answer3: reason.indexOf(3) !== -1 ? websites : '0',
      answer4: reason.indexOf(4) !== -1 ? '1' : '0',
      answer5: reason.indexOf(5) !== -1 ? '1' : '0',
      answer6: reason.indexOf(6) !== -1 ? otherReason : '0',
      clientId,
      question1: 'Privacy Concerns',
      question2: 'Prefer another coupon or cash back extension',
      question3: 'cashX doesnt work at my favorite website',
      question4: 'Dont understand how to use the extension',
      question5: 'Find the extension too buggy',
      question6: 'Others',
      userId,
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        } else {
          this.setState({
            error: 'Thank you for submitting your valuable feedback', showToast: true,
          });
          setTimeout(() => {
            this.setState({
              showToast: false,
            });
            this.props.history.push('/feedback-submitted');
          }, 2000);
        }
      })
      .catch(() => {
        this.setState({
          error: 'Something went wrong', showToast: true,
        });
        setTimeout(() => {
          this.setState({ showToast: false });
        }, 2000);
      });
  }

  render() {
    const {
      loading, showToast, error, reason,
    } = this.state;
    console.log('this.state.marketingsourceadgsfgsgsdfsa :>> ', this.state.marketingsource);

    if (loading) {
      return (
        <div className="login-main">
          <Helmet>
            <title>Feedback | cashX</title>
            <meta name="description" content="Feedback | cashX" />
          </Helmet>
          <Header user={this.props.user} {...this.props} />
          <div className="login-container">
            <ActivityIndicator />
          </div>
          <Footer />
        </div>
      );
    }
    return (
      <div className="login-main">
        <Helmet>
          <title>Feedback | cashX</title>
          <meta name="description" content="Feedback | cashX" />
        </Helmet>
        <Header user={this.props.user} {...this.props} />
        <div
          className="feed-main"
        >
          <div className="feed-box">
            <p className="title-text">
              Why did you uninstall cash
              <sup>X</sup>
              ?
            </p>
            <p className="sub-title-text">Please check all that apply:</p>
            {showToast ? (
              <div className="error-toast" style={{ bottom: '9vh', left: '38vw' }}>
                {' '}
                <p>{error}</p>
              </div>
            ) : null}
            <div className="questions">
              <ul>
                <li className="checkbox">
                  <input
                    type="checkbox"
                    id="qcheck1"
                    checked={reason.indexOf(1) !== -1}
                    onChange={() => {
                      if (reason.indexOf(1) !== -1) {
                        const a = [];
                        reason.forEach((ele) => {
                          if (ele !== 1) {
                            a.push(ele);
                          }
                        });
                        this.setState({ reason: a });
                      } else {
                        this.setState({
                          reason: [...this.state.reason, 1],
                        });
                      }
                    }}
                    className="qcheck"
                  />
                  <label htmlFor="qcheck1">
                    <span className="qchecklabel">Privacy concerns</span>
                  </label>
                </li>
                <li className="checkbox">
                  <input
                    type="checkbox"
                    id="qcheck2"
                    checked={reason.indexOf(2) !== -1}
                    onChange={() => {
                      if (reason.indexOf(2) !== -1) {
                        const a = [];
                        reason.forEach((ele) => {
                          if (ele !== 2) {
                            a.push(ele);
                          }
                        });
                        this.setState({ reason: a });
                      } else {
                        this.setState({
                          reason: [...this.state.reason, 2],
                        });
                      }
                    }}
                    className="qcheck"
                  />
                  <label htmlFor="qcheck2">
                    <span className="qchecklabel">Prefer another coupon or cash back extension</span>
                  </label>
                </li>
                <li className="checkbox">
                  <input
                    type="checkbox"
                    id="qcheck3"
                    checked={reason.indexOf(3) !== -1}
                    onChange={() => {
                      if (reason.indexOf(3) !== -1) {
                        const a = [];
                        reason.forEach((ele) => {
                          if (ele !== 3) {
                            a.push(ele);
                          }
                        });
                        this.setState({ reason: a });
                      } else {
                        this.setState({
                          reason: [...this.state.reason, 3],
                        });
                      }
                    }}
                    className="qcheck"
                  />
                  <label htmlFor="qcheck3">
                    <span className="qchecklabel">cashX doesn’t work at my favorite website</span>
                  </label>
                  <div className={reason.indexOf(3) !== -1 ? 'box open' : 'box'}>
                    <div style={{
                      paddingLeft: 35, paddingTop: 10,
                    }}
                    >
                      <p className="qchecklabel">Which website(s)?</p>
                      <input
                        className="website-input"
                        type="text"
                        onChange={(e) => {
                          this.setState({
                            websites: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                </li>
                <li className="checkbox">
                  <input
                    type="checkbox"
                    id="qcheck4"
                    checked={reason.indexOf(4) !== -1}
                    onChange={() => {
                      if (reason.indexOf(4) !== -1) {
                        const a = [];
                        reason.forEach((ele) => {
                          if (ele !== 4) {
                            a.push(ele);
                          }
                        });
                        this.setState({ reason: a });
                      } else {
                        this.setState({
                          reason: [...this.state.reason, 4],
                        });
                      }
                    }}
                    className="qcheck"
                  />
                  <label htmlFor="qcheck4">
                    <span className="qchecklabel">Don’t understand how to use the extension</span>
                  </label>
                </li>
                <li className="checkbox">
                  <input
                    type="checkbox"
                    id="qcheck5"
                    checked={reason.indexOf(5) !== -1}
                    onChange={() => {
                      if (reason.indexOf(5) !== -1) {
                        const a = [];
                        reason.forEach((ele) => {
                          if (ele !== 5) {
                            a.push(ele);
                          }
                        });
                        this.setState({ reason: a });
                      } else {
                        this.setState({
                          reason: [...this.state.reason, 5],
                        });
                      }
                    }}
                    className="qcheck"
                  />
                  <label htmlFor="qcheck5">
                    <span className="qchecklabel">Find the extension too buggy</span>
                  </label>
                </li>
                <li className="checkbox">
                  <input
                    type="checkbox"
                    id="qcheck6"
                    checked={reason.indexOf(6) !== -1}
                    onChange={() => {
                      if (reason.indexOf(6) !== -1) {
                        const a = [];
                        reason.forEach((ele) => {
                          if (ele !== 6) {
                            a.push(ele);
                          }
                        });
                        this.setState({ reason: a });
                      } else {
                        this.setState({
                          reason: [...this.state.reason, 6],
                        });
                      }
                    }}
                    className="qcheck"
                  />
                  <label htmlFor="qcheck6">
                    <span className="qchecklabel">Other</span>
                  </label>
                  <div className={reason.indexOf(6) !== -1 ? 'box open' : 'box'}>
                    <textarea
                      rows={4}
                      placeholder="Let us know how we can improve your shopping experience:"
                      cols={40}
                      style={{
                        marginLeft: 35,
                        marginTop: 10,
                      }}
                      className="website-input"
                      onChange={(e) => {
                        this.setState({
                          otherReason: e.target.value,
                        });
                      }}
                    />
                  </div>
                </li>
              </ul>

              <button type="button" onClick={this.submitFeedback} className="feedback-button" style={{ marginTop: 25 }}>Send feedback</button>
            </div>
          </div>
          <div style={{
            flex: '42%',
            backgroundColor: '#EFEFEF',
            padding: '50px 0px',
            height: '90vh',
            position: 'relative',
          }}
          >
            <div style={{ position: 'relative' }} className="opinion-text-div">
              <p className="opinion-text" style={{ marginBottom: '46.5vh', fontWeight: '500' }}>
                Your opinion is very important to us.
                We are constantly working hard to improve your shopping experience with cash
                <sup>x</sup>
                . We really appreciate your feedback.
              </p>

            </div>
            <img
              src={SurveyImage}
              alt="survey-graphic"
              className="laptop-img"
              style={{

              }}
            />
            <div style={{
              backgroundColor: '#FFA0D3',
              height: '25vh',
              position: 'relative',
            }}
            />

          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
Feedback.contextType = ThemeContext;
Feedback.propTypes = {
  user: {
    name: PropTypes.string,
  },
};
