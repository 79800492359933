/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';

// import './index.css';
import '../SweepstakeAirfyer/index.css';
import './thankyou.css';
import Helmet from 'react-helmet';
import Footer from '../../../View/Footer';
import HowBox from '../../../View/Components/HowBox';
import FavoriteStore from '../../../View/Components/FavoriteStore';
import SaveFavorite from '../../../View/Components/SaveFavorite';
// import ActivityIndicator from '../../../View/ActivityIndicator';
// import PromotionBanner from '../../../View/Components/PromotionBanner';
import Header from '../../../View/PromotionHeaderSub';
import SweepStakeLogin from '../../SweepStakeLogin';
// import SweepstakeRegisterd from '../../SweepstakeRegisterd';
import landingCashX from '../../../images/promotion/AirpodsPro.png';
import lines from '../../../images/promotion/line_steps_graphic.png';
// import { ThemeContext } from '../../../utils/theme';
// import landingpagecookie from '../../../utils/landingpagecookie';
import cookie from '../../../utils/cookie';
import { cookieGet } from '../../../utils/constants';
// import { cookieGet, API_BASE_URL } from '../../../utils/constants';

function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isregister: null,
      cxCookie: null,
      // loading: true,

      // screenWidth: window.screen.availWidth,
    };
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    const userToken = cookie.getCookie('userToken');
    const checkurl = window.location.href;
    const cxDownload = cookieGet('CX_DWN');
    this.setState({ cxCookie: cxDownload });
    console.log('cxDownload :>> ', cxDownload);
    console.log('checkurl :>> ', checkurl);
    console.log('userToken :>> ', userToken);
    // const { href } = window.location;
    // if (this.state.screenWidth <= 767) {
    //   landingpagecookie(`${API_BASE_URL}/cx/v1/user/landing-page`, 'Mobile');
    // } else {
    //   landingpagecookie(`${API_BASE_URL}/cx/v1/user/landing-page`, 'Desktop');
    // }
    // window.addEventListener('resize', this.screenresize);
  }

  handleCallback(childData) {
    console.log('childData :>> ', childData);
    this.setState({ isregister: childData });
    if (childData.status === 'success') {
      this.props.history.push('/login');
    }
  }

  render() {
    const valOfUserId = getCookie('CX_userId');
    console.log('valOfUserId :>> ', valOfUserId);
    // const theme = this.context;
    const { isregister } = this.state;

    // if (loading) {
    //   return (
    //     <div className="login-main">
    //       <Helmet>
    //         <title>Thankyou | cashX</title>
    //         <meta name="description" content="Feedback | cashX" />
    //       </Helmet>
    //       <Header user={this.props.user} {...this.props} />
    //       <div className="login-container">
    //         <ActivityIndicator />
    //       </div>
    //       <Footer />
    //     </div>
    //   );
    // }

    return (

      <div id="sweep">
        <Helmet>
          <title>cashX: We’re giving away an AirPods Pro</title>
        </Helmet>
        <Header user={this.props.user} {...this.props} txt="AirPods Pro" />
        <div className="home1">
          {isregister === null
            && (
              <div className="desktopsweepview1 ">
                <div className="header container">
                  <h2>
                    Thank you for installing cash
                    <sup>X</sup>
                  </h2>
                </div>
                <div className="thankyoupage container">

                  <div className="text-wrapper-banner-gen1">
                    <p className="headertxt">
                      There’s one more step to complete your entry to win. Join cash
                      <sup>X</sup>
                      {' '}
                      now so we can notify you if you’re the winner!
                    </p>

                    <div className="thanksweepview ">
                      <div className="sec1">
                        <span><img src={lines} alt="cimages" /></span>
                      </div>
                      <div className="sec2">
                        <p className="first">
                          Add cash
                          <sup>X</sup>
                          {' '}
                          to your desktop Chrome browser.
                        </p>
                        <p className="th-join">
                          {' '}
                          Join cash
                          <sup>X</sup>
                          {' '}
                          — it’s free.
                        </p>
                        <SweepStakeLogin user={this.props.user} cxcookie={this.state.cxCookie} parentCallback={this.handleCallback} {...this.props} />

                      </div>
                      <div className="sec3">
                        <p>You could be the winner!</p>
                        <span>You’re automatically entered into our sweepstakes. We’ll notify you if you’re the winner.</span>
                      </div>

                    </div>
                    {/* <div className="sec3">
                      <p>You could be the winner!</p>
                      <span>You’re automatically entered into our sweepstakes. We’ll notify you if you’re the winner.</span>
                    </div> */}
                  </div>
                  <div className="image-wrapper-banner-gen1">
                    <img src={landingCashX} alt="cashX Savings" />
                  </div>

                </div>

                {/* <PromotionBanner
                  txt="AirPods Pro"
                  theme={theme}
                  bimg={landingCashX}
                /> */}
                <SaveFavorite />
                <HowBox />
                <div className="favstore">
                  <FavoriteStore />
                </div>
              </div>
            )}
          <Footer />
        </div>

      </div>
    );
  }
}
// Home.contextType = ThemeContext;
