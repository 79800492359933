/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import { ThemeContext } from '../../utils/theme';
import InputRow from '../../View/InputRow';
import Loading from '../../images/google.gif';
import { passwordValidator, cnfpasswordValidator } from '../../utils/regex';
import fetchPost from '../../utils/fetchPost';
import { savePassword } from '../../utils/apiEndPoint';
import Footer from '../../View/Footer';
import './index.css';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      confirmPassword: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      userId: '',
      backendErr: '',
      submitLoading: false,
      passwordUpdated: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const step1 = this.props.location.search.split('?token=')[1];
      const step2 = step1.split('&id=');
      this.setState({ token: step2[0], userId: step2[1] });
    }
  }

  handleSubmit() {
    const {
      password, confirmPassword, userId, token,
    } = this.state;
    this.setState({
      backendErr: '',
      submitLoading: true,
    });
    const pwdError = passwordValidator(password.value);
    const cnfPwdError = cnfpasswordValidator(confirmPassword.value, password.value);
    if (pwdError || cnfPwdError) {
      this.setState({
        password: { ...this.state.password, error: pwdError },
        confirmPassword: { ...this.state.confirmPassword, error: cnfPwdError },
        submitLoading: false,
      });
      return;
    }
    fetchPost(savePassword, {
      confirmPassword: confirmPassword.value,
      token,
      userId,
    })
      .then((res) => {
        if (res.status === 'success' || res.status === 200 || res.status === true) {
          this.props.history.replace('/reset-success');
        } else {
          this.setState({ backendErr: res.message, submitLoading: false });
        }
      })
      .catch(() => {
        this.setState({ backendErr: 'Something went wrong.', submitLoading: false });
      });
  }

  render() {
    const theme = this.context;
    const {
      confirmPassword, password, submitLoading, backendErr, passwordUpdated,
    } = this.state;
    return (
      <div>
        <div className="login-main reset-password-main">
          <Helmet>
            <title>Reset Password | cashX</title>
            <meta name="description" content="Reset your password for cashX" />
          </Helmet>
          <Header user={this.props.user} {...this.props} />
          <div className="login-container" style={{ minHeight: '49vh' }}>
            <div style={{ marginLeft: 4 }}>
              <p

                style={{
                  color: 'black', marginTop: 7, fontWeight: 'bold', textAlign: 'left', fontSize: 20,
                }}
              >
                Reset Your Password

              </p>
              <p style={{ fontSize: 15, marginTop: 15 }}>
                Your new password must meet the following requirements :
                <ul style={{ paddingLeft: 35, marginTop: 5 }}>
                  <li>Minimum 6 characters</li>
                  <li>At least 1 capital letter</li>
                  <li>At least 1 small letter</li>
                  <li>At least 1 number</li>
                </ul>
              </p>
            </div>
            <div style={{ marginTop: 20 }}>
              <p style={{
                color: '#ec168c',
                fontWeight: 'bold',
                marginBottom: 7,
                marginLeft: 7,
                fontSize: 15,
              }}
              >
                New Password :
              </p>
              <InputRow
                value={password.value}
                onChange={(e) => {
                  this.setState({ password: { ...this.state.password, value: e } });
                }}
                type="password"
                placeholder="&#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;"
                error={password.error}
              />
              <p style={{
                color: '#ec168c',
                fontWeight: 'bold',
                marginBottom: 7,
                marginLeft: 7,
                fontSize: 15,
              }}
              >
                Confirm Password :
              </p>
              <InputRow
                value={confirmPassword.value}
                onChange={(e) => {
                  this.setState({ confirmPassword: { ...this.state.confirmPassword, value: e } });
                }}
                type="password"
                placeholder="&#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;"
                error={confirmPassword.error}
                id=""
              />
              {backendErr ? (
                <p style={{ color: 'red', textAlign: 'center', margin: 5 }}>
                  {backendErr}
                </p>
              ) : null}
              {passwordUpdated ? (
                <p style={{
                  color: 'green', textAlign: 'center', margin: 5, fontSize: 12,
                }}
                >
                  Your Password has been updated. Redirecting you to login page.
                </p>
              ) : null}
              {submitLoading ? (
                <div
                  className="signin-button"
                  style={{
                    justifyContent: 'center',
                    backgroundColor: theme.primary,
                    color: 'white',
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <img
                    src={Loading}
                    alt="Loading..."
                    style={{ marginRight: 0, height: 30 }}
                  />
                </div>
              ) : (
                <div
                  className="reset-password"
                  onClick={this.handleSubmit}
                >
                  Reset Password
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
ResetPassword.contextType = ThemeContext;
ResetPassword.propTypes = {
  user: {
    name: PropTypes.string,
  },
  location: {
    search: PropTypes.string,
  },
  history: PropTypes.object,
};
