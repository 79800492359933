/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import Footer from '../../View/Footer';
// import ThankyouSweepstake from '../LandingPage/ThankyouSweepstake';
import ActivityIndicator from '../../View/ActivityIndicator';

import one from '../../images/howitworks/01.png';
import two from '../../images/howitworks/02.png';
// import three from '../../images/howitworks/03.png';
import four from '../../images/howitworks/04.png';
import five from '../../images/howitworks/05.png';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import {
  CHROME_EXTENSION_LINK,
  cookieGet,
} from '../../utils/constants';
// import landingpagecookie from '../../utils/landingpagecookie';

function getCookie(name) {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  if (result) {
    return result[1];
  }
  return null;
}
export default function HowItWorks(props) {
  const [cxDownload, setcxDownload] = useState([]);
  const [getmcampign, setGetmcampign] = useState([]);
  const [isClickId, setIsClickId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ci, setCi] = useState();
  useEffect(() => {
    // setInterval(() => {
    const cxDownload1 = cookieGet('CX_DWN');
    setcxDownload(cxDownload1);
    const getmcampign1 = getCookie('UTM_CAMPAIGN');
    setGetmcampign(getmcampign1);
    const getutmclickId1 = getCookie('clickId');
    console.log('getutmclickId1', getutmclickId1);
    // setGetutmclickId(getutmclickId1);
    if (getutmclickId1) {
      setIsClickId(true);
      setCi(getutmclickId1);
      // landingpagecookie(`https://clkdeals.com/adServe/track?prdid=3077&subid=${getutmclickId1}`, 'clickid');
    }

    // }, 2000);
    setLoading(false);
  }, []);

  const theme = useContext(ThemeContext);

  if (loading) {
    return (
      <div className="login-main">
        <Helmet>
          <title>Thankyou | cashX</title>
          <meta name="description" content="Feedback | cashX" />
        </Helmet>
        <Header user={props.user} {...props} />

        <div className="login-container">
          <ActivityIndicator />
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <>
      {getmcampign === 'sweepstakes' && !loading && (
        // eslint-disable-next-line react/prop-types
        props.history.push('/promotions/thankyou')
      )}

      {
        getmcampign !== 'sweepstakes' && !loading
        && (
          <div>
            <Header user={props.user} {...props} />
            <Helmet>
              <title>How It Works | cashX</title>
              <meta name="description" content="How the cashX extension works." />
              {cxDownload
                && (
                  <script>
                    {`
                      gtag('event', 'conversion', {'send_to': 'AW-387232000/thRzCKSMzvsCEIDi0rgB'})
                    `}
                  </script>

                )}
            </Helmet>
            <div className="works-wrapper">
              {isClickId
        && <img src={`https://clkdeals.com/adServe/track?prdid=3077&subid=${ci}`} alt="img" width="1" height="1" />}
              <div>
                <p className="top heading" style={{ color: theme.primary }}>
                  How cash
                  <sup>x</sup>
                  {' '}
                  Works
                </p>
              </div>
              <div className="steps-wrapper">
                <p className="heading">Simple, Free, and Easy to Use</p>
                <div className="container steps two">
                  <div className="step">
                    <div>
                      <p className="step-number" style={{ backgroundColor: theme.primary }}>1</p>
                      <p>
                        Install the cash
                        <sup>x</sup>
                        {' '}
                        extension.
                      </p>
                    </div>
                    <img src={one} alt="cashX Savings" />
                    <div className="steps-btn-wrapper">
                      <button className="add-chrome-btn1" style={{ backgroundColor: theme.primary }} type="button" onClick={() => { window.open(CHROME_EXTENSION_LINK, '_blank'); }}>
                        Install Cash
                        <sup>x</sup>
                        {' '}
                        — it’s free
                      </button>
                      <span className="backdrop-btn" />
                    </div>
                  </div>
                  <div className="step">
                    <div>
                      <p className="step-number" style={{ backgroundColor: theme.primary }}>2</p>
                      <p>
                        Click “Activate” at checkout, and cash
                        <sup>x</sup>
                        {' '}
                        will do the rest.
                      </p>
                    </div>
                    <img src={two} alt="cashX Savings" />
                  </div>
                  {/* <div className="step">
              <div>
                <p className="step-number" style={{ backgroundColor: theme.primary }}>3</p>
                <p>cashX does the rest! We find the best coupon to save you the most money AND you earn cash back on your purchases.</p>
              </div>
              <img src={three} alt="cashX Savings" />
            </div> */}
                </div>
              </div>
              <p className="heading">Easy to Redeem Your Cash Back</p>
              <div className="container steps two">
                <div className="step">
                  <div>
                    <p className="step-number" style={{ backgroundColor: theme.primary }}>1</p>
                    <p>Add a payment method to receive your cash back payout through PayPal or Amazon gift cards.</p>
                  </div>
                  <img src={four} alt="cashX Savings" />
                </div>
                <div className="step">
                  <div>
                    <p className="step-number" style={{ backgroundColor: theme.primary }}>2</p>
                    <p>Receive your cash back each month when you meet the $5 minimum threshold.</p>
                  </div>
                  <img src={five} alt="cashX Savings" />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )
      }
    </>
  );
}
HowItWorks.propTypes = {
  user: PropTypes.object,
};
