export const API_BASE_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_PROD;
// export const API_BASE_URL = 'https://cashx-stag-api.intentx.com';
export const ACCESS_TOKEN = 'accessToken';
// const redirectUri = 'http://localhost:3000/register';
const redirectUri = 'http://getcashx.com/register';
const redirectUriProm = 'http://getcashx.com/promotions/sweepstake-airpod.html';
// const redirectUriProm = 'http://localhost:3000/promotions/sweepstake-airpod.html';
export const cookieGet = (name) => {
  const result = document.cookie.match(
    new RegExp(`${name}=([^;]+)`),
  );
  return result ? result[1] : null;
};

const cxDownload = cookieGet('CX_DWN');

const attach = `?cx_down=${cxDownload}`;
const promotion = '&promotion=sweepstakesociallogin';
export const OAUTH2_REDIRECT_URI = redirectUri;
export const OAUTH2_REDIRECT_URIP = redirectUriProm;
export const CHROME_EXTENSION_LINK = 'https://chrome.google.com/webstore/detail/cashx/fmpnhboalalnbinpmgjhohpicghimgke?hl=en';
export const GOOGLE_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}${attach}`;
export const FACEBOOK_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_uri=${OAUTH2_REDIRECT_URI}${attach}`;
export const GITHUB_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/github?redirect_uri=${OAUTH2_REDIRECT_URI}`;
export const PAYPAL_AUTH_URL = `${API_BASE_URL}/oauth2/authorize/paypal?redirect_uri=${OAUTH2_REDIRECT_URI}`;

export const GOOGLE_AUTH_URL_PROM = `${API_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URIP}${attach}${promotion}`;
export const FACEBOOK_AUTH_URL_PROM = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_uri=${OAUTH2_REDIRECT_URIP}${attach}${promotion}`;
export const PAYPAL_AUTH_URL_PROM = `${API_BASE_URL}/oauth2/authorize/paypal?redirect_uri=${OAUTH2_REDIRECT_URIP}`;
export const install = '/cx/v1/user/manage-event';
