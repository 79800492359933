/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
// import Cashxsmalllogo from '../../images/cashx/logo-small@2x.png';
import cashXiconpink from '../../images/cashx/cashXiconpink@2x.png';
import Dailymailsmalllogo from '../../images/dailymail/logo-small.png';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import { CHROME_EXTENSION_LINK } from '../../utils/constants';

export default function Footer() {
  const theme = useContext(ThemeContext);
  let SmallLogo = cashXiconpink;
  if (theme.name === 'dailymail') SmallLogo = Dailymailsmalllogo;
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div id="footer-1">
            <p className="cashback-text">
              cash
              <sup>x</sup>
              {' '}
              finds the best coupon code at checkout and pays you cash back on your purchases.
            </p>
            <p>
              &copy;
              {new Date().getFullYear()}
              {' '}
              intent
              <sup>x</sup>
            </p>
          </div>
          <div id="footer-2">
            <div className="footer-logo">
              <img src={SmallLogo} alt="cashX" onClick={() => { window.open(CHROME_EXTENSION_LINK, '_blank'); }} />
            </div>
            <div className={`cms-links ${theme.name}-links`}>
              <p onClick={() => { window.location.href = '/privacy-policy'; }} role="link">Privacy Policy</p>
              <p onClick={() => { window.location.href = '/terms-of-service'; }} role="link">Terms of Service</p>
              <p onClick={() => { window.location.href = '/website-terms-of-use'; }} role="link">Website Terms</p>
              <p onClick={() => { window.location.href = '/sweepstake-rules-airpods'; }} role="link">Sweepstake Rules</p>
              <p onClick={() => { window.open(CHROME_EXTENSION_LINK, '_blank'); }}>
                Get cash
                <sup>x</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
