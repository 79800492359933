/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-throw-literal */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from '../../View/Header';
import SignIn from '../SignIn';
import GoogleLogo from '../../images/google-logo-signup.png';
import PayPalLogo from '../../images/paypal.png';
import FBLogo from '../../images/fb-logo-signup.png';
import Loading from '../../images/google.gif';
import './index.css';
import { ThemeContext } from '../../utils/theme';
import Footer from '../../View/Footer';
import ForgotPassword from '../ForgotPassword';
import ShowEmail from '../ShowEmail';
import {
  FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL, API_BASE_URL,
} from '../../utils/constants';
import cookie from '../../utils/cookie';
import { logout } from '../../utils/apiEndPoint';

const clientId = 'AYgW6-9WI5Dn45Hf44Yr_1r7tCsPomP4wSB-xQ9Hyt-SD0Dmod1zy6c32kVhCP5g8LDjatFVwWxeXz_p';
const OAUTH2_REDIRECT_URIP = 'https://getcashx.com/register';
const paypalurl = `${API_BASE_URL}/cx/v1/paypal/paypal-login?redirect_uri=${OAUTH2_REDIRECT_URIP}&clientId=${clientId}`;
// const paypalurl = `https://www.paypal.com/connect?flowEntry=static&client_id=${clientId}&scope=openid%20email&redirect_uri=${encodeURIComponent('https://getcashx.com/register')}&response_type=code&fullPage=true&authend=live`;
// & cx_down=${ cxDownload }
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fbLoading: false,
      googleLoading: false,
      backendError: '',
      forgotPwd: false,
      showEmail: false,
      loading: true,
      paypalLoading: false,
    };
  }

  componentDidMount() {
    const info = cookie.getCookie('userInfo');
    if (this.props.location.search && this.props.location.search === '?forgotPassword=true') {
      this.setState({ forgotPwd: true });
      if (info) {
        const userToken = cookie.getCookie('userToken');
        fetch(`${API_BASE_URL}${logout}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.text())
          .then((res) => {
            if (res === 'success') {
              cookie.removeAllCookie();
              sessionStorage.setItem('logout', JSON.stringify(true));
              this.setState({ loading: false });
              this.props.onLogout();
            } else {
              throw 'Something went wrong';
            }
          }).catch((e) => {
            console.log(e);
          });
      } else {
        this.setState({ loading: false });
      }
    } else if (info) {
      let path = '/';
      try {
        path = this.props.location.state.from.pathname;
        if (!path) {
          path = '/';
        }
      } catch (e) {
        path = '/';
      }
      this.props.history.push(path);
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const theme = this.context;
    const {
      fbLoading, googleLoading, backendError, forgotPwd, showEmail, loading, paypalLoading,
    } = this.state;
    const headerPassing = loading ? null : forgotPwd ? null : this.props.user;
    if (loading) {
      return (
        <div className="login-main">
          <Helmet>
            <title>Login | cashX</title>
            <meta name="description" content="Login to the cashX website." />
          </Helmet>
          <Header {...this.props} user={headerPassing} />
          <div className="login-containerl">
            Loading...
          </div>
        </div>
      );
    }
    return (
      <div className="login-main">
        <Helmet>
          <title>Login | cashX</title>
          <meta name="description" content="Login to the cashX website." />
        </Helmet>
        <Header {...this.props} user={headerPassing} />
        <div className="login-containerl">
          <p id="welcome">
            Welcome
          </p>
          <p
            id="login-msg"
            style={{ color: theme.tertiary, marginTop: 7 }}
          >
            Login to cash
            <sup>x</sup>
          </p>
          <div className="social-button-container">
            {googleLoading ? (
              <div
                className="social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = GOOGLE_AUTH_URL;
                }}
                className="social-button"
              >
                <img src={GoogleLogo} alt="Google" />
                <span>Sign in with Google</span>
              </div>
            )}
            {fbLoading ? (
              <div
                className="social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = FACEBOOK_AUTH_URL;
                }}
                className="social-button"
              >
                <img src={FBLogo} alt="Facebook Logo" />
                <span>Sign in with Facebook</span>
              </div>
            )}
            {paypalLoading ? (
              <div
                className="social-button"
                style={{ justifyContent: 'center', minHeight: '45px' }}
              >
                <img
                  className="loading-btn"
                  src={Loading}
                  alt="Loading..."
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  window.location.href = paypalurl;
                }}
                className="social-button"
              >
                <img src={PayPalLogo} alt="PayPal" />
                <span>Sign in with PayPal</span>
              </div>
            )}
            {backendError ? (
              <p style={{ color: 'red', margin: 0, textAlign: 'center' }}>
                {backendError}
              </p>
            ) : null}
            <div className="or-div">
              <span
                className="or"
                style={
                  backendError
                    ? { top: 210, color: theme.tertiary }
                    : { color: theme.tertiary }
                }
              >
                OR
              </span>
            </div>
          </div>

          <SignIn
            onLogin={(val) => {
              this.props.onLogin(val);
              this.props.history.replace('/account');
            }}
            forgotPwd={(val) => { this.setState({ forgotPwd: val }); }}
            signup={() => { this.props.history.push('/register'); }}
          />

        </div>
        {forgotPwd
          ? (
            <ForgotPassword
              forgotPwd={(val) => { this.setState({ forgotPwd: val }); }}
              showEmail={() => { this.setState({ showEmail: true, forgotPwd: false }); }}
            />
          )
          : null}
        {showEmail
          ? <ShowEmail checkEmail={(val) => this.setState({ showEmail: val })} />
          : null}
        <Footer />
      </div>
    );
  }
}
Login.contextType = ThemeContext;
Login.propTypes = {
  user: {
    name: PropTypes.string,
  },
};
